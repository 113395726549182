<template>
  <div>
    <validation-observer
      ref="serviceRequestAncestralFormSave"
    >
      <b-form @submit.prevent="submitSaveForm">
        <b-row
          class="content-header v-sticky-sidebar-container service_request_task collapse__form-wrapper"
          :class="{ 'white-background': !editFormInOtherStatus, 'green-background': editFormInOtherStatus }"
          style="border-radius: 6px;"
        >
          <!-- Content Left -->
          <b-col
            class="content-header-left my-1 top-info-right small-d-l__padding-left small-d__padding-adjust"
            cols="12"
            md="3"
          >
            <div
              class="user_block ac_type"
              style="float: none;"
            >
              <div class="user_avatar" />
              <div class="user_info">
                <h6>Status</h6>
                <h5 class="">
                  {{ requestForm.status }}
                </h5>
              </div>
            </div>

            <b-card
              v-if="editFormInOtherStatus"
              class="mt-2 service-forms-card success-card"
            >
              <template #header>
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  type="button"
                  variant="flat-primary"
                  class="primary-button-with-background mr-3"
                >
                  <feather-icon
                    icon="FeatherIcon"
                    class=""
                    size="20"
                    style="color: #fff;"
                  />
                </b-button>
                <h3 class="align-middle mr-auto">
                  <span class="step-class">you're now</span>
                  Editing this form
                </h3>
              </template>
              <b-card-text
                class="signing-stage__title"
              >
                <span>After submitting the signed form, the system will generate a signed PDF copy and email to customer.</span>
              </b-card-text>
            </b-card>
            <app-collapse
              v-if="requestForm.status == 'To prepare' || editFormInOtherStatus"
              accordion
              class="mt-2 service-form-collapse"
            >
              <app-collapse-item
                ref="firstPartRef"
                :is-visible="firstPartCollapse"
                title="家族中堂"
                class="custom-collapse-icon"
              >
                <template
                  #header
                >
                  <b-row
                    class="full-width"
                  >
                    <!-- Content Left -->
                    <b-col
                      class="content-header-left"
                      cols="12"
                      md="12"
                    >
                      <span style="color: #FFF;">家族中堂</span>
                    </b-col>
                  </b-row>
                </template>
                <b-row
                  class=""
                >
                  <b-col
                    class="content-header-left"
                    cols="12"
                    md="12"
                  >
                    <b-form-checkbox
                      v-model="ownEngraving"
                      value="ownEngraving"
                    >
                      <span class="field-label">Upload my own engraving format</span>
                      <br>
                      <span class="field-label">使用自己客制化格式</span>
                    </b-form-checkbox>
                    <b-alert
                      variant="success"
                      class="mt-75"
                      :show="ownEngraving != '' || secondOwnEngraving != ''"
                    >
                      <div class="alert-body d-flex">
                        <feather-icon
                          icon="AlertCircleIcon"
                          size="32"
                          class="mt-50 mr-1"
                        />
                        <span>Please upload your engraving format at <strong>step 4</strong> after completing step 3.</span>
                      </div>
                    </b-alert>
                    <hr class="dividerHR">
                  </b-col>
                  <div
                    style="width: 100%;"
                    :class="{'disabled-div': ownEngraving || secondOwnEngraving}"
                  >
                    <b-col
                      class="content-header-left"
                      cols="12"
                      md="12"
                    >
                      <div class="d-flex justify-content-between">
                        <span class="field-label">#1</span>
                        <b-form-checkbox
                          v-model="firstLiving"
                          value="firstLiving"
                          class="field-label"
                        >
                          长生 (Living)
                        </b-form-checkbox>
                      </div>
                      <b-form-group
                        v-if="firstInputType == 'input'"
                        label=""
                        label-for="input-default"
                      >
                        <validation-provider
                          #default="{ errors }"
                          name="#1"
                          :rules="requiredRuleComputation"
                        >
                          <!-- <b-form-input
                            id="input-default"
                            ref="firstInput"
                            v-model="firstInput"
                            placeholder="Enter"
                            :state="(errors.length > 0) ? false : null"
                          /> -->
                          <b-form-textarea
                            id="input-default"
                            ref="firstInput"
                            v-model="firstInput"
                            placeholder="Enter"
                            :state="(errors.length > 0) ? false : null"
                            @input="resizeText('firstInputField')"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                      <div
                        v-else
                        style="border-radius: 5px;
                      border: 1px solid #000; padding: 8px; height: 120px"
                        class="my-75"
                        :class="{ 'rounded-error': firstInputImageError }"
                      >
                        <div class="d-flex justify-content-between">
                          <span>Handwriting 手写</span>
                          <feather-icon
                            icon="XIcon"
                            class="ml-auto"
                            size="16"
                            @click="changeInputType('first', 'input')"
                          />
                        </div>
                        <img
                          v-if="firstInputImage"
                          :src="firstInputImage"
                          class="w-100 signature-image"
                          alt="sign"
                          fluid
                          @click="openSignatureModal('first')"
                        >
                      </div>

                      <div
                        v-if="firstInputType == 'input'"
                        class="d-flex justify-content-between"
                        @click="changeInputType('first', 'canvas')"
                      >
                        <span style="color: #104D9D">Use handwriting instead</span>
                        <b-button
                          variant="link"
                          class="pt-0 pr-0"
                        >
                          <feather-icon
                            icon="Edit3Icon"
                            class="ml-auto"
                            size="16"
                          />
                        </b-button>
                      </div>
                      <div
                        v-else
                        class="d-flex justify-content-between"
                        @click="changeInputType('first', 'input')"
                      >
                        <span style="color: #104D9D">Use keyboard instead</span>
                        <b-button
                          variant="link"
                          class="pt-0 pr-0"
                        >
                          <feather-icon
                            icon="Edit3Icon"
                            class="ml-auto"
                            size="16"
                          />
                        </b-button>
                      </div>
                    </b-col>
                    <b-col
                      class="content-header-left"
                      cols="12"
                      md="12"
                    >
                      <div class="d-flex justify-content-between">
                        <span class="field-label">#2</span>
                        <b-form-checkbox
                          v-model="secondLiving"
                          value="secondLiving"
                          class="field-label"
                        >
                          长生 (Living)
                        </b-form-checkbox>
                      </div>
                      <b-form-group
                        v-if="secondInputType == 'input'"
                        label=""
                        label-for="input-default"
                      >
                        <validation-provider
                          #default="{ errors }"
                          name="#2"
                          :rules="requiredRuleComputation"
                        >
                          <b-form-textarea
                            id="input-default"
                            ref="secondInput"
                            v-model="secondInput"
                            placeholder="Enter"
                            :state="(errors.length > 0) ? false : null"
                            @input="resizeText('secondInputField')"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                      <div
                        v-else
                        style="border-radius: 5px;
                      border: 1px solid #000; padding: 8px; height: 120px"
                        class="my-75"
                        :class="{ 'rounded-error': secondInputImageError }"
                      >
                        <div class="d-flex justify-content-between">
                          <span>Handwriting 手写</span>
                          <feather-icon
                            icon="XIcon"
                            class="ml-auto"
                            size="16"
                            @click="changeInputType('second', 'input')"
                          />
                        </div>
                        <img
                          v-if="secondInputImage"
                          :src="secondInputImage"
                          class="w-100 signature-image"
                          alt="sign"
                          fluid
                          @click="openSignatureModal('second')"
                        >
                      </div>

                      <div
                        v-if="secondInputType == 'input'"
                        class="d-flex justify-content-between"
                        @click="changeInputType('second', 'canvas')"
                      >
                        <span style="color: #104D9D">Use handwriting instead</span>
                        <b-button
                          variant="link"
                          class="pt-0 pr-0"
                        >
                          <feather-icon
                            icon="Edit3Icon"
                            class="ml-auto"
                            size="16"
                          />
                        </b-button>
                      </div>
                      <div
                        v-else
                        class="d-flex justify-content-between"
                        @click="changeInputType('second', 'input')"
                      >
                        <span style="color: #104D9D">Use keyboard instead</span>
                        <b-button
                          variant="link"
                          class="pt-0 pr-0"
                        >
                          <feather-icon
                            icon="Edit3Icon"
                            class="ml-auto"
                            size="16"
                          />
                        </b-button>
                      </div>
                    </b-col>
                    <b-col
                      class="content-header-left"
                      cols="12"
                      md="12"
                    >
                      <div class="d-flex justify-content-between">
                        <span class="field-label">#3</span>
                        <b-form-checkbox
                          v-model="thirdLiving"
                          value="thirdLiving"
                          class="field-label"
                        >
                          长生 (Living)
                        </b-form-checkbox>
                      </div>
                      <b-form-group
                        v-if="thirdInputType == 'input'"
                        label=""
                        label-for="input-default"
                      >
                        <validation-provider
                          #default="{ errors }"
                          name="#3"
                          :rules="requiredRuleComputation"
                        >
                          <b-form-textarea
                            id="input-default"
                            ref="thirdInput"
                            v-model="thirdInput"
                            placeholder="Enter"
                            :state="(errors.length > 0) ? false : null"
                            @input="resizeText('thirdInputField')"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                      <div
                        v-else
                        style="border-radius: 5px;
                      border: 1px solid #000; padding: 8px; height: 120px"
                        class="my-75"
                        :class="{ 'rounded-error': thirdInputImageError }"
                      >
                        <div class="d-flex justify-content-between">
                          <span>Handwriting 手写</span>
                          <feather-icon
                            icon="XIcon"
                            class="ml-auto"
                            size="16"
                            @click="changeInputType('third', 'input')"
                          />
                        </div>
                        <img
                          v-if="thirdInputImage"
                          :src="thirdInputImage"
                          class="w-100 signature-image"
                          alt="sign"
                          fluid
                          @click="openSignatureModal('third')"
                        >
                      </div>

                      <div
                        v-if="thirdInputType == 'input'"
                        class="d-flex justify-content-between"
                        @click="changeInputType('third', 'canvas')"
                      >
                        <span style="color: #104D9D">Use handwriting instead</span>
                        <b-button
                          variant="link"
                          class="pt-0 pr-0"
                        >
                          <feather-icon
                            icon="Edit3Icon"
                            class="ml-auto"
                            size="16"
                          />
                        </b-button>
                      </div>
                      <div
                        v-else
                        class="d-flex justify-content-between"
                        @click="changeInputType('third', 'input')"
                      >
                        <span style="color: #104D9D">Use keyboard instead</span>
                        <b-button
                          variant="link"
                          class="pt-0 pr-0"
                        >
                          <feather-icon
                            icon="Edit3Icon"
                            class="ml-auto"
                            size="16"
                          />
                        </b-button>
                      </div>
                    </b-col>
                    <b-col
                      class="content-header-left"
                      cols="12"
                      md="12"
                    >
                      <div class="d-flex justify-content-between">
                        <span class="field-label">#4</span>
                        <b-form-checkbox
                          v-model="fourthLiving"
                          value="fourthLiving"
                          class="field-label"
                        >
                          长生 (Living)
                        </b-form-checkbox>
                      </div>
                      <b-form-group
                        v-if="fourthInputType == 'input'"
                        label=""
                        label-for="input-default"
                      >
                        <validation-provider
                          #default="{ errors }"
                          name="#4"
                          :rules="requiredRuleComputation"
                        >
                          <b-form-textarea
                            id="input-default"
                            ref="fourthInput"
                            v-model="fourthInput"
                            placeholder="Enter"
                            :state="(errors.length > 0) ? false : null"
                            @input="resizeText('fourthInputField')"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                      <div
                        v-else
                        style="border-radius: 5px;
                      border: 1px solid #000; padding: 8px; height: 120px"
                        class="my-75"
                        :class="{ 'rounded-error': fourthInputImageError }"
                      >
                        <div class="d-flex justify-content-between">
                          <span>Handwriting 手写</span>
                          <feather-icon
                            icon="XIcon"
                            class="ml-auto"
                            size="16"
                            @click="changeInputType('fourth', 'input')"
                          />
                        </div>
                        <img
                          v-if="fourthInputImage"
                          :src="fourthInputImage"
                          class="w-100 signature-image"
                          alt="sign"
                          fluid
                          @click="openSignatureModal('fourth')"
                        >
                      </div>

                      <div
                        v-if="fourthInputType == 'input'"
                        class="d-flex justify-content-between"
                        @click="changeInputType('fourth', 'canvas')"
                      >
                        <span style="color: #104D9D">Use handwriting instead</span>
                        <b-button
                          variant="link"
                          class="pt-0 pr-0"
                        >
                          <feather-icon
                            icon="Edit3Icon"
                            class="ml-auto"
                            size="16"
                          />
                        </b-button>
                      </div>
                      <div
                        v-else
                        class="d-flex justify-content-between"
                        @click="changeInputType('fourth', 'input')"
                      >
                        <span style="color: #104D9D">Use keyboard instead</span>
                        <b-button
                          variant="link"
                          class="pt-0 pr-0"
                        >
                          <feather-icon
                            icon="Edit3Icon"
                            class="ml-auto"
                            size="16"
                          />
                        </b-button>
                      </div>
                    </b-col>
                    <b-col
                      class="content-header-left"
                      cols="12"
                      md="12"
                    >
                      <div class="d-flex justify-content-between">
                        <span class="field-label">#5</span>
                        <b-form-checkbox
                          v-model="fifthLiving"
                          value="fifthLiving"
                          class="field-label"
                        >
                          长生 (Living)
                        </b-form-checkbox>
                      </div>
                      <b-form-group
                        v-if="fifthInputType == 'input'"
                        label=""
                        label-for="input-default"
                      >
                        <validation-provider
                          #default="{ errors }"
                          name="#5"
                          :rules="requiredRuleComputation"
                        >
                          <b-form-textarea
                            id="input-default"
                            ref="fifthInput"
                            v-model="fifthInput"
                            placeholder="Enter"
                            :state="(errors.length > 0) ? false : null"
                            @input="resizeText('fifthInputField')"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                      <div
                        v-else
                        style="border-radius: 5px;
                      border: 1px solid #000; padding: 8px; height: 120px"
                        class="my-75"
                        :class="{ 'rounded-error': fifthInputImageError }"
                      >
                        <div class="d-flex justify-content-between">
                          <span>Handwriting 手写</span>
                          <feather-icon
                            icon="XIcon"
                            class="ml-auto"
                            size="16"
                            @click="changeInputType('fifth', 'input')"
                          />
                        </div>
                        <img
                          v-if="fifthInputImage"
                          :src="fifthInputImage"
                          class="w-100 signature-image"
                          alt="sign"
                          fluid
                          @click="openSignatureModal('fifth')"
                        >
                      </div>

                      <div
                        v-if="fifthInputType == 'input'"
                        class="d-flex justify-content-between"
                        @click="changeInputType('fifth', 'canvas')"
                      >
                        <span style="color: #104D9D">Use handwriting instead</span>
                        <b-button
                          variant="link"
                          class="pt-0 pr-0"
                        >
                          <feather-icon
                            icon="Edit3Icon"
                            class="ml-auto"
                            size="16"
                          />
                        </b-button>
                      </div>
                      <div
                        v-else
                        class="d-flex justify-content-between"
                        @click="changeInputType('fifth', 'input')"
                      >
                        <span style="color: #104D9D">Use keyboard instead</span>
                        <b-button
                          variant="link"
                          class="pt-0 pr-0"
                        >
                          <feather-icon
                            icon="Edit3Icon"
                            class="ml-auto"
                            size="16"
                          />
                        </b-button>
                      </div>
                    </b-col>
                    <b-col
                      class="content-header-left"
                      cols="12"
                      md="12"
                    >
                      <div class="d-flex justify-content-between">
                        <span class="field-label">#6</span>
                        <b-form-checkbox
                          v-model="sixthLiving"
                          value="sixthLiving"
                          class="field-label"
                        >
                          长生 (Living)
                        </b-form-checkbox>
                      </div>
                      <b-form-group
                        v-if="sixthInputType == 'input'"
                        label=""
                        label-for="input-default"
                      >
                        <validation-provider
                          #default="{ errors }"
                          name="#6"
                          :rules="requiredRuleComputation"
                        >
                          <b-form-textarea
                            id="input-default"
                            ref="sixthInput"
                            v-model="sixthInput"
                            placeholder="Enter"
                            :state="(errors.length > 0) ? false : null"
                            @input="resizeText('sixthInputField')"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                      <div
                        v-else
                        style="border-radius: 5px;
                      border: 1px solid #000; padding: 8px; height: 120px"
                        class="my-75"
                        :class="{ 'rounded-error': sixthInputImageError }"
                      >
                        <div class="d-flex justify-content-between">
                          <span>Handwriting 手写</span>
                          <feather-icon
                            icon="XIcon"
                            class="ml-auto"
                            size="16"
                            @click="changeInputType('sixth', 'input')"
                          />
                        </div>
                        <img
                          v-if="sixthInputImage"
                          :src="sixthInputImage"
                          class="w-100 signature-image"
                          alt="sign"
                          fluid
                          @click="openSignatureModal('sixth')"
                        >
                      </div>

                      <div
                        v-if="sixthInputType == 'input'"
                        class="d-flex justify-content-between"
                        @click="changeInputType('sixth', 'canvas')"
                      >
                        <span style="color: #104D9D">Use handwriting instead</span>
                        <b-button
                          variant="link"
                          class="pt-0 pr-0"
                        >
                          <feather-icon
                            icon="Edit3Icon"
                            class="ml-auto"
                            size="16"
                          />
                        </b-button>
                      </div>
                      <div
                        v-else
                        class="d-flex justify-content-between"
                        @click="changeInputType('sixth', 'input')"
                      >
                        <span style="color: #104D9D">Use keyboard instead</span>
                        <b-button
                          variant="link"
                          class="pt-0 pr-0"
                        >
                          <feather-icon
                            icon="Edit3Icon"
                            class="ml-auto"
                            size="16"
                          />
                        </b-button>
                      </div>
                    </b-col>
                    <b-col
                      class="content-header-left"
                      cols="12"
                      md="12"
                    >
                      <div class="d-flex justify-content-between">
                        <span class="field-label">#7</span>
                        <b-form-checkbox
                          v-model="seventhLiving"
                          value="seventhLiving"
                          class="field-label"
                        >
                          长生 (Living)
                        </b-form-checkbox>
                      </div>
                      <b-form-group
                        v-if="seventhInputType == 'input'"
                        label=""
                        label-for="input-default"
                      >
                        <validation-provider
                          #default="{ errors }"
                          name="#7"
                          :rules="requiredRuleComputation"
                        >
                          <b-form-textarea
                            id="input-default"
                            ref="seventhInput"
                            v-model="seventhInput"
                            placeholder="Enter"
                            :state="(errors.length > 0) ? false : null"
                            @input="resizeText('seventhInputField')"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                      <div
                        v-else
                        style="border-radius: 5px;
                      border: 1px solid #000; padding: 8px; height: 120px"
                        class="my-75"
                        :class="{ 'rounded-error': seventhInputImageError }"
                      >
                        <div class="d-flex justify-content-between">
                          <span>Handwriting 手写</span>
                          <feather-icon
                            icon="XIcon"
                            class="ml-auto"
                            size="16"
                            @click="changeInputType('seventh', 'input')"
                          />
                        </div>
                        <img
                          v-if="seventhInputImage"
                          :src="seventhInputImage"
                          class="w-100 signature-image"
                          alt="sign"
                          fluid
                          @click="openSignatureModal('seventh')"
                        >
                      </div>

                      <div
                        v-if="seventhInputType == 'input'"
                        class="d-flex justify-content-between"
                        @click="changeInputType('seventh', 'canvas')"
                      >
                        <span style="color: #104D9D">Use handwriting instead</span>
                        <b-button
                          variant="link"
                          class="pt-0 pr-0"
                        >
                          <feather-icon
                            icon="Edit3Icon"
                            class="ml-auto"
                            size="16"
                          />
                        </b-button>
                      </div>
                      <div
                        v-else
                        class="d-flex justify-content-between"
                        @click="changeInputType('seventh', 'input')"
                      >
                        <span style="color: #104D9D">Use keyboard instead</span>
                        <b-button
                          variant="link"
                          class="pt-0 pr-0"
                        >
                          <feather-icon
                            icon="Edit3Icon"
                            class="ml-auto"
                            size="16"
                          />
                        </b-button>
                      </div>
                    </b-col>
                  </div>
                </b-row>
              </app-collapse-item>
              <app-collapse-item
                ref="secondPartRef"
                :is-visible="secondPartCollapse"
                title="無中堂"
                class="custom-collapse-icon"
              >
                <template
                  #header
                >
                  <b-row
                    class="full-width"
                  >
                    <!-- Content Left -->
                    <b-col
                      class="content-header-left"
                      cols="12"
                      md="12"
                    >
                      <span style="color: #FFF;">無中堂</span>
                    </b-col>
                  </b-row>
                </template>
                <b-row
                  class=""
                >
                  <b-col
                    class="content-header-left"
                    cols="12"
                    md="12"
                  >
                    <b-form-checkbox
                      v-model="ownEngraving"
                      value="ownEngraving"
                    >
                      <span class="field-label">Upload my own engraving format</span>
                      <br>
                      <span class="field-label">使用自己客制化格式</span>
                    </b-form-checkbox>
                    <b-alert
                      variant="success"
                      class="mt-75"
                      :show="ownEngraving != '' || secondOwnEngraving != ''"
                    >
                      <div class="alert-body d-flex">
                        <feather-icon
                          icon="AlertCircleIcon"
                          size="32"
                          class="mt-50 mr-1"
                        />
                        <span>Please upload your engraving format at <strong>step 4</strong> after completing step 3.</span>
                      </div>
                    </b-alert>
                    <hr class="dividerHR">
                  </b-col>
                  <div
                    style="width: 100%;"
                    :class="{'disabled-div': ownEngraving || secondOwnEngraving}"
                  >
                    <b-col
                      class="content-header-left"
                      cols="12"
                      md="12"
                    >
                      <div class="d-flex justify-content-between">
                        <span class="field-label">#1</span>
                        <b-form-checkbox
                          v-model="firstLivingSecond"
                          value="firstLivingSecond"
                          class="field-label"
                        >
                          长生 (Living)
                        </b-form-checkbox>
                      </div>
                      <b-form-group
                        v-if="firstInputTypeSecond == 'input'"
                        label=""
                        label-for="input-default"
                      >
                        <validation-provider
                          #default="{ errors }"
                          name="#1"
                          :rules="requiredRuleComputation"
                        >
                          <b-form-textarea
                            id="input-default"
                            ref="firstInputSecond"
                            v-model="firstInputSecond"
                            placeholder="Enter"
                            :state="(errors.length > 0) ? false : null"
                            @input="resizeText('firstInputFieldSecond')"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                      <div
                        v-else
                        style="border-radius: 5px;
                      border: 1px solid #000; padding: 8px; height: 120px"
                        class="my-75"
                        :class="{ 'rounded-error': firstInputImageSecondError }"
                      >
                        <div class="d-flex justify-content-between">
                          <span>Handwriting 手写</span>
                          <feather-icon
                            icon="XIcon"
                            class="ml-auto"
                            size="16"
                            @click="changeSecondInputType('first', 'input')"
                          />
                        </div>
                        <img
                          v-if="firstInputImageSecond"
                          :src="firstInputImageSecond"
                          class="w-100 signature-image"
                          alt="sign"
                          fluid
                          @click="openSecondSignatureModal('first')"
                        >
                      </div>

                      <div
                        v-if="firstInputTypeSecond == 'input'"
                        class="d-flex justify-content-between"
                        @click="changeSecondInputType('first', 'canvas')"
                      >
                        <span style="color: #104D9D">Use handwriting instead</span>
                        <b-button
                          variant="link"
                          class="pt-0 pr-0"
                        >
                          <feather-icon
                            icon="Edit3Icon"
                            class="ml-auto"
                            size="16"
                          />
                        </b-button>
                      </div>
                      <div
                        v-else
                        class="d-flex justify-content-between"
                        @click="changeSecondInputType('first', 'input')"
                      >
                        <span style="color: #104D9D">Use keyboard instead</span>
                        <b-button
                          variant="link"
                          class="pt-0 pr-0"
                        >
                          <feather-icon
                            icon="Edit3Icon"
                            class="ml-auto"
                            size="16"
                          />
                        </b-button>
                      </div>
                    </b-col>
                    <b-col
                      class="content-header-left"
                      cols="12"
                      md="12"
                    >
                      <div class="d-flex justify-content-between">
                        <span class="field-label">#2</span>
                        <b-form-checkbox
                          v-model="secondLivingSecond"
                          value="secondLivingSecond"
                          class="field-label"
                        >
                          长生 (Living)
                        </b-form-checkbox>
                      </div>
                      <b-form-group
                        v-if="secondInputTypeSecond == 'input'"
                        label=""
                        label-for="input-default"
                      >
                        <validation-provider
                          #default="{ errors }"
                          name="#2"
                          :rules="requiredRuleComputation"
                        >
                          <b-form-textarea
                            id="input-default"
                            ref="secondInputSecond"
                            v-model="secondInputSecond"
                            placeholder="Enter"
                            :state="(errors.length > 0) ? false : null"
                            @input="resizeText('secondInputFieldSecond')"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                      <div
                        v-else
                        style="border-radius: 5px;
                      border: 1px solid #000; padding: 8px; height: 120px"
                        class="my-75"
                        :class="{ 'rounded-error': secondInputImageSecondError }"
                      >
                        <div class="d-flex justify-content-between">
                          <span>Handwriting 手写</span>
                          <feather-icon
                            icon="XIcon"
                            class="ml-auto"
                            size="16"
                            @click="changeSecondInputType('second', 'input')"
                          />
                        </div>
                        <img
                          v-if="secondInputImageSecond"
                          :src="secondInputImageSecond"
                          class="w-100 signature-image"
                          alt="sign"
                          fluid
                          @click="openSecondSignatureModal('second')"
                        >
                      </div>

                      <div
                        v-if="secondInputTypeSecond == 'input'"
                        class="d-flex justify-content-between"
                        @click="changeSecondInputType('second', 'canvas')"
                      >
                        <span style="color: #104D9D">Use handwriting instead</span>
                        <b-button
                          variant="link"
                          class="pt-0 pr-0"
                        >
                          <feather-icon
                            icon="Edit3Icon"
                            class="ml-auto"
                            size="16"
                          />
                        </b-button>
                      </div>
                      <div
                        v-else
                        class="d-flex justify-content-between"
                        @click="changeSecondInputType('second', 'input')"
                      >
                        <span style="color: #104D9D">Use keyboard instead</span>
                        <b-button
                          variant="link"
                          class="pt-0 pr-0"
                        >
                          <feather-icon
                            icon="Edit3Icon"
                            class="ml-auto"
                            size="16"
                          />
                        </b-button>
                      </div>
                    </b-col>
                    <b-col
                      class="content-header-left"
                      cols="12"
                      md="12"
                    >
                      <div class="d-flex justify-content-between">
                        <span class="field-label">#3</span>
                        <b-form-checkbox
                          v-model="thirdLivingSecond"
                          value="thirdLivingSecond"
                          class="field-label"
                        >
                          长生 (Living)
                        </b-form-checkbox>
                      </div>
                      <b-form-group
                        v-if="thirdInputTypeSecond == 'input'"
                        label=""
                        label-for="input-default"
                      >
                        <validation-provider
                          #default="{ errors }"
                          name="#3"
                          :rules="requiredRuleComputation"
                        >
                          <b-form-textarea
                            id="input-default"
                            ref="thirdInputSecond"
                            v-model="thirdInputSecond"
                            placeholder="Enter"
                            :state="(errors.length > 0) ? false : null"
                            @input="resizeText('thirdInputFieldSecond')"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                      <div
                        v-else
                        style="border-radius: 5px;
                      border: 1px solid #000; padding: 8px; height: 120px"
                        class="my-75"
                        :class="{ 'rounded-error': thirdInputImageSecondError }"
                      >
                        <div class="d-flex justify-content-between">
                          <span>Handwriting 手写</span>
                          <feather-icon
                            icon="XIcon"
                            class="ml-auto"
                            size="16"
                            @click="changeSecondInputType('third', 'input')"
                          />
                        </div>
                        <img
                          v-if="thirdInputImageSecond"
                          :src="thirdInputImageSecond"
                          class="w-100 signature-image"
                          alt="sign"
                          fluid
                          @click="openSecondSignatureModal('third')"
                        >
                      </div>

                      <div
                        v-if="thirdInputTypeSecond == 'input'"
                        class="d-flex justify-content-between"
                        @click="changeSecondInputType('third', 'canvas')"
                      >
                        <span style="color: #104D9D">Use handwriting instead</span>
                        <b-button
                          variant="link"
                          class="pt-0 pr-0"
                        >
                          <feather-icon
                            icon="Edit3Icon"
                            class="ml-auto"
                            size="16"
                          />
                        </b-button>
                      </div>
                      <div
                        v-else
                        class="d-flex justify-content-between"
                        @click="changeSecondInputType('third', 'input')"
                      >
                        <span style="color: #104D9D">Use keyboard instead</span>
                        <b-button
                          variant="link"
                          class="pt-0 pr-0"
                        >
                          <feather-icon
                            icon="Edit3Icon"
                            class="ml-auto"
                            size="16"
                          />
                        </b-button>
                      </div>
                    </b-col>
                    <b-col
                      class="content-header-left"
                      cols="12"
                      md="12"
                    >
                      <div class="d-flex justify-content-between">
                        <span class="field-label">#4</span>
                        <b-form-checkbox
                          v-model="fourthLivingSecond"
                          value="fourthLivingSecond"
                          class="field-label"
                        >
                          长生 (Living)
                        </b-form-checkbox>
                      </div>
                      <b-form-group
                        v-if="fourthInputTypeSecond == 'input'"
                        label=""
                        label-for="input-default"
                      >
                        <validation-provider
                          #default="{ errors }"
                          name="#4"
                          :rules="requiredRuleComputation"
                        >
                          <b-form-textarea
                            id="input-default"
                            ref="fourthInputSecond"
                            v-model="fourthInputSecond"
                            placeholder="Enter"
                            :state="(errors.length > 0) ? false : null"
                            @input="resizeText('fourthInputFieldSecond')"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                      <div
                        v-else
                        style="border-radius: 5px;
                      border: 1px solid #000; padding: 8px; height: 120px"
                        class="my-75"
                        :class="{ 'rounded-error': fourthInputImageSecondError }"
                      >
                        <div class="d-flex justify-content-between">
                          <span>Handwriting 手写</span>
                          <feather-icon
                            icon="XIcon"
                            class="ml-auto"
                            size="16"
                            @click="changeSecondInputType('fourth', 'input')"
                          />
                        </div>
                        <img
                          v-if="fourthInputImageSecond"
                          :src="fourthInputImageSecond"
                          class="w-100 signature-image"
                          alt="sign"
                          fluid
                          @click="openSecondSignatureModal('fourth')"
                        >
                      </div>

                      <div
                        v-if="fourthInputTypeSecond == 'input'"
                        class="d-flex justify-content-between"
                        @click="changeSecondInputType('fourth', 'canvas')"
                      >
                        <span style="color: #104D9D">Use handwriting instead</span>
                        <b-button
                          variant="link"
                          class="pt-0 pr-0"
                        >
                          <feather-icon
                            icon="Edit3Icon"
                            class="ml-auto"
                            size="16"
                          />
                        </b-button>
                      </div>
                      <div
                        v-else
                        class="d-flex justify-content-between"
                        @click="changeSecondInputType('fourth', 'input')"
                      >
                        <span style="color: #104D9D">Use keyboard instead</span>
                        <b-button
                          variant="link"
                          class="pt-0 pr-0"
                        >
                          <feather-icon
                            icon="Edit3Icon"
                            class="ml-auto"
                            size="16"
                          />
                        </b-button>
                      </div>
                    </b-col>
                    <b-col
                      class="content-header-left"
                      cols="12"
                      md="12"
                    >
                      <div class="d-flex justify-content-between">
                        <span class="field-label">#5</span>
                        <b-form-checkbox
                          v-model="fifthLivingSecond"
                          value="fifthLivingSecond"
                          class="field-label"
                        >
                          长生 (Living)
                        </b-form-checkbox>
                      </div>
                      <b-form-group
                        v-if="fifthInputTypeSecond == 'input'"
                        label=""
                        label-for="input-default"
                      >
                        <validation-provider
                          #default="{ errors }"
                          name="#5"
                          :rules="requiredRuleComputation"
                        >
                          <b-form-textarea
                            id="input-default"
                            ref="fifthInputSecond"
                            v-model="fifthInputSecond"
                            placeholder="Enter"
                            :state="(errors.length > 0) ? false : null"
                            @input="resizeText('fifthInputFieldSecond')"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                      <div
                        v-else
                        style="border-radius: 5px;
                      border: 1px solid #000; padding: 8px; height: 120px"
                        class="my-75"
                        :class="{ 'rounded-error': fifthInputImageSecondError }"
                      >
                        <div class="d-flex justify-content-between">
                          <span>Handwriting 手写</span>
                          <feather-icon
                            icon="XIcon"
                            class="ml-auto"
                            size="16"
                            @click="changeSecondInputType('fifth', 'input')"
                          />
                        </div>
                        <img
                          v-if="fifthInputImageSecond"
                          :src="fifthInputImageSecond"
                          class="w-100 signature-image"
                          alt="sign"
                          fluid
                          @click="openSecondSignatureModal('fifth')"
                        >
                      </div>

                      <div
                        v-if="fifthInputTypeSecond == 'input'"
                        class="d-flex justify-content-between"
                        @click="changeSecondInputType('fifth', 'canvas')"
                      >
                        <span style="color: #104D9D">Use handwriting instead</span>
                        <b-button
                          variant="link"
                          class="pt-0 pr-0"
                        >
                          <feather-icon
                            icon="Edit3Icon"
                            class="ml-auto"
                            size="16"
                          />
                        </b-button>
                      </div>
                      <div
                        v-else
                        class="d-flex justify-content-between"
                        @click="changeSecondInputType('fifth', 'input')"
                      >
                        <span style="color: #104D9D">Use keyboard instead</span>
                        <b-button
                          variant="link"
                          class="pt-0 pr-0"
                        >
                          <feather-icon
                            icon="Edit3Icon"
                            class="ml-auto"
                            size="16"
                          />
                        </b-button>
                      </div>
                    </b-col>
                    <b-col
                      class="content-header-left"
                      cols="12"
                      md="12"
                    >
                      <div class="d-flex justify-content-between">
                        <span class="field-label">#6</span>
                        <b-form-checkbox
                          v-model="sixthLivingSecond"
                          value="sixthLivingSecond"
                          class="field-label"
                        >
                          长生 (Living)
                        </b-form-checkbox>
                      </div>
                      <b-form-group
                        v-if="sixthInputTypeSecond == 'input'"
                        label=""
                        label-for="input-default"
                      >
                        <validation-provider
                          #default="{ errors }"
                          name="#6"
                          :rules="requiredRuleComputation"
                        >
                          <b-form-textarea
                            id="input-default"
                            ref="sixthInputSecond"
                            v-model="sixthInputSecond"
                            placeholder="Enter"
                            :state="(errors.length > 0) ? false : null"
                            @input="resizeText('sixthInputFieldSecond')"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                      <div
                        v-else
                        style="border-radius: 5px;
                      border: 1px solid #000; padding: 8px; height: 120px"
                        class="my-75"
                        :class="{ 'rounded-error': sixthInputImageSecondError }"
                      >
                        <div class="d-flex justify-content-between">
                          <span>Handwriting 手写</span>
                          <feather-icon
                            icon="XIcon"
                            class="ml-auto"
                            size="16"
                            @click="changeSecondInputType('sixth', 'input')"
                          />
                        </div>
                        <img
                          v-if="sixthInputImageSecond"
                          :src="sixthInputImageSecond"
                          class="w-100 signature-image"
                          alt="sign"
                          fluid
                          @click="openSecondSignatureModal('sixth')"
                        >
                      </div>

                      <div
                        v-if="sixthInputTypeSecond == 'input'"
                        class="d-flex justify-content-between"
                        @click="changeSecondInputType('sixth', 'canvas')"
                      >
                        <span style="color: #104D9D">Use handwriting instead</span>
                        <b-button
                          variant="link"
                          class="pt-0 pr-0"
                        >
                          <feather-icon
                            icon="Edit3Icon"
                            class="ml-auto"
                            size="16"
                          />
                        </b-button>
                      </div>
                      <div
                        v-else
                        class="d-flex justify-content-between"
                        @click="changeSecondInputType('sixth', 'input')"
                      >
                        <span style="color: #104D9D">Use keyboard instead</span>
                        <b-button
                          variant="link"
                          class="pt-0 pr-0"
                        >
                          <feather-icon
                            icon="Edit3Icon"
                            class="ml-auto"
                            size="16"
                          />
                        </b-button>
                      </div>
                    </b-col>
                  </div>
                </b-row>
              </app-collapse-item>
              <app-collapse-item
                ref="purchaserDetailsRef"
                :is-visible="purchaserDetailsCollapse"
                title="Purchaser Details"
                class="custom-collapse-icon"
              >
                <template
                  #header
                >
                  <b-row
                    class="full-width"
                  >
                    <!-- Content Left -->
                    <b-col
                      class="content-header-left"
                      cols="12"
                      md="12"
                    >
                      <span style="color: #FFF;">Purchaser Details</span>
                    </b-col>
                  </b-row>
                </template>
                <b-row
                  class=""
                >
                  <b-col
                    class="content-header-left"
                    cols="12"
                    md="12"
                  >
                    <div>
                      <span class="field-label label-required-star">At the behest of 在购者</span>
                    </div>
                    <b-form-group
                      v-if="customerNameType == 'input'"
                      label=""
                      label-for="input-default"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="At the behest of"
                        rules="required"
                      >
                        <b-form-input
                          id="input-default"
                          ref="customerName"
                          v-model="customerName"
                          placeholder="Enter"
                          :state="(errors.length > 0) ? false : null"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                    <div
                      v-else
                      style="border-radius: 5px;
                      border: 1px solid #000; padding: 8px; height: 120px"
                      class="my-75"
                      :class="{ 'rounded-error': customerNameImageError }"
                    >
                      <div class="d-flex justify-content-between">
                        <span>Handwriting 手写</span>
                        <feather-icon
                          icon="XIcon"
                          class="ml-auto"
                          size="16"
                          @click="changeCustomerNameInput('input')"
                        />
                      </div>
                      <img
                        v-if="customerNameImage"
                        v-b-modal.customer-name-modal
                        :src="customerNameImage"
                        class="w-100 signature-image"
                        alt="sign"
                        fluid
                      >
                    </div>

                    <div
                      v-if="customerNameType == 'input'"
                      class="d-flex justify-content-between"
                      @click="changeCustomerNameInput('canvas')"
                    >
                      <span style="color: #104D9D">Use handwriting instead</span>
                      <b-button
                        variant="link"
                        class="pt-0 pr-0"
                      >
                        <feather-icon
                          icon="Edit3Icon"
                          class="ml-auto"
                          size="16"
                        />
                      </b-button>
                    </div>
                    <div
                      v-else
                      class="d-flex justify-content-between"
                      @click="changeCustomerNameInput('input')"
                    >
                      <span style="color: #104D9D">Use keyboard instead</span>
                      <b-button
                        variant="link"
                        class="pt-0 pr-0"
                      >
                        <feather-icon
                          icon="Edit3Icon"
                          class="ml-auto"
                          size="16"
                        />
                      </b-button>
                    </div>
                  </b-col>
                  <b-col
                    class="content-header-left"
                    cols="12"
                    md="12"
                  >
                    <b-form-group
                      label-class="field-label"
                      label="Contact No. 联络号码"
                      label-for="customerContact"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="customerContact"
                        vid="customerContact"
                        rules=""
                      >
                        <b-form-input
                          id="customerContact"
                          ref="customerContact"
                          v-model="customerContact"
                          placeholder="Enter"
                          :state="(errors.length > 0) ? false : null"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col
                    class="content-header-left"
                    cols="12"
                    md="12"
                  >
                    <b-form-group
                      label-class="field-label label-required-star"
                      label="NRIC 身份证号码"
                      label-for="customerNRIC"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="NRIC"
                        vid="customerNRIC"
                        rules="required"
                      >
                        <b-form-input
                          id="customerNRIC"
                          ref="customerNRIC"
                          v-model="customerNRIC"
                          placeholder="Enter"
                          :state="(errors.length > 0) ? false : null"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                </b-row>
              </app-collapse-item>
              <app-collapse-item
                ref="kinDetailsRef"
                :is-visible="kinDetailsCollapse"
                title="Next of Kin Details"
                class="custom-collapse-icon"
              >
                <template
                  #header
                >
                  <b-row
                    class="full-width"
                  >
                    <!-- Content Left -->
                    <b-col
                      class="content-header-left"
                      cols="12"
                      md="12"
                    >
                      <span style="color: #FFF;">Next of Kin Details</span>
                    </b-col>
                  </b-row>
                </template>
                <b-row
                  class=""
                >
                  <b-col
                    class="content-header-left"
                    cols="12"
                    md="12"
                  >
                    <div>
                      <span class="field-label">Next of Kin Name 或是其至亲</span>
                    </div>
                    <b-form-group
                      v-if="currentKinNameType == 'input'"
                      label=""
                      label-for="input-default"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="Next of Kin Name"
                        rules=""
                      >
                        <b-form-input
                          id="input-default"
                          ref="kinName"
                          v-model="kinName"
                          placeholder="Enter"
                          :state="(errors.length > 0) ? false : null"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                    <div
                      v-else
                      style="border-radius: 5px;
                    border: 1px solid #000; padding: 8px; height: 120px"
                      class="my-75"
                      :class="{ 'rounded-error': kinNameImageError }"
                    >
                      <div class="d-flex justify-content-between">
                        <span>Handwriting 手写</span>
                        <feather-icon
                          icon="XIcon"
                          class="ml-auto"
                          size="16"
                          @click="changeKinNameInput('input')"
                        />
                      </div>
                      <img
                        v-if="kinNameImage"
                        v-b-modal.kin-name-modal
                        :src="kinNameImage"
                        class="w-100 signature-image"
                        alt="sign"
                        fluid
                      >
                    </div>

                    <div
                      v-if="currentKinNameType == 'input'"
                      class="d-flex justify-content-between"
                      @click="changeKinNameInput('canvas')"
                    >
                      <span style="color: #104D9D">Use handwriting instead</span>
                      <b-button
                        variant="link"
                        class="pt-0 pr-0"
                      >
                        <feather-icon
                          icon="Edit3Icon"
                          class="ml-auto"
                          size="16"
                        />
                      </b-button>
                    </div>
                    <div
                      v-else
                      class="d-flex justify-content-between"
                      @click="changeKinNameInput('input')"
                    >
                      <span style="color: #104D9D">Use keyboard instead</span>
                      <b-button
                        variant="link"
                        class="pt-0 pr-0"
                      >
                        <feather-icon
                          icon="Edit3Icon"
                          class="ml-auto"
                          size="16"
                        />
                      </b-button>
                    </div>
                  </b-col>
                  <b-col
                    class="content-header-left"
                    cols="12"
                    md="12"
                  >
                    <b-form-group
                      label-class="field-label"
                      label="Contact No. 联络号码"
                      label-for="kinContact"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="kinContact"
                        vid="kinContact"
                        rules=""
                      >
                        <b-form-input
                          id="kinContact"
                          ref="kinContact"
                          v-model="kinContact"
                          placeholder="Enter"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col
                    class="content-header-left"
                    cols="12"
                    md="12"
                  >
                    <b-form-group
                      label-class="field-label"
                      label="NRIC 身份证号码"
                      label-for="kinNRIC"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="NRIC"
                        vid="kinNRIC"
                        rules=""
                      >
                        <b-form-input
                          id="kinNRIC"
                          ref="kinNRIC"
                          v-model="kinNRIC"
                          placeholder="Enter"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                </b-row>
              </app-collapse-item>
              <app-collapse-item
                ref="othersDetailsRef"
                :is-visible="othersDetailsCollapse"
                title="Others"
                class="custom-collapse-icon m-0"
              >
                <template
                  #header
                >
                  <b-row
                    class="full-width"
                  >
                    <!-- Content Left -->
                    <b-col
                      class="content-header-left"
                      cols="12"
                      md="12"
                    >
                      <span style="color: #FFF;">Others</span>
                    </b-col>
                  </b-row>
                </template>
                <b-row
                  class=""
                >
                  <b-col
                    class="content-header-left"
                    cols="12"
                    md="12"
                  >
                    <b-form-group
                      label-class="field-label label-required-star"
                      label="File No. 文件号码"
                      label-for="fileNo"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="File No."
                        vid="fileNo"
                        rules="required"
                      >
                        <b-form-input
                          id="fileNo"
                          ref="fileNo"
                          v-model="fileNo"
                          placeholder="Enter"
                          :state="(errors.length > 0) ? false : null"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col
                    class="content-header-left"
                    cols="12"
                    md="12"
                  >
                    <b-form-group
                      label-class="field-label label-required-star"
                      label="Pedestal No. 牌位编号"
                      label-for="pedestalNo"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="Pedestal No."
                        vid="pedestalNo"
                        rules=""
                      >
                        <b-form-input
                          id="pedestalNo"
                          ref="pedestalNo"
                          v-model="pedestalNo"
                          placeholder="Enter"
                          readonly
                          :state="(errors.length > 0) ? false : null"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col
                    class="content-header-left"
                    cols="12"
                    md="12"
                  >
                    <b-form-group
                      label-class="field-label label-required-star"
                      label="Agent Name"
                      label-for="agentName"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="Agent Name"
                        vid="agentName"
                        rules=""
                      >
                        <b-form-input
                          id="agentName"
                          ref="agentName"
                          v-model="agentName"
                          placeholder="Enter"
                          readonly
                          :state="(errors.length > 0) ? false : null"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col
                    class="content-header-left"
                    cols="12"
                    md="12"
                  >
                    <b-form-group
                      label-class="field-label"
                      label="Agent Contact"
                      label-for="agentContact"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="agentContact"
                        vid="agentContact"
                        rules=""
                      >
                        <b-form-input
                          id="agentContact"
                          ref="agentContact"
                          v-model="agentContact"
                          placeholder="Enter"
                          readonly
                          :state="(errors.length > 0) ? false : null"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col
                    class="content-header-left"
                    cols="12"
                    md="12"
                  >
                    <b-form-group
                      label-class="field-label"
                      label="Remarks"
                      label-for="remarks"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="remarks"
                        vid="remarks"
                        rules=""
                      >
                        <b-form-input
                          id="remarks"
                          ref="remarks"
                          v-model="remarks"
                          placeholder="Enter"
                          :state="(errors.length > 0) ? false : null"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                </b-row>
              </app-collapse-item>
              <app-collapse-item
                ref="photoDetailsRef"
                :is-visible="photoDetailsCollapse"
                title="Photo Received/Returned"
                class="custom-collapse-icon m-0"
              >
                <template
                  #header
                >
                  <b-row
                    class="full-width"
                  >
                    <!-- Content Left -->
                    <b-col
                      class="content-header-left"
                      cols="12"
                      md="12"
                    >
                      <span style="color: #FFF;">Photo Received/Returned</span>
                    </b-col>
                  </b-row>
                </template>
                <b-row
                  class=""
                >
                  <b-col
                    class="content-header-left"
                    cols="12"
                    md="12"
                  >
                    <b-form-group
                      label-class="field-label"
                      label="Received By 收件"
                      label-for="photoReceivedBy"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="photoReceivedBy"
                        vid="photoReceivedBy"
                        rules=""
                      >
                        <b-form-input
                          id="photoReceivedBy"
                          ref="photoReceivedBy"
                          v-model="photoReceivedBy"
                          placeholder="Enter"
                          :state="(errors.length > 0) ? false : null"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col
                    class="content-header-left"
                    cols="12"
                    md="12"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Date 日期"
                      vid="photoReceivedDate"
                      rules=""
                    >
                      <b-form-group
                        label="Date 日期"
                        label-class="field-label"
                        label-for="photoReceivedDate"
                        :class="{ 'is-invalid': errors.length }"
                      >
                        <b-input-group>
                          <flat-pickr
                            id="photoReceivedDate"
                            ref="photoReceivedDate"
                            v-model="photoReceivedDate"
                            class="form-control flat-pickr-group"
                            placeholder="Date 日期"
                            :config="flatPickrConfig"
                          />
                          <b-input-group-append is-text>
                            <feather-icon
                              icon="CalendarIcon"
                              class="cursor-pointer"
                              data-toggle
                              size="18"
                            />
                          </b-input-group-append>
                        </b-input-group>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <b-col
                    class="content-header-left"
                    cols="12"
                    md="12"
                  >
                    <b-form-group
                      label-class="field-label"
                      label="Claimed By 照片领回"
                      label-for="photoClaimedBy"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="photoClaimedBy"
                        vid="photoClaimedBy"
                        rules=""
                      >
                        <b-form-input
                          id="photoClaimedBy"
                          ref="photoClaimedBy"
                          v-model="photoClaimedBy"
                          placeholder="Enter"
                          :state="(errors.length > 0) ? false : null"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col
                    class="content-header-left"
                    cols="12"
                    md="12"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Date 日期"
                      vid="photoClaimedDate"
                      rules=""
                    >
                      <b-form-group
                        label="Date 日期"
                        label-class="field-label"
                        label-for="photoClaimedDate"
                        :class="{ 'is-invalid': errors.length }"
                      >
                        <b-input-group>
                          <flat-pickr
                            id="photoClaimedDate"
                            ref="photoClaimedDate"
                            v-model="photoClaimedDate"
                            class="form-control flat-pickr-group"
                            placeholder="Date 日期"
                            :config="flatPickrConfig"
                          />
                          <b-input-group-append is-text>
                            <feather-icon
                              icon="CalendarIcon"
                              class="cursor-pointer"
                              data-toggle
                              size="18"
                            />
                          </b-input-group-append>
                        </b-input-group>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                </b-row>
              </app-collapse-item>
              <app-collapse-item
                ref="officeDetailsRef"
                :is-visible="officeDetailsCollapse"
                title="For office use"
                class="custom-collapse-icon m-0"
              >
                <template
                  #header
                >
                  <b-row
                    class="full-width"
                  >
                    <!-- Content Left -->
                    <b-col
                      class="content-header-left"
                      cols="12"
                      md="12"
                    >
                      <span style="color: #FFF;">For office use</span>
                    </b-col>
                  </b-row>
                </template>
                <b-row
                  class=""
                >
                  <b-col
                    class="content-header-left"
                    cols="12"
                    md="12"
                  >
                    <b-form-group
                      label-class="field-label"
                      label="Remark 备注"
                      label-for="serviceType"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="serviceType"
                        vid="serviceType"
                        rules=""
                      >
                        <b-form-input
                          id="serviceType"
                          ref="serviceType"
                          v-model="serviceType"
                          placeholder="Enter"
                          :state="(errors.length > 0) ? false : null"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col
                    class="content-header-left"
                    cols="12"
                    md="12"
                  >
                    <b-form-group
                      label-class="field-label"
                      label="Person in Charge 服务人员"
                      label-for="inChargePerson"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="inChargePerson"
                        vid="inChargePerson"
                        rules=""
                      >
                        <b-form-input
                          id="inChargePerson"
                          ref="inChargePerson"
                          v-model="inChargePerson"
                          placeholder="Enter"
                          :state="(errors.length > 0) ? false : null"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col
                    class="content-header-left"
                    cols="12"
                    md="12"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Installation Date"
                      vid="installationDate"
                      rules=""
                    >
                      <b-form-group
                        label="Installation Date 预定上位日期"
                        label-class="field-label"
                        label-for="installationDate"
                        :class="{ 'is-invalid': errors.length }"
                      >
                        <b-input-group>
                          <flat-pickr
                            id="installationDate"
                            ref="installationDate"
                            v-model="installationDate"
                            class="form-control flat-pickr-group"
                            placeholder="DD/MM/YYYYY"
                            :config="flatPickrConfigInstallationDate"
                          />
                          <b-input-group-append is-text>
                            <feather-icon
                              icon="CalendarIcon"
                              class="cursor-pointer"
                              data-toggle
                              size="18"
                            />
                          </b-input-group-append>
                        </b-input-group>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <b-col
                    class="content-header-left"
                    cols="12"
                    md="12"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Submitted Date"
                      vid="submitDate"
                      rules=""
                    >
                      <b-form-group
                        label="Submitted Date 刻碑资料提交日期"
                        label-class="field-label"
                        label-for="submitDate"
                        :class="{ 'is-invalid': errors.length }"
                      >
                        <b-input-group>
                          <flat-pickr
                            id="submitDate"
                            ref="submitDate"
                            v-model="submitDate"
                            class="form-control flat-pickr-group"
                            placeholder="DD/MM/YYYYY"
                            :config="flatPickrConfig"
                          />
                          <b-input-group-append is-text>
                            <feather-icon
                              icon="CalendarIcon"
                              class="cursor-pointer"
                              data-toggle
                              size="18"
                            />
                          </b-input-group-append>
                        </b-input-group>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <b-col
                    class="content-header-left"
                    cols="12"
                    md="12"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Email Date"
                      vid="emailDate"
                      rules=""
                    >
                      <b-form-group
                        label="Email Date 校对检查电邮日期"
                        label-class="field-label"
                        label-for="emailDate"
                        :class="{ 'is-invalid': errors.length }"
                      >
                        <b-input-group>
                          <flat-pickr
                            id="emailDate"
                            ref="emailDate"
                            v-model="emailDate"
                            class="form-control flat-pickr-group"
                            placeholder="DD/MM/YYYYY"
                            :config="flatPickrConfig"
                          />
                          <b-input-group-append is-text>
                            <feather-icon
                              icon="CalendarIcon"
                              class="cursor-pointer"
                              data-toggle
                              size="18"
                            />
                          </b-input-group-append>
                        </b-input-group>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                </b-row>
              </app-collapse-item>
            </app-collapse>

            <div v-if="!editFormInOtherStatus">
              <b-card
                v-if="requestForm.status == 'Ready to sign'"
                class="mt-2 service-forms-card"
              >
                <template #header>
                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    type="button"
                    variant="flat-primary"
                    class="primary-button-with-background mr-3"
                  >
                    <feather-icon
                      icon="FeatherIcon"
                      class=""
                      size="20"
                      style="color: #fff;"
                    />
                  </b-button>
                  <h3 class="align-middle mr-auto">
                    <span class="step-class">signing required</span>
                    Ready to Sign?
                  </h3>
                </template>
                <b-card-text
                  class="signing-stage__title"
                >
                  <span>If you're prepared to proceed, please press the button to indicate your agreement to this form.</span>
                </b-card-text>

                <!-- Button: Send Invoice -->
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="success"
                  class="mb-75"
                  block
                  @click="startSignProcess"
                >
                  <feather-icon
                    icon="FeatherIcon"
                    class="mr-50"
                    size="16"
                  />
                  <span class="align-middle">Sign now</span>
                </b-button>
              </b-card>
              <b-card
                v-if="requestForm.status == 'Signing'"
                class="mt-2 service-forms-card"
              >
                <template #header>
                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    type="button"
                    variant="flat-primary"
                    class="primary-button-with-background mr-3"
                  >
                    <feather-icon
                      icon="FeatherIcon"
                      class=""
                      size="20"
                      style="color: #fff;"
                    />
                  </b-button>
                  <h3 class="align-middle mr-auto">
                    <span class="step-class">signing required</span>
                    Start signing
                  </h3>
                </template>
                <b-card-text
                  class="signing-stage__title"
                >
                  <span>This form requires 2 signatures from:</span>
                </b-card-text>

                <app-timeline class="sign-list">
                  <app-timeline-item
                    v-if="isPurchaserSign"
                    title="Purchaser / Authorized Representative Signatory & Date"
                    icon="CheckIcon"
                    variant="primary"
                    class="signed"
                  />
                  <app-timeline-item
                    v-else
                    title="Purchaser / Authorized Representative Signatory & Date"
                    icon="FeatherIcon"
                    variant="primary"
                  />
                  <app-timeline-item
                    v-if="completionSign"
                    title="Completion Checked By Signatory & Date"
                    icon="CheckIcon"
                    variant="primary"
                    class="signed"
                  />
                  <app-timeline-item
                    v-else
                    title="Completion Checked By Signatory & Date"
                    icon="FeatherIcon"
                    variant="primary"
                  />
                </app-timeline>

                <hr class="dividerHR signing-stage__divider">

                <b-card-text
                  class="mt-2"
                >
                  <span>After submitted the signed form, the system will generate a PDF copy and email to customer.</span>
                </b-card-text>
              </b-card>

              <b-card
                v-if="requestForm.status == 'Signed and Sent'"
                class="mt-2 service-forms-card"
              >
                <b-card-text
                  class="signing-stage__title"
                >
                  <span>This form requires 2 signatures from:</span>
                </b-card-text>

                <app-timeline class="sign-list">
                  <app-timeline-item
                    title="Purchaser’s / Next of Kin’s Signatory & Date"
                    icon="CheckIcon"
                    variant="primary"
                    class="signed"
                  />
                  <app-timeline-item
                    title="Completion Checked By Signatory & Date"
                    icon="CheckIcon"
                    variant="primary"
                    class="signed"
                  />
                </app-timeline>

                <hr class="dividerHR signing-stage__divider">

                <b-card-text
                  class="mt-2"
                >
                  <b-button
                    variant="outline-primary"
                    class="w-100"
                    @click="resendSignedFormModal"
                  >
                    <feather-icon
                      icon="SendIcon"
                      class="mr-50"
                      size="16"
                    />
                    <span>Resend signed form</span>
                  </b-button>
                </b-card-text>
              </b-card>
            </div>
            <div class="d-form__action-btn">
              <b-button
                variant="outline-primary"
                class="mt-2"
                style="background: #FFF;"
                :to="{ name: 'operation-service-requests-show', params: { id: serviceRequest._id } }"
              >
                <feather-icon
                  icon="ChevronLeftIcon"
                  class="mr-50"
                  size="16"
                />
                <span>Return to {{ serviceRequest.stringID }}</span>
              </b-button>
              <b-button
                v-if="!editFormInOtherStatus && requestForm.status != 'To prepare'"
                variant="outline-primary"
                class="mt-2 ml-auto"
                style="background: #FFF;"
                @click="editForm"
              >
                <feather-icon
                  icon="EditIcon"
                  size="16"
                />
              </b-button>
            </div>
          </b-col>

          <b-col
            class="content-header-right my-1 d-md-block small-d-l__padding-right small-d__padding-adjust"
            style="position: relative;"
            md="9"
            cols="12"
          >
            <div v-if="ownEngraving || secondOwnEngraving">
              <b-card
                class="mb-0"
              >
                <b-row class="invoice-preview">
                  <b-col
                    cols="3"
                    xl="2"
                    md="2"
                    sm="3"
                    lg="2"
                    class="text-left d-block my-auto px-0"
                  >
                    <b-button
                      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                      variant="link"
                      :disabled="currentFileIndex == 1"
                      class="px-0"
                      @click="changeFileIndex(currentFileIndex - 1)"
                    >
                      <feather-icon
                        icon="ChevronLeftIcon"
                        class="pt-25"
                        size="18"
                      />
                      <span class="align-middle">Prev</span>
                    </b-button>
                  </b-col>
                  <b-col
                    cols="6"
                    xl="8"
                    md="8"
                    sm="6"
                    lg="8"
                    class="text-center px-0"
                  >
                    <b-dropdown
                      id="dropdown-2"
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="primary"
                      split
                      class="d-block-after"
                    >
                      <template #button-content>
                        <feather-icon
                          icon="FileIcon"
                          size="18"
                          class="align-middle mr-1"
                          :class="{'step-mobile__font-adjust' : isMobile()}"
                        />
                        <span
                          class="mr-1"
                          :class="{'step-mobile__font-adjust' : isMobile()}"
                        >{{ currentFileIndex.toString().padStart(2, "0") }} / {{ ownEngravingImage ? ((ownEngravingImage.length + 1).toString().padStart(2, "0")) : '01' }}
                          <span v-if="!isMobile()">: {{ selectedFileName }}</span>
                        </span>
                      </template>
                      <b-dropdown-item @click="changeFileIndex(1)">
                        Ancestral Tablets Engraving Notice 牌位刻碑表格
                      </b-dropdown-item>
                      <b-dropdown-item
                        v-for="(file, key) in ownEngravingImage"
                        :key="key"
                        @click="changeFileIndex(key + 2)"
                      >
                        {{ file ? file.name : '' }}
                      </b-dropdown-item>
                    </b-dropdown>
                  </b-col>
                  <b-col
                    cols="3"
                    xl="2"
                    md="2"
                    sm="3"
                    lg="2"
                    class="text-right d-block my-auto px-0"
                  >
                    <b-button
                      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                      variant="link"
                      class="px-0"
                      :disabled="currentFileIndex == (ownEngravingImage ? (ownEngravingImage.length + 1) : 1)"
                      @click="changeFileIndex(currentFileIndex + 1)"
                    >
                      <span class="align-middle">Next</span>
                      <feather-icon
                        icon="ChevronRightIcon"
                        class="pt-25"
                        size="18"
                      />
                    </b-button>
                  </b-col>
                </b-row>
              </b-card>
            </div>
            <b-card
              v-show="showAttachment"
              no-body
              class="invoice-preview-card background-card"
            >
              <div class="m-2 card__inner p-4">
                <iframe
                  v-show="showPDF"
                  id="attachmentpdf"
                  width="100%"
                  height="1145"
                />
                <b-img
                  v-show="!showPDF"
                  :src="imageSrc"
                  fluid-grow
                  alt="Fluid-grow image"
                />
              </div>
            </b-card>
            <b-card
              v-show="!showAttachment"
              no-body
              class="mb-0 invoice-preview-card"
              :class="{'background-card': ownEngraving || secondOwnEngraving}"
            >
              <div
                class="urn-form-wrapper m-2"
                :class="{'card__inner p-4': ownEngraving || secondOwnEngraving}"
              >
                <div class="d-form-header d-flex justify-content-between mb-75">
                  <div class="d-form-logo_section" />
                  <div class="d-form-no_section text-right">
                    <h5>
                      {{ digitalForm.formNumber }}
                    </h5>
                    <h5>
                      {{ digitalForm.revision }}
                    </h5>
                  </div>
                </div>
                <div class="urn-repository-order_section">
                  <div class="b-form-section-title my-2">
                    <h3>ANCESTRAL TABLETS ENGRAVING NOTICE <span>牌位刻碑表格</span></h3>
                  </div>
                  <b-row>
                    <b-col
                      lg="8"
                      md="8"
                      sm="8"
                      cols="12"
                      class="pr-0"
                      :class="{'disabled-div': ownEngraving || secondOwnEngraving}"
                    >
                      <div class="d-form-border my-2">
                        <b-row>
                          <b-col
                            lg="7"
                            md="7"
                            sm="7"
                            cols="12"
                            class="d-flex justify-content-center pr-0"
                            :class="{ 'pr-0 an-padding-right-1': isMobile() }"
                          >
                            <div class="p-1 padding-none padding-10">
                              <h6 class="ancestral-table-section-title">
                                家族中堂
                              </h6>
                              <div class="d-flex">
                                <div class="vertical-single-box-wrapper">
                                  <div
                                    class="vertical-single-box"
                                    :class="{ 'bg-white': (secondInput || secondInputImage || requestForm.status != 'To prepare'), 'edit-mode': editFormInOtherStatus }"
                                  >
                                    <div
                                      v-if="secondInput || secondInputImage"
                                      class="vertical-single-box-content"
                                    >
                                      <p v-if="secondLiving">
                                        长生
                                      </p>
                                      <h5
                                        v-if="secondInputType == 'input'"
                                        ref="secondInputField"
                                        :style="{ 'font-size': getFontSize('secondInputField') }"
                                        @click="handleClick('secondInput', 'firstPartRef')"
                                      >
                                        {{ secondInput }}
                                      </h5>
                                      <div
                                        v-else
                                        :class="{ 'signed': secondInputImage }"
                                      >
                                        <b-img
                                          v-if="secondInputImage"
                                          :src="secondInputImage"
                                          class="w-100 signature-image"
                                          alt="sign"
                                        />
                                      </div>
                                      <p>2</p>
                                    </div>
                                    <div
                                      v-else
                                      class="full-width-div"
                                      @click="handleClick('secondInput', 'firstPartRef')"
                                    >
                                      <div class="vertical-single-box-content-empty">
                                        <span v-if="requestForm.status == 'To prepare' || editFormInOtherStatus">2</span>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    class="vertical-single-box"
                                    :class="{ 'bg-white': (fourthInput || fourthInputImage || requestForm.status != 'To prepare'), 'edit-mode': editFormInOtherStatus }"
                                  >
                                    <div
                                      v-if="fourthInput || fourthInputImage"
                                      class="vertical-single-box-content"
                                    >
                                      <p v-if="fourthLiving">
                                        长生
                                      </p>
                                      <h5
                                        v-if="fourthInputType == 'input'"
                                        ref="fourthInputField"
                                        :style="{ 'font-size': getFontSize('fourthInputField') }"
                                        @click="handleClick('fourthInput', 'firstPartRef')"
                                      >
                                        {{ fourthInput }}
                                      </h5>
                                      <div
                                        v-else
                                        :class="{ 'signed': fourthInputImage }"
                                      >
                                        <b-img
                                          v-if="fourthInputImage"
                                          :src="fourthInputImage"
                                          class="w-100 signature-image"
                                          alt="sign"
                                        />
                                      </div>
                                      <p>4</p>
                                    </div>
                                    <div
                                      v-else
                                      class="full-width-div"
                                      @click="handleClick('fourthInput', 'firstPartRef')"
                                    >
                                      <div class="vertical-single-box-content-empty">
                                        <span v-if="requestForm.status == 'To prepare' || editFormInOtherStatus">4</span>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    class="vertical-single-box"
                                    :class="{ 'bg-white': (sixthInput || sixthInputImage || requestForm.status != 'To prepare'), 'edit-mode': editFormInOtherStatus }"
                                  >
                                    <div
                                      v-if="sixthInput || sixthInputImage"
                                      class="vertical-single-box-content"
                                    >
                                      <p v-if="sixthLiving">
                                        长生
                                      </p>
                                      <h5
                                        v-if="sixthInputType == 'input'"
                                        ref="sixthInputField"
                                        :style="{ 'font-size': getFontSize('sixthInputField') }"
                                        @click="handleClick('sixthInput', 'firstPartRef')"
                                      >
                                        {{ sixthInput }}
                                      </h5>
                                      <div
                                        v-else
                                        :class="{ 'signed': sixthInputImage }"
                                      >
                                        <b-img
                                          v-if="sixthInputImage"
                                          :src="sixthInputImage"
                                          class="w-100 signature-image"
                                          alt="sign"
                                        />
                                      </div>
                                      <p>6</p>
                                    </div>
                                    <div
                                      v-else
                                      class="full-width-div"
                                      @click="handleClick('sixthInput', 'firstPartRef')"
                                    >
                                      <div class="vertical-single-box-content-empty">
                                        <span v-if="requestForm.status == 'To prepare' || editFormInOtherStatus">6</span>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div class="vertical-single-box-wrapper-with-text">
                                  <div class="vertical-single-box-heading">
                                    <h4>佛光</h4>
                                    <h4>接引</h4>
                                  </div>
                                  <div
                                    class="vertical-single-box-with-text"
                                    :class="{ 'bg-white': (seventhInput || seventhInputImage || requestForm.status != 'To prepare'), 'edit-mode': editFormInOtherStatus }"
                                  >
                                    <div
                                      v-if="seventhInput || seventhInputImage"
                                      class="vertical-single-box-content"
                                    >
                                      <p v-if="seventhLiving">
                                        长生
                                      </p>
                                      <h5
                                        v-if="seventhInputType == 'input'"
                                        ref="seventhInputField"
                                        :style="{ 'font-size': getFontSize('seventhInputField') }"
                                        @click="handleClick('seventhInput', 'firstPartRef')"
                                      >
                                        {{ seventhInput }}
                                      </h5>
                                      <div
                                        v-else
                                        :class="{ 'signed': seventhInputImage }"
                                      >
                                        <b-img
                                          v-if="seventhInputImage"
                                          :src="seventhInputImage"
                                          class="w-100 signature-image"
                                          alt="sign"
                                        />
                                      </div>
                                      <p>7</p>
                                    </div>
                                    <div
                                      v-else
                                      class="full-width-div"
                                      @click="handleClick('seventhInput', 'firstPartRef')"
                                    >
                                      <div class="vertical-single-box-content-empty">
                                        <span v-if="requestForm.status == 'To prepare' || editFormInOtherStatus">7</span>
                                      </div>
                                    </div>
                                  </div>
                                  <div class="vertical-single-box-heading-bottom">
                                    <h4>門</h4>
                                    <h4>歷</h4>
                                    <h4>代</h4>
                                    <h4>祖</h4>
                                    <h4>先</h4>
                                    <h4>位</h4>
                                  </div>
                                </div>
                                <div class="vertical-single-box-wrapper">
                                  <div
                                    class="vertical-single-box"
                                    :class="{ 'bg-white': (firstInput || firstInputImage || requestForm.status != 'To prepare'), 'edit-mode': editFormInOtherStatus }"
                                  >
                                    <div
                                      v-if="firstInput || firstInputImage"
                                      class="vertical-single-box-content"
                                    >
                                      <p v-if="firstLiving">
                                        长生
                                      </p>
                                      <h5
                                        v-if="firstInputType == 'input'"
                                        ref="firstInputField"
                                        :style="{ 'font-size': getFontSize('firstInputField') }"
                                        @click="handleClick('firstInput', 'firstPartRef')"
                                      >
                                        {{ firstInput }}
                                      </h5>
                                      <div
                                        v-else
                                        :class="{ 'signed': firstInputImage }"
                                      >
                                        <b-img
                                          v-if="firstInputImage"
                                          :src="firstInputImage"
                                          class="w-100 signature-image"
                                          alt="sign"
                                        />
                                      </div>
                                      <p>1</p>
                                    </div>
                                    <div
                                      v-else
                                      class="full-width-div"
                                      @click="handleClick('firstInput', 'firstPartRef')"
                                    >
                                      <div class="vertical-single-box-content-empty">
                                        <span v-if="requestForm.status == 'To prepare' || editFormInOtherStatus">1</span>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    class="vertical-single-box"
                                    :class="{ 'bg-white': (thirdInput || thirdInputImage || requestForm.status != 'To prepare'), 'edit-mode': editFormInOtherStatus }"
                                  >
                                    <div
                                      v-if="thirdInput || thirdInputImage"
                                      class="vertical-single-box-content"
                                    >
                                      <p v-if="thirdLiving">
                                        长生
                                      </p>
                                      <h5
                                        v-if="thirdInputType == 'input'"
                                        ref="thirdInputField"
                                        :style="{ 'font-size': getFontSize('thirdInputField') }"
                                        @click="handleClick('thirdInput', 'firstPartRef')"
                                      >
                                        {{ thirdInput }}
                                      </h5>
                                      <div
                                        v-else
                                        :class="{ 'signed': thirdInputImage }"
                                      >
                                        <b-img
                                          v-if="thirdInputImage"
                                          :src="thirdInputImage"
                                          class="w-100 signature-image"
                                          alt="sign"
                                        />
                                      </div>
                                      <p>3</p>
                                    </div>
                                    <div
                                      v-else
                                      class="full-width-div"
                                      @click="handleClick('thirdInput', 'firstPartRef')"
                                    >
                                      <div class="vertical-single-box-content-empty">
                                        <span v-if="requestForm.status == 'To prepare' || editFormInOtherStatus">3</span>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    class="vertical-single-box"
                                    :class="{ 'bg-white': (fifthInput || fifthInputImage || requestForm.status != 'To prepare'), 'edit-mode': editFormInOtherStatus }"
                                  >
                                    <div
                                      v-if="fifthInput || fifthInputImage"
                                      class="vertical-single-box-content"
                                    >
                                      <p v-if="fifthLiving">
                                        长生
                                      </p>
                                      <h5
                                        v-if="fifthInputType == 'input'"
                                        ref="fifthInputField"
                                        :style="{ 'font-size': getFontSize('fifthInputField') }"
                                        @click="handleClick('fifthInput', 'firstPartRef')"
                                      >
                                        {{ fifthInput }}
                                      </h5>
                                      <div
                                        v-else
                                        :class="{ 'signed': fifthInputImage }"
                                      >
                                        <b-img
                                          v-if="fifthInputImage"
                                          :src="fifthInputImage"
                                          class="w-100 signature-image"
                                          alt="sign"
                                        />
                                      </div>
                                      <p>5</p>
                                    </div>
                                    <div
                                      v-else
                                      class="full-width-div"
                                      @click="handleClick('fifthInput', 'firstPartRef')"
                                    >
                                      <div class="vertical-single-box-content-empty">
                                        <span v-if="requestForm.status == 'To prepare' || editFormInOtherStatus">5</span>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </b-col>
                          <b-col
                            class="d-flex justify-content-center vertical-box-right-section-block pl-0"
                            lg="5"
                            md="5"
                            sm="5"
                            cols="12"
                          >
                            <div class="p-1 padding-none padding-10 vertical-box-right-section__border-top">
                              <h6 class="ancestral-table-section-title">
                                無中堂
                              </h6>
                              <div class="vertical-single-box-right-section-block">
                                <div class="vertical-single-box-right-section-heading">
                                  <h4>佛光</h4>
                                  <h4>接引</h4>
                                </div>
                                <div class="vertical-single-box-right-section-main-wrapper">
                                  <div class="vertical-single-box-right-section-wrapper">
                                    <div
                                      class="vertical-single-box-right-section"
                                      :class="{ 'bg-white': (secondInputSecond || secondInputImageSecond || requestForm.status != 'To prepare'), 'edit-mode': editFormInOtherStatus }"
                                    >
                                      <div
                                        v-if="secondInputSecond || secondInputImageSecond"
                                        class="vertical-single-box-content"
                                      >
                                        <p v-if="secondLivingSecond">
                                          长生
                                        </p>
                                        <h5
                                          v-if="secondInputTypeSecond == 'input'"
                                          ref="secondInputFieldSecond"
                                          :style="{ 'font-size': getFontSize('secondInputFieldSecond') }"
                                          @click="handleClick('secondInputSecond', 'secondPartRef')"
                                        >
                                          {{ secondInputSecond }}
                                        </h5>
                                        <div
                                          v-else
                                          :class="{ 'signed': secondInputImageSecond }"
                                        >
                                          <b-img
                                            v-if="secondInputImageSecond"
                                            :src="secondInputImageSecond"
                                            class="w-100 signature-image"
                                            alt="sign"
                                          />
                                        </div>
                                        <p>2</p>
                                      </div>
                                      <div
                                        v-else
                                        class="full-width-div"
                                        @click="handleClick('secondInputSecond', 'secondPartRef')"
                                      >
                                        <div class="vertical-single-box-content-empty">
                                          <span v-if="requestForm.status == 'To prepare' || editFormInOtherStatus">2</span>
                                        </div>
                                      </div>
                                    </div>
                                    <div
                                      class="vertical-single-box-right-section"
                                      :class="{ 'bg-white': (fourthInputSecond || fourthInputImageSecond || requestForm.status != 'To prepare'), 'edit-mode': editFormInOtherStatus }"
                                    >
                                      <div
                                        v-if="fourthInputSecond || fourthInputImageSecond"
                                        class="vertical-single-box-content"
                                      >
                                        <p v-if="fourthLivingSecond">
                                          长生
                                        </p>
                                        <h5
                                          v-if="fourthInputTypeSecond == 'input'"
                                          ref="fourthInputFieldSecond"
                                          :style="{ 'font-size': getFontSize('fourthInputFieldSecond') }"
                                          @click="handleClick('fourthInputSecond', 'secondPartRef')"
                                        >
                                          {{ fourthInputSecond }}
                                        </h5>
                                        <div
                                          v-else
                                          :class="{ 'signed': fourthInputImageSecond }"
                                        >
                                          <b-img
                                            v-if="fourthInputImageSecond"
                                            :src="fourthInputImageSecond"
                                            class="w-100 signature-image"
                                            alt="sign"
                                          />
                                        </div>
                                        <p>4</p>
                                      </div>
                                      <div
                                        v-else
                                        class="full-width-div"
                                        @click="handleClick('fourthInputSecond', 'secondPartRef')"
                                      >
                                        <div class="vertical-single-box-content-empty">
                                          <span v-if="requestForm.status == 'To prepare' || editFormInOtherStatus">4</span>
                                        </div>
                                      </div>
                                    </div>
                                    <div
                                      class="vertical-single-box-right-section"
                                      :class="{ 'bg-white': (sixthInputSecond || sixthInputImageSecond || requestForm.status != 'To prepare'), 'edit-mode': editFormInOtherStatus }"
                                    >
                                      <div
                                        v-if="sixthInputSecond || sixthInputImageSecond"
                                        class="vertical-single-box-content"
                                      >
                                        <p v-if="sixthLivingSecond">
                                          长生
                                        </p>
                                        <h5
                                          v-if="sixthInputTypeSecond == 'input'"
                                          ref="sixthInputFieldSecond"
                                          :style="{ 'font-size': getFontSize('sixthInputFieldSecond') }"
                                          @click="handleClick('sixthInputSecond', 'secondPartRef')"
                                        >
                                          {{ sixthInputSecond }}
                                        </h5>
                                        <div
                                          v-else
                                          :class="{ 'signed': sixthInputImageSecond }"
                                        >
                                          <b-img
                                            v-if="sixthInputImageSecond"
                                            :src="sixthInputImageSecond"
                                            class="w-100 signature-image"
                                            alt="sign"
                                          />
                                        </div>
                                        <p>6</p>
                                      </div>
                                      <div
                                        v-else
                                        class="full-width-div"
                                        @click="handleClick('sixthInputSecond', 'secondPartRef')"
                                      >
                                        <div class="vertical-single-box-content-empty">
                                          <span v-if="requestForm.status == 'To prepare' || editFormInOtherStatus">6</span>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div class="vertical-single-box-right-section-wrapper">
                                    <div
                                      class="vertical-single-box-right-section"
                                      :class="{ 'bg-white': (firstInputSecond || firstInputImageSecond || requestForm.status != 'To prepare'), 'edit-mode': editFormInOtherStatus }"
                                    >
                                      <div
                                        v-if="firstInputSecond || firstInputImageSecond"
                                        class="vertical-single-box-content"
                                      >
                                        <p v-if="firstLivingSecond">
                                          长生
                                        </p>
                                        <h5
                                          v-if="firstInputTypeSecond == 'input'"
                                          ref="firstInputFieldSecond"
                                          :style="{ 'font-size': getFontSize('firstInputFieldSecond') }"
                                          @click="handleClick('firstInputSecond', 'secondPartRef')"
                                        >
                                          {{ firstInputSecond }}
                                        </h5>
                                        <div
                                          v-else
                                          :class="{ 'signed': firstInputImageSecond }"
                                        >
                                          <b-img
                                            v-if="firstInputImageSecond"
                                            :src="firstInputImageSecond"
                                            class="w-100 signature-image"
                                            alt="sign"
                                          />
                                        </div>
                                        <p>1</p>
                                      </div>
                                      <div
                                        v-else
                                        class="full-width-div"
                                        @click="handleClick('firstInputSecond', 'secondPartRef')"
                                      >
                                        <div class="vertical-single-box-content-empty">
                                          <span v-if="requestForm.status == 'To prepare' || editFormInOtherStatus">1</span>
                                        </div>
                                      </div>
                                    </div>
                                    <div
                                      class="vertical-single-box-right-section"
                                      :class="{ 'bg-white': (thirdInputSecond || thirdInputImageSecond || requestForm.status != 'To prepare'), 'edit-mode': editFormInOtherStatus }"
                                    >
                                      <div
                                        v-if="thirdInputSecond || thirdInputImageSecond"
                                        class="vertical-single-box-content"
                                      >
                                        <p v-if="thirdLivingSecond">
                                          长生
                                        </p>
                                        <h5
                                          v-if="thirdInputTypeSecond == 'input'"
                                          ref="thirdInputFieldSecond"
                                          :style="{ 'font-size': getFontSize('thirdInputFieldSecond') }"
                                          @click="handleClick('thirdInputSecond', 'secondPartRef')"
                                        >
                                          {{ thirdInputSecond }}
                                        </h5>
                                        <div
                                          v-else
                                          :class="{ 'signed': thirdInputImageSecond }"
                                        >
                                          <b-img
                                            v-if="thirdInputImageSecond"
                                            :src="thirdInputImageSecond"
                                            class="w-100 signature-image"
                                            alt="sign"
                                          />
                                        </div>
                                        <p>3</p>
                                      </div>
                                      <div
                                        v-else
                                        class="full-width-div"
                                        @click="handleClick('thirdInputSecond', 'secondPartRef')"
                                      >
                                        <div class="vertical-single-box-content-empty">
                                          <span v-if="requestForm.status == 'To prepare' || editFormInOtherStatus">3</span>
                                        </div>
                                      </div>
                                    </div>
                                    <div
                                      class="vertical-single-box-right-section"
                                      :class="{ 'bg-white': (fifthInputSecond || fifthInputImageSecond || requestForm.status != 'To prepare'), 'edit-mode': editFormInOtherStatus }"
                                    >
                                      <div
                                        v-if="fifthInputSecond || fifthInputImageSecond"
                                        class="vertical-single-box-content"
                                      >
                                        <p v-if="fifthLivingSecond">
                                          长生
                                        </p>
                                        <h5
                                          v-if="fifthInputTypeSecond == 'input'"
                                          ref="fifthInputFieldSecond"
                                          :style="{ 'font-size': getFontSize('fifthInputFieldSecond') }"
                                          @click="handleClick('fifthInputSecond', 'secondPartRef')"
                                        >
                                          {{ fifthInputSecond }}
                                        </h5>
                                        <div
                                          v-else
                                          :class="{ 'signed': fifthInputImageSecond }"
                                        >
                                          <b-img
                                            v-if="fifthInputImageSecond"
                                            :src="fifthInputImageSecond"
                                            class="w-100 signature-image"
                                            alt="sign"
                                          />
                                        </div>
                                        <p>5</p>
                                      </div>
                                      <div
                                        v-else
                                        class="full-width-div"
                                        @click="handleClick('fifthInputSecond', 'secondPartRef')"
                                      >
                                        <div class="vertical-single-box-content-empty">
                                          <span v-if="requestForm.status == 'To prepare' || editFormInOtherStatus">5</span>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div class="vertical-single-box-right-section-heading-bottom">
                                  <h4>之</h4>
                                  <h4>蓮</h4>
                                  <h4>位</h4>
                                </div>
                              </div>
                            </div>
                          </b-col>
                        </b-row>
                      </div>
                    </b-col>
                    <b-col
                      lg="4"
                      md="4"
                      sm="4"
                      cols="12"
                    >
                      <div class="d-form-border my-2 mobile_res-adjust-margin">
                        <div class="d-form-photo-status-block">
                          <div class="d-form-heading-block">
                            <h4>
                              Photo Received
                              <br>
                              <span>照片提供</span>
                            </h4>
                          </div>
                          <b-form-group
                            label-class="ancestral-label-adjust"
                            label="Received By 收件:"
                            label-for="input-default"
                          >
                            <div
                              class="empty-input__text small-d__input-height"
                              :class="{ 'bg-white': (requestForm.status != 'To prepare' || photoReceivedBy), 'edit-mode': editFormInOtherStatus }"
                              @click="handleClick('photoReceivedBy', 'photoDetailsRef')"
                            >
                              <p
                                v-if="(requestForm.status == 'To prepare' || editFormInOtherStatus) && !photoReceivedBy"
                                class="placeholder__text"
                              />
                              <p v-else>
                                {{ photoReceivedBy || '-' }}
                              </p>
                            </div>
                          </b-form-group>
                          <b-form-group
                            label-class="ancestral-label-adjust"
                            label="Date 日期:"
                            label-for="input-default"
                          >
                            <div
                              class="empty-input__text small-d__input-height"
                              :class="{ 'bg-white': (requestForm.status != 'To prepare' || photoReceivedDate), 'edit-mode': editFormInOtherStatus }"
                              @click="handleClickDateField('photoReceivedDate', 'photoDetailsRef')"
                            >
                              <p
                                v-if="(requestForm.status == 'To prepare' || editFormInOtherStatus) && !photoReceivedDate"
                                class="date__placeholder__text"
                              />
                              <p v-else>
                                {{ photoReceivedDate || '-' }}
                              </p>
                            </div>
                          </b-form-group>
                          <div class="d-form-heading-block">
                            <h4>
                              Photo Returned
                              <br>
                              <span>照片归还</span>
                            </h4>
                          </div>
                          <b-form-group
                            label-class="ancestral-label-adjust"
                            label="Claimed By 照片领回:"
                            label-for="input-default"
                          >
                            <div
                              class="empty-input__text small-d__input-height"
                              :class="{ 'bg-white': (requestForm.status != 'To prepare' || photoClaimedBy), 'edit-mode': editFormInOtherStatus }"
                              @click="handleClick('photoClaimedBy', 'photoDetailsRef')"
                            >
                              <p
                                v-if="(requestForm.status == 'To prepare' || editFormInOtherStatus) && !photoClaimedBy"
                                class="placeholder__text"
                              />
                              <p v-else>
                                {{ photoClaimedBy || '-' }}
                              </p>
                            </div>
                          </b-form-group>
                          <b-form-group
                            label-class="ancestral-label-adjust"
                            label="Date 日期:"
                            label-for="input-default"
                          >
                            <div
                              class="empty-input__text small-d__input-height"
                              :class="{ 'bg-white': (requestForm.status != 'To prepare' || photoClaimedDate), 'edit-mode': editFormInOtherStatus }"
                              @click="handleClickDateField('photoClaimedDate', 'photoDetailsRef')"
                            >
                              <p
                                v-if="(requestForm.status == 'To prepare' || editFormInOtherStatus) && !photoClaimedDate"
                                class="date__placeholder__text"
                              />
                              <p v-else>
                                {{ photoClaimedDate || '-' }}
                              </p>
                            </div>
                          </b-form-group>
                          <div class="d-form-heading-block">
                            <h4>Remarks 备注</h4>
                          </div>
                          <div class="ancestral-form-remarks">
                            <p>All Chinese Character on the plaque must be in traditional Chinese text.<br><span>碑文均採繁體</span></p>
                          </div>
                        </div>
                      </div>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col md="12">
                      <b-form-group
                        label-cols="2"
                        label-cols-lg="2"
                        label-for="input-default"
                        class="align-items-center"
                      >
                        <template #label>
                          <span>Purchaser*</span>
                          <br>
                          <span>购买者</span>
                        </template>
                        <div
                          v-if="customerNameType == 'input'"
                          class="empty-input__text"
                          :class="{ 'bg-white': (requestForm.status != 'To prepare' || customerName), 'edit-mode': editFormInOtherStatus }"
                          @click="handleClick('customerName', 'purchaserDetailsRef')"
                        >
                          <p
                            v-if="(requestForm.status == 'To prepare' || editFormInOtherStatus) && !customerName"
                            class="placeholder__text"
                          />
                          <p v-else>
                            {{ customerName || '-' }}
                          </p>
                        </div>
                        <div
                          v-else
                          :class="{ 'signed': customerNameImage }"
                          style="border-bottom: 1px solid #000"
                        >
                          <b-img
                            v-if="customerNameImage"
                            :src="customerNameImage"
                            class="w-100 signature-image"
                            alt="sign"
                          />
                        </div>
                      </b-form-group>
                    </b-col>
                    <b-col md="6">
                      <b-form-group
                        label-cols="4"
                        label-cols-lg="4"
                        label-for="input-default"
                        class="align-items-center"
                      >
                        <template #label>
                          <span>Contact No.</span>
                          <br>
                          <span>联络号码</span>
                        </template>
                        <div
                          class="empty-input__text"
                          :class="{ 'bg-white': (requestForm.status != 'To prepare' || customerContact), 'edit-mode': editFormInOtherStatus }"
                          @click="handleClick('customerContact', 'purchaserDetailsRef')"
                        >
                          <p
                            v-if="(requestForm.status == 'To prepare' || editFormInOtherStatus) && !customerContact"
                            class="placeholder__text"
                          />
                          <p v-else>
                            {{ customerContact || '-' }}
                          </p>
                        </div>
                      </b-form-group>
                    </b-col>
                    <b-col md="6">
                      <b-form-group
                        label-cols="4"
                        label-cols-lg="4"
                        label-for="input-default"
                        class="align-items-center"
                      >
                        <template #label>
                          <span>NRIC*</span>
                          <br>
                          <span>身分证号码</span>
                        </template>
                        <div
                          class="empty-input__text"
                          :class="{ 'bg-white': (requestForm.status != 'To prepare' || customerNRIC), 'edit-mode': editFormInOtherStatus }"
                          @click="handleClick('customerNRIC', 'purchaserDetailsRef')"
                        >
                          <p
                            v-if="(requestForm.status == 'To prepare' || editFormInOtherStatus) && !customerNRIC"
                            class="placeholder__text"
                          />
                          <p v-else>
                            {{ customerNRIC || '-' }}
                          </p>
                        </div>
                      </b-form-group>
                    </b-col>

                    <b-col md="12">
                      <b-form-group
                        label-class="label-width-150"
                        label-cols="2"
                        label-cols-lg="2"
                        label-for="input-default"
                        class="align-items-center"
                      >
                        <template #label>
                          <span>Next of Kin Name*</span>
                          <br>
                          <span>至亲姓名</span>
                        </template>
                        <div
                          v-if="kinNameType == 'input'"
                          class="empty-input__text"
                          :class="{ 'bg-white': (requestForm.status != 'To prepare' || kinName), 'edit-mode': editFormInOtherStatus }"
                          @click="handleClick('kinName', 'kinDetailsRef')"
                        >
                          <p
                            v-if="(requestForm.status == 'To prepare' || editFormInOtherStatus) && !kinName"
                            class="placeholder__text"
                          />
                          <p v-else>
                            {{ kinName || '-' }}
                          </p>
                        </div>
                        <div
                          v-else
                          :class="{ 'signed': kinNameImage }"
                          style="border-bottom: 1px solid #000"
                        >
                          <b-img
                            v-if="kinNameImage"
                            :src="kinNameImage"
                            class="w-100 signature-image"
                            alt="sign"
                          />
                        </div>
                      </b-form-group>
                    </b-col>
                    <b-col md="6">
                      <b-form-group
                        label-cols="4"
                        label-cols-lg="4"
                        label-for="input-default"
                        class="align-items-center"
                      >
                        <template #label>
                          <span>Contact No.</span>
                          <br>
                          <span>联络号码</span>
                        </template>
                        <div
                          class="empty-input__text"
                          :class="{ 'bg-white': (requestForm.status != 'To prepare' || kinContact), 'edit-mode': editFormInOtherStatus }"
                          @click="handleClick('kinContact', 'kinDetailsRef')"
                        >
                          <p
                            v-if="(requestForm.status == 'To prepare' || editFormInOtherStatus) && !kinContact"
                            class="placeholder__text"
                          />
                          <p v-else>
                            {{ kinContact || '-' }}
                          </p>
                        </div>
                      </b-form-group>
                    </b-col>
                    <b-col md="6">
                      <b-form-group
                        label-cols="4"
                        label-cols-lg="4"
                        label-for="input-default"
                        class="align-items-center"
                      >
                        <template #label>
                          <span>NRIC*</span>
                          <br>
                          <span>身分证号码</span>
                        </template>
                        <div
                          class="empty-input__text"
                          :class="{ 'bg-white': (requestForm.status != 'To prepare' || kinNRIC), 'edit-mode': editFormInOtherStatus }"
                          @click="handleClick('kinNRIC', 'kinDetailsRef')"
                        >
                          <p
                            v-if="(requestForm.status == 'To prepare' || editFormInOtherStatus) && !kinNRIC"
                            class="placeholder__text"
                          />
                          <p v-else>
                            {{ kinNRIC || '-' }}
                          </p>
                        </div>
                      </b-form-group>
                    </b-col>

                    <b-col md="6">
                      <b-form-group
                        label-cols="4"
                        label-cols-lg="4"
                        label-for="input-default"
                        class="align-items-center"
                      >
                        <template #label>
                          <span>File No.*</span>
                          <br>
                          <span>文件号码</span>
                        </template>
                        <div
                          class="empty-input__text"
                          :class="{ 'bg-white': (requestForm.status != 'To prepare' || fileNo), 'edit-mode': editFormInOtherStatus }"
                          @click="handleClick('fileNo', 'othersDetailsRef')"
                        >
                          <p
                            v-if="(requestForm.status == 'To prepare' || editFormInOtherStatus) && !fileNo"
                            class="placeholder__text"
                          />
                          <p v-else>
                            {{ fileNo || '-' }}
                          </p>
                        </div>
                      </b-form-group>
                    </b-col>
                    <b-col md="6">
                      <b-form-group
                        label-class="label-width-120"
                        label-cols="4"
                        label-cols-lg="4"
                        label-for="input-default"
                        class="align-items-center"
                      >
                        <template #label>
                          <span>Pedestal No.*</span>
                          <br>
                          <span>牌位编号</span>
                        </template>
                        <div
                          class="empty-input__text"
                          :class="{ 'bg-white': (requestForm.status != 'To prepare' || pedestalNo), 'edit-mode': editFormInOtherStatus }"
                          @click="handleClick('pedestalNo', 'othersDetailsRef')"
                        >
                          <p
                            v-if="(requestForm.status == 'To prepare' || editFormInOtherStatus) && !pedestalNo"
                            class="placeholder__text"
                          />
                          <p v-else>
                            {{ pedestalNo || '-' }}
                          </p>
                        </div>
                      </b-form-group>
                    </b-col>
                    <b-col md="6">
                      <b-form-group
                        label-class="label-width-110"
                        label-cols="4"
                        label-cols-lg="4"
                        label-for="input-default"
                        class="align-items-center"
                      >
                        <template #label>
                          <span>Agent Name*</span>
                          <br>
                          <span>代理姓名</span>
                        </template>
                        <div
                          class="empty-input__text"
                          :class="{ 'bg-white': (requestForm.status != 'To prepare' || agentName), 'edit-mode': editFormInOtherStatus }"
                          @click="handleClick('agentName', 'othersDetailsRef')"
                        >
                          <p
                            v-if="(requestForm.status == 'To prepare' || editFormInOtherStatus) && !agentName"
                            class="placeholder__text"
                          />
                          <p v-else>
                            {{ agentName || '-' }}
                          </p>
                        </div>
                      </b-form-group>
                    </b-col>
                    <b-col md="6">
                      <b-form-group
                        label-class="label-width-150"
                        label-cols="4"
                        label-cols-lg="4"
                        label-for="input-default"
                        class="align-items-center"
                      >
                        <template #label>
                          <span>Agent Contact No.</span>
                          <br>
                          <span>代理联络电话</span>
                        </template>
                        <div
                          class="empty-input__text"
                          :class="{ 'bg-white': (requestForm.status != 'To prepare' || agentContact), 'edit-mode': editFormInOtherStatus }"
                          @click="handleClick('agentContact', 'othersDetailsRef')"
                        >
                          <p
                            v-if="(requestForm.status == 'To prepare' || editFormInOtherStatus) && !agentContact"
                            class="placeholder__text"
                          />
                          <p v-else>
                            {{ agentContact || '-' }}
                          </p>
                        </div>
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col cols="12">
                      <b-form-group
                        label-cols="4"
                        label-cols-lg="2"
                        label-for="input-default"
                        class="align-items-center"
                      >
                        <template #label>
                          <span>Remarks</span>
                          <br>
                          <span>备注</span>
                        </template>
                        <div
                          class="empty-input__text"
                          :class="{ 'bg-white': (requestForm.status != 'To prepare' || remarks), 'edit-mode': editFormInOtherStatus }"
                          @click="handleClick('remarks', 'othersDetailsRef')"
                        >
                          <p
                            v-if="(requestForm.status == 'To prepare' || editFormInOtherStatus) && !remarks"
                            class="placeholder__text"
                          />
                          <p v-else>
                            {{ remarks || '-' }}
                          </p>
                        </div>
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <div class="d-form-disclaimer_text-block">
                    <p>Disclaimer: I hereby confirm that all the information and characters herein are correct and authorised Mount Prajna Ltd to engrave the Plaque in accordance to this notice. The Plaque will be available after 30 days from the date of this notice.</p>
                    <p>
                      声明： 本人确定所附资料正确无误，并请遵照刻碑，本表格签署三十天后，即请安排上碑。
                    </p>
                  </div>
                  <b-row class="d-form-signature_block">
                    <b-col md="6">
                      <p>Purchaser’s / Next of Kin’s Signatory & Date
                        <br>
                        <span>购者或至亲签名</span>
                      </p>
                      <div
                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                        v-b-modal.purchaser-sign-modal
                        class="form-signature-box"
                        :class="{ 'signed': isPurchaserSign, 'edit-mode': editFormInOtherStatus }"
                        :disabled="(requestForm.status == 'Signed and Sent' && !editFormInOtherStatus) || ['To prepare', 'Ready to sign', 'Not required'].includes(requestForm.status)"
                      >
                        <div class="form-signature-content">
                          <span
                            v-if="!isPurchaserSign"
                            class="form-signature-text"
                          >
                            Sign here
                          </span>
                          <b-img
                            v-else
                            :src="purchaserSign"
                            alt="sign"
                          />
                        </div>
                      </div>
                      <div class="signature-date-block">
                        <p>Name 姓名: {{ customerName }}</p>
                        <p v-if="isPurchaserSign">
                          Date 日期: {{ dateFormatWithTime(purchaserSignDate) }}
                        </p>
                        <p v-else>
                          Date 日期:
                        </p>
                      </div>
                    </b-col>
                    <b-col md="6">
                      <p>Completion checked
                        <br>
                        <span>碑文检查，确认签名 / 日期</span>
                      </p>
                      <div
                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                        v-b-modal.completion-sign-modal
                        class="form-signature-box"
                        :class="{ 'signed': completionSign, 'edit-mode': editFormInOtherStatus }"
                        :disabled="(requestForm.status == 'Signed and Sent' && !editFormInOtherStatus) || ['To prepare', 'Ready to sign', 'Not required'].includes(requestForm.status)"
                      >
                        <div class="form-signature-content">
                          <span
                            v-if="!completionSign"
                            class="form-signature-text"
                          >
                            Sign here
                          </span>
                          <img
                            v-else
                            :src="completionSignSrc"
                            alt="authorized-sign"
                          >
                        </div>
                      </div>
                      <div class="signature-date-block">
                        <p>Name 姓名: {{ completionSignName }}</p>
                        <p v-if="completionSign">
                          Date 日期: {{ dateFormatWithTime(completionSignDate) }}
                        </p>
                        <p v-else>
                          Date 日期:
                        </p>
                      </div>
                    </b-col>
                  </b-row>
                  <div class="d-form-border mt-4">
                    <h5 class="d-form-office-use-title">
                      For office use 公务用栏
                    </h5>
                    <b-row>
                      <b-col
                        md="2"
                        class="d-form-border-right pr-0 border-right-none padding-right-auto"
                      >
                        <div class="padding-20">
                          <b-form-group
                            label="Remark 备注:"
                            label-for="input-default"
                          >
                            <div
                              class="empty-input__text"
                              :class="{ 'bg-white': (requestForm.status != 'To prepare' || serviceType), 'edit-mode': editFormInOtherStatus }"
                              @click="handleClick('serviceType', 'officeDetailsRef')"
                            >
                              <p
                                v-if="(requestForm.status == 'To prepare' || editFormInOtherStatus) && !serviceType"
                                class="placeholder__text"
                              />
                              <p v-else>
                                {{ serviceType || '-' }}
                              </p>
                            </div>
                          </b-form-group>
                        </div>
                      </b-col>
                      <b-col
                        md="5"
                        class="d-form-border-right p-0 border-right-none padding-left-auto padding-right-auto"
                      >
                        <div class="padding-20">
                          <b-form-group
                            label="Person in Charge 服务人员:"
                            label-for="input-default"
                          >
                            <div
                              class="empty-input__text"
                              :class="{ 'bg-white': (requestForm.status != 'To prepare' || inChargePerson), 'edit-mode': editFormInOtherStatus }"
                              @click="handleClick('inChargePerson', 'officeDetailsRef')"
                            >
                              <p
                                v-if="(requestForm.status == 'To prepare' || editFormInOtherStatus) && !inChargePerson"
                                class="placeholder__text"
                              />
                              <p v-else>
                                {{ inChargePerson || '-' }}
                              </p>
                            </div>
                          </b-form-group>
                        </div>
                      </b-col>
                      <b-col
                        md="5"
                        class="pl-0 padding-left-auto"
                      >
                        <div class="padding-20">
                          <b-form-group
                            label="Installation Date 预定上位日期:"
                            label-for="input-default"
                          >
                            <div
                              class="empty-input__text"
                              :class="{ 'bg-white': (requestForm.status != 'To prepare' || installationDate), 'edit-mode': editFormInOtherStatus }"
                              @click="handleClickDateField('installationDate', 'officeDetailsRef')"
                            >
                              <p
                                v-if="(requestForm.status == 'To prepare' || editFormInOtherStatus) && !installationDate"
                                class="date__placeholder__text"
                              />
                              <p v-else>
                                {{ installationDate || '-' }}
                              </p>
                            </div>
                          </b-form-group>
                          <b-form-group
                            label="Submitted Date 刻碑资料提交日期:"
                            label-for="input-default"
                          >
                            <div
                              class="empty-input__text"
                              :class="{ 'bg-white': (requestForm.status != 'To prepare' || submitDate), 'edit-mode': editFormInOtherStatus }"
                              @click="handleClickDateField('submitDate', 'officeDetailsRef')"
                            >
                              <p
                                v-if="(requestForm.status == 'To prepare' || editFormInOtherStatus) && !submitDate"
                                class="date__placeholder__text"
                              />
                              <p v-else>
                                {{ submitDate || '-' }}
                              </p>
                            </div>
                          </b-form-group>
                          <b-form-group
                            label="Email Date 校对检查电邮日期:"
                            label-for="input-default"
                          >
                            <div
                              class="empty-input__text"
                              :class="{ 'bg-white': (requestForm.status != 'To prepare' || emailDate), 'edit-mode': editFormInOtherStatus }"
                              @click="handleClickDateField('emailDate', 'officeDetailsRef')"
                            >
                              <p
                                v-if="(requestForm.status == 'To prepare' || editFormInOtherStatus) && !emailDate"
                                class="date__placeholder__text"
                              />
                              <p v-else>
                                {{ emailDate || '-' }}
                              </p>
                            </div>
                          </b-form-group>
                        </div>
                      </b-col>
                    </b-row>
                  </div>
                </div>
              </div>
            </b-card>
          </b-col>
          <b-col
            v-if="(requestForm.status == 'To prepare' || editFormInOtherStatus)"
            class="content-header-right my-1 d-md-block"
            style="position: relative;"
            md="3"
            cols="12"
          />
          <b-col
            v-if="(requestForm.status == 'To prepare' || editFormInOtherStatus)"
            class="content-header-right my-1 d-md-block"
            :class="{ 'show-error': showFileUploadError }"
            style="position: relative;"
            md="9"
            cols="12"
          >
            <vue-dropzone
              id="event-images"
              ref="myVueDropzone"
              :options="dropzoneOptions"
              :use-custom-slot="true"
              @vdropzone-files-added="processFile"
              @vdropzone-removed-file="fileRemoved"
              @vdropzone-error="handleError"
            >
              <div class="dropzone-custom-content">
                <h3 class="dropzone-custom-title">
                  Drop file here or click to upload
                </h3>
                <div class="subtitle">
                  Files should not be bigger than 20 mb. JPEG, PNG or PDF only.
                </div>
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  type="button"
                  variant="primary"
                  class="mt-2 no-float"
                >
                  Upload file
                </b-button>
              </div>
            </vue-dropzone>
          </b-col>
          <div
            v-if="requestForm.status == 'To prepare'"
            class="d-form_action-btn_group wrap-border save-nav"
          >
            <p
              v-if="isMobile()"
              class="margin-left-20 mt-50 mb-50 d-form_action-title"
            >
              <span>Ancestral Tablets Engraving Notice</span>
            </p>
            <b-nav
              class="padding-left-right-20"
              :class="{'d-form_action-btn_tablet': isMobile()}"
            >
              <li
                v-if="!isMobile()"
                class="nav-item"
              >
                <span>Ancestral Tablets Engraving Notice</span>
              </li>
              <li
                class="nav-item"
                :class="{'d-form_action-btn__mob-li': isMobile(), 'ml-auto margin-right-5': !isMobile()}"
              >
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  type="button"
                  variant="outline-primary"
                  @click="submitSaveForm('saveOnly')"
                >
                  Save changes
                </b-button>
              </li>
              <li
                class="nav-item d-form_action-second_btn"
              >
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  type="button"
                  variant="primary"
                  @click="makeFormNotRequired"
                >
                  <feather-icon
                    icon="XCircleIcon"
                    class="mr-50"
                  />
                  <span class="align-middle">Mark as Not Required</span>
                </b-button>
              </li>
              <li
                class="nav-item d-form_action-third_btn"
              >
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  type="button"
                  variant="success"
                  @click="submitSaveForm('readyToSign')"
                >
                  <feather-icon
                    icon="FeatherIcon"
                    class="mr-50"
                  />
                  <span class="align-middle">Mark as Ready to Sign</span>
                </b-button>
              </li>
            </b-nav>
          </div>

          <div
            v-if="editFormInOtherStatus"
            class="d-form_action-btn_group wrap-border save-nav"
          >
            <p
              v-if="isMobile()"
              class="margin-left-20 mt-50 mb-50 d-form_action-title"
            >
              <span>Editing Ancestral Tablets Engraving Notice</span>
            </p>
            <b-nav
              class="padding-left-right-20"
              :class="{'d-form_action-btn_tablet': isMobile()}"
            >
              <li
                v-if="!isMobile()"
                class="nav-item"
              >
                <span>Editing Ancestral Tablets Engraving Notice</span>
              </li>
              <li
                :class="{'ml-auto margin-right-5': !isMobile(), 'mob__cancel-btn': isMobile()}"
              >
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  type="button"
                  variant="outline-primary"
                  class="action-cancel_btn"
                  @click="editFormInOtherStatus = false; makeFormValues()"
                >
                  Cancel Editing
                </b-button>
              </li>
              <li
                class="nav-item"
                :class="{'d-form_action-editing__btn': isMobile()}"
              >
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  type="button"
                  variant="success"
                  class="action-done_btn"
                  @click="submitSaveFormConfirmation()"
                >
                  <feather-icon
                    icon="CheckIcon"
                    class="mr-50"
                  />
                  <span class="align-middle">Done</span>
                </b-button>
              </li>
            </b-nav>
          </div>
        </b-row>
      </b-form>
    </validation-observer>

    <!-- purchaser signature modal -->
    <b-modal
      id="purchaser-sign-modal"
      ref="purchaser-sign-modal"
      modal-class="status-update-modal sign-modal-adjust"
      centered
      no-stacking
      no-close-on-backdrop
      header-class="front-modal"
      title="Purchaser's Signature"
      @ok="closePurchaserSignModal"
      @cancel="closePurchaserSignModal"
    >
      <template #modal-title>
        <span>
          Signature
        </span>
        <br>
        <span class="weight-400 fs-14">Purchaser’s / Next of Kin’s Signatory & Date</span>
      </template>
      <div class="">
        <VueSignaturePad
          ref="signaturePad"
          class="border rounded"
          width="100%"
          height="260px"
          :options="options"
        />
      </div>
      <template #modal-footer="{ cancel }">
        <b-button
          variant="flat-primary"
          class="mr-auto ml-0 pl-0"
          @click="clearPurchaserSign()"
        >
          Clear
        </b-button>
        <b-button
          variant="outline-primary"
          @click="cancel()"
        >
          Cancel
        </b-button>
        <b-button
          variant="primary"
          @click="savePurchaserSign()"
        >
          <span class="align-middle">Save</span>
        </b-button>
      </template>
    </b-modal>
    <!-- ./ purchaser signature modal -->
    <!-- completion signature modal -->
    <b-modal
      id="completion-sign-modal"
      ref="completion-sign-modal"
      modal-class="status-update-modal sign-modal-adjust"
      centered
      no-stacking
      no-close-on-backdrop
      header-class="front-modal"
      title="Completion Signature"
      @ok="closeCompletionSignModal"
      @cancel="closeCompletionSignModal"
    >
      <template #modal-title>
        <span>
          Signature
        </span>
        <br>
        <span class="weight-400 fs-14">Completion Checked</span>
      </template>
      <div class="">
        <VueSignaturePad
          ref="signaturePad"
          class="border rounded"
          width="100%"
          height="260px"
          :options="options"
        />
      </div>
      <template #modal-footer="{ cancel }">
        <b-button
          variant="flat-primary"
          class="mr-auto ml-0 pl-0"
          @click="clearCompletionSign()"
        >
          Clear
        </b-button>
        <b-button
          variant="outline-primary"
          @click="cancel()"
        >
          Cancel
        </b-button>
        <b-button
          variant="primary"
          @click="saveCompletionSign()"
        >
          <span class="align-middle">Save</span>
        </b-button>
      </template>
    </b-modal>

    <!-- completion signature modal -->
    <!-- name modal -->
    <b-modal
      id="name-field-modal"
      ref="name-field-modal"
      modal-class="status-update-modal sign-modal-adjust"
      centered
      no-stacking
      no-close-on-esc
      no-close-on-backdrop
      hide-header-close
      header-class="front-modal"
      title="Handwriting 手写"
      @cancel="closeNameFieldModal"
    >
      <div class="">
        <VueSignaturePad
          ref="nameInputPad"
          class="border rounded"
          width="100%"
          height="260px"
          :options="nameInputOptions"
        />
      </div>
      <template #modal-footer="{ cancel }">
        <b-button
          variant="flat-primary"
          class="mr-auto ml-0 pl-0"
          @click="clearNameInputPad()"
        >
          Clear
        </b-button>
        <b-button
          variant="outline-primary"
          @click="cancel()"
        >
          Cancel
        </b-button>
        <b-button
          variant="primary"
          @click="saveNameInput()"
        >
          <span class="align-middle">Save</span>
        </b-button>
      </template>
    </b-modal>

    <!-- name modal -->
    <b-modal
      id="second-name-field-modal"
      ref="second-name-field-modal"
      modal-class="status-update-modal sign-modal-adjust"
      centered
      no-stacking
      no-close-on-esc
      no-close-on-backdrop
      hide-header-close
      header-class="front-modal"
      title="Handwriting 手写"
      @cancel="closeSecondNameFieldModal"
    >
      <div class="">
        <VueSignaturePad
          ref="secondNameInputPad"
          class="border rounded"
          width="100%"
          height="260px"
          :options="secondNameInputOptions"
        />
      </div>
      <template #modal-footer="{ cancel }">
        <b-button
          variant="flat-primary"
          class="mr-auto ml-0 pl-0"
          @click="clearSecondNameInputPad()"
        >
          Clear
        </b-button>
        <b-button
          variant="outline-primary"
          @click="cancel()"
        >
          Cancel
        </b-button>
        <b-button
          variant="primary"
          @click="saveSecondNameInput()"
        >
          <span class="align-middle">Save</span>
        </b-button>
      </template>
    </b-modal>
    <!-- customer name modal -->
    <b-modal
      id="customer-name-modal"
      ref="customer-name-modal"
      modal-class="status-update-modal sign-modal-adjust"
      centered
      no-stacking
      no-close-on-esc
      no-close-on-backdrop
      hide-header-close
      header-class="front-modal"
      title="Handwriting 手写"
      @cancel="closeCustomerNameModal"
    >
      <div class="">
        <VueSignaturePad
          ref="customerSignaturePad"
          class="border rounded"
          width="100%"
          height="260px"
          :options="customerNameOptions"
        />
      </div>
      <template #modal-footer="{ cancel }">
        <b-button
          variant="flat-primary"
          class="mr-auto ml-0 pl-0"
          @click="clearCustomerName()"
        >
          Clear
        </b-button>
        <b-button
          variant="outline-primary"
          @click="cancel()"
        >
          Cancel
        </b-button>
        <b-button
          variant="primary"
          @click="saveCustomerName()"
        >
          <span class="align-middle">Save</span>
        </b-button>
      </template>
    </b-modal>
    <!-- customer name -->
    <!-- kin name modal -->
    <b-modal
      id="kin-name-modal"
      ref="kin-name-modal"
      modal-class="status-update-modal sign-modal-adjust"
      centered
      no-stacking
      no-close-on-esc
      no-close-on-backdrop
      hide-header-close
      header-class="front-modal"
      title="Handwriting 手写"
      @cancel="closeKinNameModal"
    >
      <div class="">
        <VueSignaturePad
          ref="kinSignaturePad"
          class="border rounded"
          width="100%"
          height="260px"
          :options="kinNameOptions"
        />
      </div>
      <template #modal-footer="{ cancel }">
        <b-button
          variant="flat-primary"
          class="mr-auto ml-0 pl-0"
          @click="clearKinName()"
        >
          Clear
        </b-button>
        <b-button
          variant="outline-primary"
          @click="cancel()"
        >
          Cancel
        </b-button>
        <b-button
          variant="primary"
          @click="saveKinName()"
        >
          <span class="align-middle">Save</span>
        </b-button>
      </template>
    </b-modal>

    <!-- office use section modal -->
    <b-modal
      id="office-use-section-modal"
      ref="office-use-section-modal"
      modal-class="status-update-modal"
      centered
      no-stacking
      no-close-on-backdrop
      header-class="front-modal"
      title="For office use 公务用栏"
      @ok="saveOfficeSectionData"
      @cancel="closeOfficeSectionModal"
    >
      <div class="">
        <!-- <b-form-checkbox-group
          v-model="serviceTypeValueLocal"
          :options="needOptions"
          name="need"
          class="form-custom-input-checkbox"
          @input="makeCheckboxToRadio()"
        /> -->
        <b-form-group
          label="Remark 备注"
          label-for="service-type"
        >
          <validation-provider
            #default="{ errors }"
            name="Remark 备注"
            vid="service-type"
            rules=""
          >
            <b-form-input
              id="service-type"
              v-model="serviceTypeValueLocal"
              placeholder="Remarks"
              :state="(errors.length > 0) ? false : null"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
        <b-form-group
          label="Person in Charge 服务人员"
          label-for="person-in-charge"
        >
          <validation-provider
            #default="{ errors }"
            name="Person in Charge 服务人员"
            vid="person-in-charge"
            rules=""
          >
            <b-form-input
              id="person-in-charge"
              v-model="inChargePersonLocal"
              placeholder="Person in Charge"
              :state="(errors.length > 0) ? false : null"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
        <validation-provider
          #default="{ errors }"
          name="Installation Date"
          vid="installationDate"
          rules=""
        >
          <b-form-group
            label="Installation Date 预定上位日期"
            label-for="installationDate"
            :class="{ 'is-invalid': errors.length }"
          >
            <b-input-group>
              <flat-pickr
                id="installationDate"
                ref="installationDate"
                v-model="installationDateLocal"
                class="form-control flat-pickr-group"
                placeholder="Installation Date p"
                :config="flatPickrConfigInstallationDate"
              />
              <b-input-group-append is-text>
                <feather-icon
                  icon="CalendarIcon"
                  class="cursor-pointer"
                  data-toggle
                  size="18"
                />
              </b-input-group-append>
            </b-input-group>
            <small class="text-danger">{{ errors[0] }}</small>
          </b-form-group>
        </validation-provider>
        <validation-provider
          #default="{ errors }"
          name="Submit Date"
          vid="submitDate"
          rules=""
        >
          <b-form-group
            label="Submit Date 刻碑资料提交日期*"
            label-for="submitDate"
            :class="{ 'is-invalid': errors.length }"
          >
            <b-input-group>
              <flat-pickr
                id="submitDate"
                ref="submitDate"
                v-model="submitDateLocal"
                class="form-control flat-pickr-group"
                placeholder="Submit Date"
                :config="flatPickrConfig"
              />
              <b-input-group-append is-text>
                <feather-icon
                  icon="CalendarIcon"
                  class="cursor-pointer"
                  data-toggle
                  size="18"
                />
              </b-input-group-append>
            </b-input-group>
            <small class="text-danger">{{ errors[0] }}</small>
          </b-form-group>
        </validation-provider>
        <validation-provider
          #default="{ errors }"
          name="Email Date"
          vid="emailDate"
          rules=""
        >
          <b-form-group
            label="Email Date 校对检查电邮日期*"
            label-for="emailDate"
            :class="{ 'is-invalid': errors.length }"
          >
            <b-input-group>
              <flat-pickr
                id="emailDate"
                ref="emailDate"
                v-model="emailDateLocal"
                class="form-control flat-pickr-group"
                placeholder="Email Date"
                :config="flatPickrConfig"
              />
              <b-input-group-append is-text>
                <feather-icon
                  icon="CalendarIcon"
                  class="cursor-pointer"
                  data-toggle
                  size="18"
                />
              </b-input-group-append>
            </b-input-group>
            <small class="text-danger">{{ errors[0] }}</small>
          </b-form-group>
        </validation-provider>
      </div>
      <template #modal-footer="{ ok, cancel }">
        <b-button
          variant="outline-primary"
          @click="cancel()"
        >
          Cancel
        </b-button>
        <b-button
          variant="primary"
          @click="ok()"
        >
          Submit
        </b-button>
      </template>
    </b-modal>
    <b-modal
      id="submit-form-modal"
      ref="submit-form-modal"
      modal-class="status-update-modal sign-modal-adjust sign-form_modal-adjust"
      centered
      no-stacking
      no-close-on-backdrop
      header-class="front-modal"
      title="Submit this signed form?"
      @ok="submitSignedForm"
      @cancel="closeSubmitFormModal"
    >
      <div class="">
        <div>
          If the information in <strong>{{ requestForm.formID.name }} Form</strong> is accurate, submitting this form will generate a PDF copy to be sent out to customer.
        </div>
        <div class="mt-1 mb-50">
          Please confirm the PDF will be send via:
        </div>
        <b-tabs
          pills
          class="d-form-tab submit-d-form booking__content"
        >
          <b-tab
            :active="tabActive == 'email'"
            class="tab-content-wrapper"
            @click="tabActive = 'email'"
          >
            <template #title>
              <span class="">Email Only</span>
            </template>
            <b-form-group
              label="Email Address"
              label-for="customer-email"
              class="mb-0"
            >
              <validation-provider
                #default="{ errors }"
                name="Email Address"
                vid="customer-email"
                rules="required"
              >
                <b-form-input
                  id="customer-email"
                  v-model="customerEmailVal"
                  placeholder="jlam@gmail.com"
                  class="mb-0"
                  :state="(errors.length > 0) ? false : null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group
              label="Message to customer (optional)"
              label-for="customer-message"
              class="mt-75"
            >
              <validation-provider
                name="customer-message"
                vid="customer-message"
                rules=""
              >
                <quill-editor
                  v-model="customerMessageValEmail"
                  :options="editorOptionEmail"
                >
                  <div
                    id="customer-email-message"
                    slot="toolbar"
                  >
                    <!-- Add a bold button -->
                    <button class="ql-bold">
                      Bold
                    </button>
                    <button class="ql-italic">
                      Italic
                    </button>
                    <button class="ql-underline">
                      Underline
                    </button>
                    <select class="ql-size">
                      <option value="small" />
                      <!-- Note a missing, thus falsy value, is used to reset to default -->
                      <option selected />
                      <option value="large" />
                      <option value="huge" />
                    </select>

                    <select class="ql-align">
                      <option selected="selected" />
                      <option value="center" />
                      <option value="right" />
                      <option value="justify" />
                    </select>
                  </div>
                </quill-editor>
              </validation-provider>
            </b-form-group>
          </b-tab>
          <b-tab
            :active="tabActive == 'phone'"
            class="tab-content-wrapper"
            @click="tabActive = 'phone'"
          >
            <template #title>
              <span class="">SMS Only</span>
            </template>
            <b-form-group
              label="Mobile Number"
              label-for="customer-mobile"
              class="mb-0"
            >
              <validation-provider
                #default="{ errors }"
                name="Mobile Number"
                vid="customer-mobile"
                rules="required"
              >
                <b-form-input
                  id="customer-mobile"
                  v-model="customerMobileVal"
                  placeholder="(65) 98784248"
                  class="mb-0"
                  :state="(errors.length > 0) ? false : null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group
              label="Message to customer (optional)"
              label-for="customer-message"
              class="mt-75"
            >
              <validation-provider
                name="customer-message"
                vid="customer-message"
                rules=""
              >
                <b-form-textarea
                  v-model="customerMessageValPhone"
                  placeholder="Enter"
                  rows="3"
                  name="customerMessageValPhone"
                />
              </validation-provider>
            </b-form-group>
          </b-tab>
        </b-tabs>
      </div>
      <template #modal-footer="{ ok, cancel }">
        <b-button
          variant="flat-primary"
          @click="cancel()"
        >
          No, back to editing
        </b-button>
        <b-button
          variant="success"
          class="custom-icon"
          :disabled="(tabActive == 'email' && !customerEmailVal) || (tabActive == 'phone' && !customerMobileVal)"
          @click="ok()"
        >
          <feather-icon
            icon="ArrowRightIcon"
            size="16"
            class="mr-50"
          />
          <span class="align-middle">Yes, Proceed</span>
        </b-button>
      </template>
    </b-modal>
    <b-modal
      id="resend-form-modal"
      ref="resend-form-modal"
      modal-class="status-update-modal sign-modal-adjust sign-form_modal-adjust"
      centered
      no-stacking
      no-close-on-backdrop
      header-class="front-modal"
      title="Resend this form?"
      @ok="resendSignedForm"
      @cancel="closeResendFormModal"
    >
      <div class="">
        <div>
          If the information in <strong>{{ requestForm.formID.name }} Form</strong> is accurate, submitting this form will generate a PDF copy to be sent out to customer.
        </div>
        <div class="mt-1 mb-50">
          Please confirm the PDF will be send via:
        </div>
        <b-tabs
          pills
          class="d-form-tab submit-d-form booking__content"
        >
          <b-tab
            :active="tabActive == 'email'"
            class="tab-content-wrapper"
            @click="tabActive = 'email'"
          >
            <template #title>
              <span class="">Email Only</span>
            </template>
            <b-form-group
              label="Email Address"
              label-for="customer-email"
              class="mb-0"
            >
              <validation-provider
                #default="{ errors }"
                name="Email Address"
                vid="customer-email"
                rules="required"
              >
                <b-form-input
                  id="customer-email"
                  v-model="customerEmailVal"
                  placeholder="jlam@gmail.com"
                  class="mb-0"
                  :state="(errors.length > 0) ? false : null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group
              label="Message to customer (optional)"
              label-for="customer-message"
              class="mt-75"
            >
              <validation-provider
                name="customer-message"
                vid="customer-message"
                rules=""
              >
                <quill-editor
                  v-model="customerMessageValEmail"
                  :options="editorOptionEmail"
                >
                  <div
                    id="customer-email-message"
                    slot="toolbar"
                  >
                    <!-- Add a bold button -->
                    <button class="ql-bold">
                      Bold
                    </button>
                    <button class="ql-italic">
                      Italic
                    </button>
                    <button class="ql-underline">
                      Underline
                    </button>
                    <select class="ql-size">
                      <option value="small" />
                      <!-- Note a missing, thus falsy value, is used to reset to default -->
                      <option selected />
                      <option value="large" />
                      <option value="huge" />
                    </select>

                    <select class="ql-align">
                      <option selected="selected" />
                      <option value="center" />
                      <option value="right" />
                      <option value="justify" />
                    </select>
                  </div>
                </quill-editor>
              </validation-provider>
            </b-form-group>
          </b-tab>
          <b-tab
            :active="tabActive == 'phone'"
            class="tab-content-wrapper"
            @click="tabActive = 'phone'"
          >
            <template #title>
              <span class="">SMS Only</span>
            </template>
            <b-form-group
              label="Mobile Number"
              label-for="customer-mobile"
              class="mb-0"
            >
              <validation-provider
                #default="{ errors }"
                name="Mobile Number"
                vid="customer-mobile"
                rules="required"
              >
                <b-form-input
                  id="customer-mobile"
                  v-model="customerMobileVal"
                  placeholder="(65) 98784248"
                  class="mb-0"
                  :state="(errors.length > 0) ? false : null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group
              label="Message to customer (optional)"
              label-for="customer-message"
              class="mt-75"
            >
              <validation-provider
                name="customer-message"
                vid="customer-message"
                rules=""
              >
                <b-form-textarea
                  v-model="customerMessageValPhone"
                  placeholder="Enter"
                  rows="3"
                  name="customerMessageValPhone"
                />
              </validation-provider>
            </b-form-group>
          </b-tab>
        </b-tabs>
      </div>
      <template #modal-footer="{ ok, cancel }">
        <b-button
          variant="flat-primary"
          @click="cancel()"
        >
          No, back to Form
        </b-button>
        <b-button
          variant="success"
          class="custom-icon"
          :disabled="(tabActive == 'email' && !customerEmailVal) || (tabActive == 'phone' && !customerMobileVal)"
          @click="ok()"
        >
          <feather-icon
            icon="ArrowRightIcon"
            size="16"
            class="mr-50"
          />
          <span class="align-middle">Yes, Proceed</span>
        </b-button>
      </template>
    </b-modal>
    <b-modal
      id="edit-form-modal"
      ref="edit-form-modal"
      modal-class="status-update-modal sign-modal-adjust sign-form_modal-adjust"
      centered
      no-stacking
      no-close-on-backdrop
      header-class="front-modal"
      title="Submit this edited form?"
      @ok="submitSaveForm('edited', true)"
      @cancel="cancelEditing"
    >
      <div class="">
        <div>
          If the information in <strong>{{ requestForm.formID.name }} Form</strong> is accurate, submitting this form will generate a PDF copy to be sent out to customer.
        </div>
        <div class="mt-1 mb-50">
          Please confirm the PDF will be send via:
        </div>
        <b-tabs
          pills
          class="d-form-tab submit-d-form booking__content"
        >
          <b-tab
            :active="tabActive == 'email'"
            class="tab-content-wrapper"
            @click="tabActive = 'email'"
          >
            <template #title>
              <span class="">Email Only</span>
            </template>
            <b-form-group
              label="Email Address"
              label-for="customer-email"
              class="mb-0"
            >
              <validation-provider
                #default="{ errors }"
                name="Email Address"
                vid="customer-email"
                rules="required"
              >
                <b-form-input
                  id="customer-email"
                  v-model="customerEmailVal"
                  placeholder="jlam@gmail.com"
                  class="mb-0"
                  :state="(errors.length > 0) ? false : null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group
              label="Message to customer (optional)"
              label-for="customer-message"
              class="mt-75"
            >
              <validation-provider
                name="customer-message"
                vid="customer-message"
                rules=""
              >
                <quill-editor
                  v-model="customerMessageValEmail"
                  :options="editorOptionEmail"
                >
                  <div
                    id="customer-email-message"
                    slot="toolbar"
                  >
                    <!-- Add a bold button -->
                    <button class="ql-bold">
                      Bold
                    </button>
                    <button class="ql-italic">
                      Italic
                    </button>
                    <button class="ql-underline">
                      Underline
                    </button>
                    <select class="ql-size">
                      <option value="small" />
                      <!-- Note a missing, thus falsy value, is used to reset to default -->
                      <option selected />
                      <option value="large" />
                      <option value="huge" />
                    </select>

                    <select class="ql-align">
                      <option selected="selected" />
                      <option value="center" />
                      <option value="right" />
                      <option value="justify" />
                    </select>
                  </div>
                </quill-editor>
              </validation-provider>
            </b-form-group>
          </b-tab>
          <b-tab
            :active="tabActive == 'phone'"
            class="tab-content-wrapper"
            @click="tabActive = 'phone'"
          >
            <template #title>
              <span class="">SMS Only</span>
            </template>
            <b-form-group
              label="Mobile Number"
              label-for="customer-mobile"
              class="mb-0"
            >
              <validation-provider
                #default="{ errors }"
                name="Mobile Number"
                vid="customer-mobile"
                rules="required"
              >
                <b-form-input
                  id="customer-mobile"
                  v-model="customerMobileVal"
                  placeholder="(65) 98784248"
                  class="mb-0"
                  :state="(errors.length > 0) ? false : null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group
              label="Message to customer (optional)"
              label-for="customer-message"
              class="mt-75"
            >
              <validation-provider
                name="customer-message"
                vid="customer-message"
                rules=""
              >
                <b-form-textarea
                  v-model="customerMessageValPhone"
                  placeholder="Enter"
                  rows="3"
                  name="customerMessageValPhone"
                />
              </validation-provider>
            </b-form-group>
          </b-tab>
        </b-tabs>
      </div>
      <template #modal-footer="{ ok, cancel }">
        <b-button
          variant="flat-primary"
          @click="cancel()"
        >
          No, back to editing
        </b-button>
        <b-button
          variant="success"
          class="custom-icon"
          :disabled="(tabActive == 'email' && !customerEmailVal) || (tabActive == 'phone' && !customerMobileVal)"
          @click="ok()"
        >
          <feather-icon
            icon="ArrowRightIcon"
            size="16"
            class="mr-50"
          />
          <span class="align-middle">Yes, Proceed</span>
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BCard, BCardText, BRow, BCol, BFormGroup, BFormInput, BImg, BButton, BNav, BForm, BFormCheckbox,
  BInputGroup, BInputGroupAppend, BDropdown, BDropdownItem, BAlert, BTab, BTabs, BFormTextarea,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import flatPickr from 'vue-flatpickr-component'
import '@core/scss/vue/libs/vue-flatpicker.scss'
import vue2Dropzone from 'vue2-dropzone'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'
import { required } from '@validations'
import store from '@/store/index'
// eslint-disable-next-line
import 'quill/dist/quill.core.css'
// eslint-disable-next-line
import 'quill/dist/quill.snow.css'
// eslint-disable-next-line
import 'quill/dist/quill.bubble.css'
import { quillEditor } from 'vue-quill-editor'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'
import ToastificationContentCustom from '../../ToastificationContentCustom.vue'

export default {
  components: {
    BCard,
    BCardText,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BImg,
    BButton,
    BNav,
    BForm,
    BFormCheckbox,
    // BFormCheckboxGroup,
    BInputGroup,
    BInputGroupAppend,
    BDropdown,
    BDropdownItem,
    BAlert,
    BTab,
    BTabs,
    BFormTextarea,

    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContentCustom,
    flatPickr,
    quillEditor,
    vueDropzone: vue2Dropzone,
    ValidationProvider,
    ValidationObserver,
    AppCollapse,
    AppCollapseItem,
    AppTimeline,
    AppTimelineItem,
  },
  directives: {
    Ripple,
  },
  props: {
    digitalForm: {
      type: Object,
      required: true,
    },
    requestForm: {
      type: Object,
      required: true,
    },
    serviceRequest: {
      type: Object,
      required: true,
    },
    topSpacing: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      user: store.state.auth.userData,
      dropzoneOptions: {
        autoProcessQueue: false,
        url: 'https://httpbin.org/post',
        acceptedFiles: 'image/jpeg,image/png,application/pdf',
        maxFilesize: 20,
        addRemoveLinks: true,
        dictFileTooBig: 'File is too big ({{filesize}}mb). Max filesize: {{maxFilesize}}mb.',
      },
      uploadedFiles: [],
      need: [],
      needOptions: [
        { text: 'As-Need', value: 'as-need' },
        { text: 'Pre-Needed', value: 'pre-needed' },
      ],
      isInputValue: true,
      ownEngraving: '',
      secondOwnEngraving: '',
      firstLiving: '',
      secondLiving: '',
      thirdLiving: '',
      fourthLiving: '',
      fifthLiving: '',
      sixthLiving: '',
      seventhLiving: '',
      firstLivingSecond: '',
      secondLivingSecond: '',
      thirdLivingSecond: '',
      fourthLivingSecond: '',
      fifthLivingSecond: '',
      sixthLivingSecond: '',
      firstInputType: 'input',
      secondInputType: 'input',
      thirdInputType: 'input',
      fourthInputType: 'input',
      fifthInputType: 'input',
      sixthInputType: 'input',
      seventhInputType: 'input',
      firstInputTypeSecond: 'input',
      secondInputTypeSecond: 'input',
      thirdInputTypeSecond: 'input',
      fourthInputTypeSecond: 'input',
      fifthInputTypeSecond: 'input',
      sixthInputTypeSecond: 'input',
      firstInput: '',
      secondInput: '',
      thirdInput: '',
      fourthInput: '',
      fifthInput: '',
      sixthInput: '',
      seventhInput: '',
      firstInputSecond: '',
      secondInputSecond: '',
      thirdInputSecond: '',
      fourthInputSecond: '',
      fifthInputSecond: '',
      sixthInputSecond: '',
      isPurchaserSign: false,
      purchaserSign: '',
      purchaserSignDate: '',
      completionSign: false,
      completionSignSrc: '',
      completionSignDate: '',
      completionSignName: '',
      firstInputImageError: false,
      secondInputImageError: false,
      thirdInputImageError: false,
      fourthInputImageError: false,
      fifthInputImageError: false,
      sixthInputImageError: false,
      seventhInputImageError: false,
      firstInputImageSecondError: false,
      secondInputImageSecondError: false,
      thirdInputImageSecondError: false,
      fourthInputImageSecondError: false,
      fifthInputImageSecondError: false,
      sixthInputImageSecondError: false,
      purchaserDetailsCollapse: true,
      kinDetailsCollapse: false,
      officeDetailsCollapse: false,
      photoDetailsCollapse: false,
      othersDetailsCollapse: true,
      firstPartCollapse: true,
      secondPartCollapse: true,
      particularCollapse: false,
      editFormInOtherStatus: false,
      tabActive: 'email',
      flatPickrConfig: {
        wrap: true, enableTime: false, dateFormat: 'd/m/Y', maxDate: 'today', disableMobile: true,
      },
      flatPickrConfigInstallationDate: {
        wrap: true, enableTime: false, dateFormat: 'd/m/Y', disableMobile: true,
      },
      options: {
        penColor: '#000000',
        onBegin: () => { this.$refs.signaturePad.resizeCanvas() },
      },
      nameInputOptions: {
        penColor: '#000000',
        onBegin: () => { this.$refs.nameInputPad.resizeCanvas() },
      },
      secondNameInputOptions: {
        penColor: '#000000',
        onBegin: () => { this.$refs.secondNameInputPad.resizeCanvas() },
      },
      customerNameOptions: {
        penColor: '#000000',
        onBegin: () => { this.$refs.customerSignaturePad.resizeCanvas() },
      },
      kinNameOptions: {
        penColor: '#000000',
        onBegin: () => { this.$refs.kinSignaturePad.resizeCanvas() },
      },
      thirdInputOptions: {
        penColor: '#000000',
        onBegin: () => { this.$refs.thirdInputPad.resizeCanvas() },
      },
      fourthInputOptions: {
        penColor: '#000000',
        onBegin: () => { this.$refs.fourthInputPad.resizeCanvas() },
      },
      fifthInputOptions: {
        penColor: '#000000',
        onBegin: () => { this.$refs.fifthInputPad.resizeCanvas() },
      },
      sixthInputOptions: {
        penColor: '#000000',
        onBegin: () => { this.$refs.sixthInputPad.resizeCanvas() },
      },
      seventhInputOptions: {
        penColor: '#000000',
        onBegin: () => { this.$refs.seventhInputPad.resizeCanvas() },
      },
      firstInputSecondOptions: {
        penColor: '#000000',
        onBegin: () => { this.$refs.firstInputSecondPad.resizeCanvas() },
      },
      secondInputSecondOptions: {
        penColor: '#000000',
        onBegin: () => { this.$refs.secondInputSecondPad.resizeCanvas() },
      },
      thirdInputSecondOptions: {
        penColor: '#000000',
        onBegin: () => { this.$refs.thirdInputSecondPad.resizeCanvas() },
      },
      fourthInputSecondOptions: {
        penColor: '#000000',
        onBegin: () => { this.$refs.fourthInputSecondPad.resizeCanvas() },
      },
      fifthInputSecondOptions: {
        penColor: '#000000',
        onBegin: () => { this.$refs.fifthInputSecondPad.resizeCanvas() },
      },
      sixthInputSecondOptions: {
        penColor: '#000000',
        onBegin: () => { this.$refs.sixthInputSecondPad.resizeCanvas() },
      },
      currentFirstInputType: 'input',
      customerNameType: 'input',
      kinNameType: 'input',
      customerName: '',
      customerNameImage: '',
      customerNameImageError: false,
      firstInputImage: '',
      secondInputImage: '',
      thirdInputImage: '',
      fourthInputImage: '',
      fifthInputImage: '',
      sixthInputImage: '',
      seventhInputImage: '',
      firstInputImageSecond: '',
      secondInputImageSecond: '',
      thirdInputImageSecond: '',
      fourthInputImageSecond: '',
      fifthInputImageSecond: '',
      sixthInputImageSecond: '',
      customerNRIC: '',
      customerContact: '',
      currentKinNameType: 'input',
      kinName: '',
      kinNameImage: '',
      kinNameImageError: false,
      kinNRIC: '',
      kinContact: '',
      fileNo: '',
      pedestalNo: '',
      agentName: '',
      agentContact: '',
      remarks: '',
      personInCharge: '',
      installationDate: '',
      installationDateLocal: '',
      inChargePerson: '',
      inChargePersonLocal: '',
      submitDate: '',
      submitDateLocal: '',
      emailDate: '',
      emailDateLocal: '',
      officeRemarks: '',
      serviceType: '',
      customerEmailVal: '',
      customerMobileVal: '',
      customerMessageValEmail: '',
      customerMessageValPhone: '',
      serviceTypeValue: '',
      serviceTypeValueLocal: '',
      existingCompletionSignSrcObject: {},
      existingPurchaserSignObject: {},
      existingServiceTypeObject: {},
      existingInChargePersonObject: {},
      existingInstallationDateObject: {},
      existingSubmitDateObject: {},
      existingEmailDateObject: {},
      ownEngravingImage: [],
      secondOwnEngravingImage: [],
      selectedFileName: 'Ancestral Tablets Engraving Notice 牌位刻碑表格',
      currentFileIndex: 1,
      imageSrc: '',
      showPDF: true,
      showAttachment: false,
      editorOptionPhone: {
        modules: {
          toolbar: '#customer-sms-message',
        },
        placeholder: 'Type your message here',
      },
      editorOptionEmail: {
        modules: {
          toolbar: '#customer-email-message',
        },
        placeholder: 'Type your message here',
      },
      isSignature: false,
      defaultSignature: '',
      currentField: '',
      currentSecondField: '',
      showFileUploadError: false,
      photoClaimedBy: '',
      photoClaimedDate: '',
      photoReceivedBy: '',
      photoReceivedDate: '',
      required,
    }
  },
  computed: {
    requiredRuleComputation() {
      if (this.ownEngraving || this.secondOwnEngraving) {
        return ''
      }
      if (!this.firstInput && !this.secondInput && !this.thirdInput && !this.fourthInput && !this.fifthInput && !this.sixthInput && !this.seventhInput
      && !this.firstInputImage && !this.secondInputImage && !this.thirdInputImage && !this.fourthInputImage && !this.fifthInputImage && !this.sixthInputImage && !this.seventhInputImage
      && !this.firstInputSecond && !this.secondInputSecond && !this.thirdInputSecond && !this.fourthInputSecond && !this.fifthInputSecond && !this.sixthInputSecond
      && !this.firstInputImageSecond && !this.secondInputImageSecond && !this.thirdInputImageSecond && !this.fourthInputImageSecond && !this.fifthInputImageSecond && !this.sixthInputImageSecond) {
        return 'required'
      }

      return ''
    },
    requiredRuleComputationSecond() {
      if (this.ownEngraving || this.secondOwnEngraving) {
        return ''
      }
      if (!this.firstInputSecond && !this.secondInputSecond && !this.thirdInputSecond && !this.fourthInputSecond && !this.fifthInputSecond && !this.sixthInputSecond) {
        return 'required'
      }

      return ''
    },
  },
  created() {
    this.makeFormValues()
    this.customerMobileVal = this.requestForm.customerContact
    this.customerEmailVal = this.requestForm.customerEmail
  },
  methods: {
    resizeText(field) {
      if (process.env.VUE_APP_FONT_AUTO_ADJUST === 'true') {
        const isOverflown = ({ clientHeight, scrollHeight }) => scrollHeight > clientHeight
        let i = 21
        let overflow = true
        const minSize = 2
        const element = this.$refs[field]
        if (element) {
          const parent = element.parentElement

          while (overflow && i > minSize) {
            element.style.fontSize = `${i}px`
            overflow = isOverflown(parent)
            // eslint-disable-next-line no-plusplus
            if (overflow) i--
          }

          element.style.fontSize = `${i}px`
        }
      }
    },
    getFontSize(field) {
      if (process.env.VUE_APP_FONT_AUTO_ADJUST === 'true') {
        const isOverflown = ({ clientHeight, scrollHeight }) => scrollHeight > clientHeight
        let i = 21
        let overflow = true
        const minSize = 2
        const element = this.$refs[field]
        if (element) {
          const parent = element.parentElement
          while (overflow && i > minSize) {
            element.style.fontSize = `${i}px`
            overflow = isOverflown(parent)
            // eslint-disable-next-line no-plusplus
            if (overflow) i--
          }
        }

        return `${i}px`
      }

      return '21px'
    },
    hasChineseText(text) {
      const initialLength = text.length
      const chineseLength = text.split('')
        .filter(char => (/\p{Script=Han}/u.test(char) || /\s/u.test(char)))
        .join('')
        .length
      if (initialLength === chineseLength) {
        return true
      }

      return false
    },
    changeFileIndex(key) {
      this.currentFileIndex = key
      if (key === 1) {
        this.selectedFileName = 'Ancestral Tablets Engraving Notice 牌位刻碑表格'
        // eslint-disable-next-line func-names
        // pdfViewerFrame.onload = () => {
        //   pdfViewerFrame.contentWindow.PDFViewerApplication.open(this.pdfDataJS)
        // }
        // this.showFirst = false
        // const pdfViewerFrame = document.getElementById('docpdf2')
        // pdfViewerFrame.setAttribute('src', `/lib/pdfjs/web/viewer.html?file=${this.pdfDataJS}`)
        this.showAttachment = false
      } else {
        this.selectedFileName = this.ownEngravingImage[key - 2].name
        if (this.ownEngravingImage[key - 2].type.includes('pdf')) {
          // eslint-disable-next-line func-names
          // pdfViewerFrame.onload = () => {
          //   pdfViewerFrame.contentWindow.PDFViewerApplication.open(this.ownEngravingImage[key - 2].data)
          // }
          const pdfViewerFrame2 = document.getElementById('attachmentpdf')
          pdfViewerFrame2.setAttribute('src', `/lib/pdfjs/web/viewer.html?file=${this.ownEngravingImage[key - 2].data}`)
          this.showPDF = true
        } else {
          this.imageSrc = this.ownEngravingImage[key - 2].data
          this.showPDF = false
        }
        this.showAttachment = true
      }
    },
    closeCompletionSignModal() {
      this.$root.$emit('bv::hide::modal', 'completion-sign-modal', '')
    },
    clearCompletionSign() {
      this.$refs.signaturePad.clearSignature()
    },
    saveCompletionSign() {
      const { data } = this.$refs.signaturePad.saveSignature()
      if (data) {
        this.$root.$emit('bv::hide::modal', 'completion-sign-modal', '')
        const completionSignObj = {
          key: 'completionSignSrc',
          value: data,
        }
        if (this.editFormInOtherStatus) {
          completionSignObj.date = new Date()
          completionSignObj.name = this.user.name
          completionSignObj.admin = this.user._id
          this.existingCompletionSignSrcObject = completionSignObj
          this.completionSignSrc = data
          this.completionSign = true
        } else {
          const formData = new FormData()
          let allSigned = false
          if (this.isPurchaserSign) {
            allSigned = true
          }

          formData.append('allSigned', allSigned)
          formData.append('signature', JSON.stringify(completionSignObj))
          this.$http.post(`operation/service-requests/service-form/${this.requestForm._id}/store/sign`, formData, {
            headers: { 'Content-Type': 'multipart/form-data' },
          })
            .then(async response => {
              if (response.data.data) {
                await this.$emit('update:requestForm', response.data.data)
                this.makeFormValues()
                if (allSigned) {
                  this.$root.$emit('bv::show::modal', 'submit-form-modal', '')
                } else {
                  this.$swal({
                    title: 'Signature Saved',
                    html: response.data.message || '',
                    // eslint-disable-next-line global-require
                    imageUrl: require('@/assets/images/icons/save.png'),
                    imageWidth: 80,
                    imageHeight: 80,
                    imageAlt: 'Custom Icon',
                    showCancelButton: false,
                    confirmButtonText: 'Okay',
                    customClass: {
                      confirmButton: 'btn btn-primary',
                    },
                    buttonsStyling: false,
                  })
                }
              } else {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: response.data.message || '',
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                  },
                })
              }
            })
            .catch(error => {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: error.data.message ?? process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                },
              })
            })
        }
      }
    },
    async makeFormValues() {
      this.fileNo = this.serviceRequest.fileNo
      this.pedestalNo = this.serviceRequest.serviceForm.deceasedPedestal
      const customerNameObject = this.serviceRequest.formValues.find(o => o.key === 'customerName')
      if (customerNameObject) {
        if (customerNameObject.value.startsWith('data:image/')) {
          this.customerNameImage = customerNameObject.value
          this.customerNameType = 'canvas'
        } else {
          this.customerNameType = 'input'
          this.customerName = customerNameObject.value
          // this.customerName = this.serviceRequest.serviceForm.customerName
        }
      }
      // const customerNRICObject = this.requestForm.values.find(o => o.key === 'customerNRIC')
      // if (customerNRICObject) {
      //   this.customerNRIC = customerNRICObject.value
      // }
      this.customerNRIC = this.serviceRequest.formValues.find(o => o.key === 'customerNRIC')?.value
      // const customerContactObject = this.requestForm.values.find(o => o.key === 'customerContact')
      // if (customerContactObject) {
      //   this.customerContact = customerContactObject.value
      // }
      this.customerContact = this.serviceRequest.formValues.find(o => o.key === 'customerContact')?.value
      const kinNameObject = this.serviceRequest.formValues.find(o => o.key === 'kinName')
      if (kinNameObject) {
        if (kinNameObject.value.startsWith('data:image/')) {
          this.kinNameImage = kinNameObject.value
          this.kinNameType = 'canvas'
        } else {
          this.kinNameType = 'input'
          this.kinName = kinNameObject.value
          // this.kinName = this.serviceRequest.formValues.find(o => o.key === 'kinName')?.value
        }
      }
      // const kinNRICObject = this.requestForm.values.find(o => o.key === 'kinNRIC')
      // if (kinNRICObject) {
      //   this.kinNRIC = kinNRICObject.value
      // }
      this.kinNRIC = this.serviceRequest.formValues.find(o => o.key === 'kinNRIC')?.value
      // const kinContactObject = this.requestForm.values.find(o => o.key === 'kinContact')
      // if (kinContactObject) {
      //   this.kinContact = kinContactObject.value
      // }
      this.kinContact = this.serviceRequest.formValues.find(o => o.key === 'kinContact')?.value
      // const fileNoObject = this.requestForm.values.find(o => o.key === 'fileNo')
      // if (fileNoObject) {
      //   this.fileNo = fileNoObject.value
      // }
      this.fileNo = this.serviceRequest.fileNo
      // const pedestalNoObject = this.requestForm.values.find(o => o.key === 'pedestalNo')
      // if (pedestalNoObject) {
      //   this.pedestalNo = pedestalNoObject.value
      // }
      this.pedestalNo = this.serviceRequest.serviceForm.deceasedPedestal
      // const agentNameObject = this.requestForm.values.find(o => o.key === 'agentName')
      // if (agentNameObject) {
      //   this.agentName = agentNameObject.value
      // }
      this.agentName = this.serviceRequest.serviceForm.agentName
      // const agentContactObject = this.requestForm.values.find(o => o.key === 'agentContact')
      // if (agentContactObject) {
      //   this.agentContact = agentContactObject.value
      // }
      this.agentContact = this.serviceRequest.serviceForm.agentContact
      if (this.requestForm.values.length) {
        const ownEngravingObject = this.requestForm.values.find(o => o.key === 'ownEngraving')
        const secondOwnEngravingObject = this.requestForm.values.find(o => o.key === 'secondOwnEngraving')
        if (ownEngravingObject || secondOwnEngravingObject) {
          let hasValue = false
          if (ownEngravingObject && ownEngravingObject.value) {
            this.ownEngraving = ownEngravingObject.value
            hasValue = true
          }
          if (secondOwnEngravingObject && secondOwnEngravingObject.value) {
            this.secondOwnEngraving = secondOwnEngravingObject.value
            hasValue = true
          }
          if (!hasValue) {
            const firstLivingObject = this.requestForm.values.find(o => o.key === 'firstLiving')
            if (firstLivingObject) {
              this.firstLiving = firstLivingObject.value
            }
            const secondLivingObject = this.requestForm.values.find(o => o.key === 'secondLiving')
            if (secondLivingObject) {
              this.secondLiving = secondLivingObject.value
            }
            const thirdLivingObject = this.requestForm.values.find(o => o.key === 'thirdLiving')
            if (thirdLivingObject) {
              this.thirdLiving = thirdLivingObject.value
            }
            const fourthLivingObject = this.requestForm.values.find(o => o.key === 'fourthLiving')
            if (fourthLivingObject) {
              this.fourthLiving = fourthLivingObject.value
            }
            const fifthLivingObject = this.requestForm.values.find(o => o.key === 'fifthLiving')
            if (fifthLivingObject) {
              this.fifthLiving = fifthLivingObject.value
            }
            const sixthLivingObject = this.requestForm.values.find(o => o.key === 'sixthLiving')
            if (sixthLivingObject) {
              this.sixthLiving = sixthLivingObject.value
            }
            const seventhLivingObject = this.requestForm.values.find(o => o.key === 'seventhLiving')
            if (seventhLivingObject) {
              this.seventhLiving = seventhLivingObject.value
            }

            const firstInputObject = this.requestForm.values.find(o => o.key === 'firstInput')
            if (firstInputObject) {
              if (firstInputObject.value.startsWith('data:image/')) {
                this.firstInputImage = firstInputObject.value
                this.firstInputType = 'canvas'
              } else {
                this.firstInputType = 'input'
                this.firstInput = firstInputObject.value
              }
            }

            const secondInputObject = this.requestForm.values.find(o => o.key === 'secondInput')
            if (secondInputObject) {
              if (secondInputObject.value.startsWith('data:image/')) {
                this.secondInputImage = secondInputObject.value
                this.secondInputType = 'canvas'
              } else {
                this.secondInputType = 'input'
                this.secondInput = secondInputObject.value
              }
            }
            const thirdInputObject = this.requestForm.values.find(o => o.key === 'thirdInput')
            if (thirdInputObject) {
              if (thirdInputObject.value.startsWith('data:image/')) {
                this.thirdInputImage = thirdInputObject.value
                this.thirdInputType = 'canvas'
              } else {
                this.thirdInputType = 'input'
                this.thirdInput = thirdInputObject.value
              }
            }

            const fourthInputObject = this.requestForm.values.find(o => o.key === 'fourthInput')
            if (fourthInputObject) {
              if (fourthInputObject.value.startsWith('data:image/')) {
                this.fourthInputImage = fourthInputObject.value
                this.fourthInputType = 'canvas'
              } else {
                this.fourthInputType = 'input'
                this.fourthInput = fourthInputObject.value
              }
            }
            const fifthInputObject = this.requestForm.values.find(o => o.key === 'fifthInput')
            if (fifthInputObject) {
              if (fifthInputObject.value.startsWith('data:image/')) {
                this.fifthInputImage = fifthInputObject.value
                this.fifthInputType = 'canvas'
              } else {
                this.fifthInputType = 'input'
                this.fifthInput = fifthInputObject.value
              }
            }

            const sixthInputObject = this.requestForm.values.find(o => o.key === 'sixthInput')
            if (sixthInputObject) {
              if (sixthInputObject.value.startsWith('data:image/')) {
                this.sixthInputImage = sixthInputObject.value
                this.sixthInputType = 'canvas'
              } else {
                this.sixthInputType = 'input'
                this.sixthInput = sixthInputObject.value
              }
            }

            const seventhInputObject = this.requestForm.values.find(o => o.key === 'seventhInput')
            if (seventhInputObject) {
              if (seventhInputObject.value.startsWith('data:image/')) {
                this.seventhInputImage = seventhInputObject.value
                this.seventhInputType = 'canvas'
              } else {
                this.seventhInputType = 'input'
                this.seventhInput = seventhInputObject.value
              }
            }

            const firstLivingSecondObject = this.requestForm.values.find(o => o.key === 'firstLivingSecond')
            if (firstLivingSecondObject) {
              this.firstLivingSecond = firstLivingSecondObject.value
            }
            const secondLivingSecondObject = this.requestForm.values.find(o => o.key === 'secondLivingSecond')
            if (secondLivingSecondObject) {
              this.secondLivingSecond = secondLivingSecondObject.value
            }
            const thirdLivingSecondObject = this.requestForm.values.find(o => o.key === 'thirdLivingSecond')
            if (thirdLivingSecondObject) {
              this.thirdLivingSecond = thirdLivingSecondObject.value
            }
            const fourthLivingSecondObject = this.requestForm.values.find(o => o.key === 'fourthLivingSecond')
            if (fourthLivingSecondObject) {
              this.fourthLivingSecond = fourthLivingSecondObject.value
            }
            const fifthLivingSecondObject = this.requestForm.values.find(o => o.key === 'fifthLivingSecond')
            if (fifthLivingSecondObject) {
              this.fifthLivingSecond = fifthLivingSecondObject.value
            }
            const sixthLivingSecondObject = this.requestForm.values.find(o => o.key === 'sixthLivingSecond')
            if (sixthLivingSecondObject) {
              this.sixthLivingSecond = sixthLivingSecondObject.value
            }

            const firstInputSecondObject = this.requestForm.values.find(o => o.key === 'firstInputSecond')
            if (firstInputSecondObject) {
              if (firstInputSecondObject.value.startsWith('data:image/')) {
                this.firstInputImageSecond = firstInputSecondObject.value
                this.firstInputTypeSecond = 'canvas'
              } else {
                this.firstInputTypeSecond = 'input'
                this.firstInputSecond = firstInputSecondObject.value
              }
            }

            const secondInputSecondObject = this.requestForm.values.find(o => o.key === 'secondInputSecond')
            if (secondInputSecondObject) {
              if (secondInputSecondObject.value.startsWith('data:image/')) {
                this.secondInputImageSecond = secondInputSecondObject.value
                this.secondInputTypeSecond = 'canvas'
              } else {
                this.secondInputTypeSecond = 'input'
                this.secondInputSecond = secondInputSecondObject.value
              }
            }
            const thirdInputSecondObject = this.requestForm.values.find(o => o.key === 'thirdInputSecond')
            if (thirdInputSecondObject) {
              if (thirdInputSecondObject.value.startsWith('data:image/')) {
                this.thirdInputImageSecond = thirdInputSecondObject.value
                this.thirdInputTypeSecond = 'canvas'
              } else {
                this.thirdInputTypeSecond = 'input'
                this.thirdInputSecond = thirdInputSecondObject.value
              }
            }

            const fourthInputSecondObject = this.requestForm.values.find(o => o.key === 'fourthInputSecond')
            if (fourthInputSecondObject) {
              if (fourthInputSecondObject.value.startsWith('data:image/')) {
                this.fourthInputImageSecond = fourthInputSecondObject.value
                this.fourthInputTypeSecond = 'canvas'
              } else {
                this.fourthInputTypeSecond = 'input'
                this.fourthInputSecond = fourthInputSecondObject.value
              }
            }
            const fifthInputSecondObject = this.requestForm.values.find(o => o.key === 'fifthInputSecond')
            if (fifthInputSecondObject) {
              if (fifthInputSecondObject.value.startsWith('data:image/')) {
                this.fifthInputImageSecond = fifthInputSecondObject.value
                this.fifthInputTypeSecond = 'canvas'
              } else {
                this.fifthInputTypeSecond = 'input'
                this.fifthInputSecond = fifthInputSecondObject.value
              }
            }

            const sixthInputSecondObject = this.requestForm.values.find(o => o.key === 'sixthInputSecond')
            if (sixthInputSecondObject) {
              if (sixthInputSecondObject.value.startsWith('data:image/')) {
                this.sixthInputImageSecond = sixthInputSecondObject.value
                this.sixthInputTypeSecond = 'canvas'
              } else {
                this.sixthInputTypeSecond = 'input'
                this.sixthInputSecond = sixthInputSecondObject.value
              }
            }
          }
        }

        await this.$nextTick()
        const ownEngravingImageObject = this.requestForm.values.find(o => o.key === 'ownEngravingImage')
        if (ownEngravingImageObject) {
          this.uploadedFiles = []
          this.ownEngravingImage = ownEngravingImageObject.value
          ownEngravingImageObject.value.forEach(arrayItem => {
            const file = {
              size: (arrayItem.size * 1024 * 1024), name: arrayItem.name, type: arrayItem.type, description: arrayItem.description,
            }
            if (this.$refs.myVueDropzone) {
              this.$refs.myVueDropzone.manuallyAddFile(file, arrayItem.data)
            }
            this.uploadedFiles.push(arrayItem)
          })
        }

        const remarksObject = this.requestForm.values.find(o => o.key === 'remarks')
        if (remarksObject) {
          this.remarks = remarksObject.value
        }
        const inChargePersonObject = this.requestForm.values.find(o => o.key === 'inChargePerson')
        if (inChargePersonObject) {
          this.existingInChargePersonObject = inChargePersonObject
          this.inChargePerson = inChargePersonObject.value
          this.inChargePersonLocal = inChargePersonObject.value
        }
        const serviceTypeObject = this.requestForm.values.find(o => o.key === 'serviceType')
        if (serviceTypeObject) {
          this.existingServiceTypeObject = serviceTypeObject
          this.serviceType = serviceTypeObject.value
          this.serviceTypeValue = serviceTypeObject.value
          this.serviceTypeValueLocal = serviceTypeObject.value
        }
        const installationDateObject = this.requestForm.values.find(o => o.key === 'installationDate')
        if (installationDateObject) {
          this.installationDate = installationDateObject.value
          this.installationDateLocal = installationDateObject.value
          this.existingInstallationDateObject = installationDateObject
        }
        const submitDateObject = this.requestForm.values.find(o => o.key === 'submitDate')
        if (submitDateObject) {
          this.submitDate = submitDateObject.value
          this.submitDateLocal = submitDateObject.value
          this.existingSubmitDateObject = submitDateObject
        }
        const emailDateObject = this.requestForm.values.find(o => o.key === 'emailDate')
        if (emailDateObject) {
          this.emailDate = emailDateObject.value
          this.emailDateLocal = emailDateObject.value
          this.existingEmailDateObject = emailDateObject
        }
        const purchaserSignObject = this.requestForm.values.find(o => o.key === 'purchaserSign')
        if (purchaserSignObject) {
          this.existingPurchaserSignObject = purchaserSignObject
          this.purchaserSign = purchaserSignObject.value
          this.isPurchaserSign = true
          this.purchaserSignDate = purchaserSignObject.date
        }
        const completionSignSrcObject = this.requestForm.values.find(o => o.key === 'completionSignSrc')
        if (completionSignSrcObject) {
          this.existingCompletionSignSrcObject = completionSignSrcObject
          this.completionSignSrc = completionSignSrcObject.value
          this.completionSign = true
          this.completionSignDate = completionSignSrcObject.date
          this.completionSignName = completionSignSrcObject.name
        }
        const photoClaimedByObject = this.requestForm.values.find(o => o.key === 'photoClaimedBy')
        if (photoClaimedByObject) {
          this.photoClaimedBy = photoClaimedByObject.value
        }
        const photoClaimedDateObject = this.requestForm.values.find(o => o.key === 'photoClaimedDate')
        if (photoClaimedDateObject) {
          this.photoClaimedDate = photoClaimedDateObject.value
        }
        const photoReceivedByObject = this.requestForm.values.find(o => o.key === 'photoReceivedBy')
        if (photoReceivedByObject) {
          this.photoReceivedBy = photoReceivedByObject.value
        }
        const photoReceivedDateObject = this.requestForm.values.find(o => o.key === 'photoReceivedDate')
        if (photoReceivedDateObject) {
          this.photoReceivedDate = photoReceivedDateObject.value
        }
      }
    },
    startSignProcess() {
      const finalStatus = 'Signing'
      const formData = new FormData()
      formData.append('status', finalStatus)
      this.$http.post(`operation/service-requests/service-form/${this.requestForm._id}/update/status`, formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      })
        .then(async response => {
          if (response.data.data) {
            await this.$emit('update:requestForm', response.data.data)
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: response.data.message || '',
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            })
          }
        })
        .catch(error => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: error.data.message ?? process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
    async editForm() {
      if (this.requestForm.status === 'Not required') {
        const finalStatus = 'To prepare'
        const formData = new FormData()
        formData.append('status', finalStatus)
        this.$http.post(`operation/service-requests/service-form/${this.requestForm._id}/update/status`, formData, {
          headers: { 'Content-Type': 'multipart/form-data' },
        })
          .then(async response => {
            if (response.data.data) {
              await this.$emit('update:requestForm', response.data.data)
            } else {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: response.data.message || '',
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                },
              })
            }
          })
          .catch(error => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: error.data.message ?? process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            })
          })
      } else {
        this.editFormInOtherStatus = true
        this.uploadedFiles = []
        await this.$nextTick()
        const ownEngravingImageObject = this.requestForm.values.find(o => o.key === 'ownEngravingImage')
        if (ownEngravingImageObject) {
          this.ownEngravingImage = ownEngravingImageObject.value
          ownEngravingImageObject.value.forEach(arrayItem => {
            const file = {
              size: (arrayItem.size * 1024 * 1024), name: arrayItem.name, type: arrayItem.type, description: arrayItem.description,
            }
            if (this.$refs.myVueDropzone) {
              this.$refs.myVueDropzone.manuallyAddFile(file, arrayItem.data)
            }
            this.uploadedFiles.push(arrayItem)
          })
        }
      }
    },
    makeFormNotRequired() {
      this.$swal({
        title: 'Are you sure you want to ‘Mark as Not Required’ for this form?',
        html: 'The form status will change to ‘Not Required’, all information you have keyed in will be cleared.',
        // eslint-disable-next-line global-require
        imageUrl: require('@/assets/images/icons/warning.png'),
        reverseButtons: true,
        showCancelButton: true,
        cancelButtonText: 'No, go back',
        confirmButtonText: "Yes, I'm sure",
        customClass: {
          confirmButton: 'btn btn-primary ml-1',
          cancelButton: 'btn btn-outline-primary',
        },
        buttonsStyling: false,
      })
        .then(result => {
          if (result.value) {
            const formData = new FormData()
            formData.append('status', 'Not required')
            this.$http.post(`operation/service-requests/service-form/${this.requestForm._id}/update/status`, formData, {
              headers: { 'Content-Type': 'multipart/form-data' },
            })
              .then(async response => {
                if (response.data.data) {
                  await this.$emit('update:requestForm', response.data.data)
                  this.makeFormValues()
                  this.$swal({
                    title: 'Form Has Been Marked as Not Required',
                    // html: response.data.message || '',
                    // eslint-disable-next-line global-require
                    imageUrl: require('@/assets/images/icons/save.png'),
                    imageWidth: 80,
                    imageHeight: 80,
                    imageAlt: 'Custom Icon',
                    showCancelButton: false,
                    confirmButtonText: 'Okay',
                    customClass: {
                      confirmButton: 'btn btn-primary',
                    },
                    buttonsStyling: false,
                  })
                } else {
                  this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: response.data.message || '',
                      icon: 'AlertTriangleIcon',
                      variant: 'danger',
                    },
                  })
                }
              })
              .catch(error => {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: error.data.message ?? process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                  },
                })
              })
          }
        })
    },
    closePurchaserSignModal() {
      this.$root.$emit('bv::hide::modal', 'purchaser-sign-modal', '')
    },
    clearPurchaserSign() {
      this.$refs.signaturePad.clearSignature()
    },
    savePurchaserSign() {
      const { data } = this.$refs.signaturePad.saveSignature()

      if (data) {
        this.$root.$emit('bv::hide::modal', 'purchaser-sign-modal', '')
        const purchaserSignObj = {
          key: 'purchaserSign',
          value: data,
        }
        if (this.editFormInOtherStatus) {
          this.purchaserSign = data
          this.isPurchaserSign = true
          purchaserSignObj.date = new Date()
          this.existingPurchaserSignObject = purchaserSignObj
        } else {
          const formData = new FormData()
          let allSigned = false
          if (this.completionSign) {
            allSigned = true
          }

          formData.append('allSigned', allSigned)
          formData.append('signature', JSON.stringify(purchaserSignObj))
          this.$http.post(`operation/service-requests/service-form/${this.requestForm._id}/store/sign`, formData, {
            headers: { 'Content-Type': 'multipart/form-data' },
          })
            .then(async response => {
              if (response.data.data) {
                await this.$emit('update:requestForm', response.data.data)
                this.makeFormValues()
                if (allSigned) {
                  this.$root.$emit('bv::show::modal', 'submit-form-modal', '')
                } else {
                  this.$swal({
                    title: 'Signature Saved',
                    html: response.data.message || '',
                    // eslint-disable-next-line global-require
                    imageUrl: require('@/assets/images/icons/save.png'),
                    imageWidth: 80,
                    imageHeight: 80,
                    imageAlt: 'Custom Icon',
                    showCancelButton: false,
                    confirmButtonText: 'Okay',
                    customClass: {
                      confirmButton: 'btn btn-primary',
                    },
                    buttonsStyling: false,
                  })
                }
              } else {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: response.data.message || '',
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                  },
                })
              }
            })
            .catch(error => {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: error.data.message ?? process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                },
              })
            })
        }
      }
    },
    processFile(file) {
      const acceptedTypes = 'image/jpeg,image/png,application/pdf'
      file.forEach(element => {
        if (acceptedTypes.includes(element.type)) {
          if (element.size > 20 * 1024 * 1024) {
            // this.$toast({
            //   component: ToastificationContent,
            //   props: {
            //     title: 'Image is too large',
            //     icon: 'AlertTriangleIcon',
            //     variant: 'danger',
            //   },
            // })
          } else {
            const exist = this.uploadedFiles.find(o => o.name === element.name && o.size === (element.size / (1024 * 1024)).toFixed(2))
            if (exist) {
              // this.$refs.myVueDropzone.removeFile(element)
              setTimeout(() => {
                if (element.previewElement) {
                  element.previewElement.remove()
                }
              }, 500)
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Duplicate File',
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                },
              })
            } else {
              const reader = new FileReader()
              reader.readAsDataURL(element)

              reader.onload = event => {
                // const found = this.uploadedFiles.find(image => image === event.target.result)
                // if (found) {
                //   this.$refs.myVueDropzone.removeFile(element)
                //   this.$toast({
                //     component: ToastificationContent,
                //     props: {
                //       title: 'This File Already Exists',
                //       icon: 'AlertTriangleIcon',
                //       variant: 'danger',
                //     },
                //   })
                // } else {
                //   this.newFile = true
                //   const fileObj = {}
                //   fileObj.name = element.name
                //   fileObj.description = ''
                //   fileObj.data = event.target.result
                //   fileObj.size = (element.size / (1024 * 1024)).toFixed(2)
                //   fileObj.type = element.type
                //   this.uploadedFiles.push(fileObj)
                // }
                const fileObj = {}
                fileObj.name = element.name
                fileObj.description = ''
                fileObj.data = event.target.result
                fileObj.size = (element.size / (1024 * 1024)).toFixed(2)
                fileObj.type = element.type
                fileObj.lastModified = element.lastModified
                this.uploadedFiles.push(fileObj)
              }
            }
          }
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Unsupported file type',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        }
      })
    },
    fileRemoved(file) {
      if (file.dataURL) {
        this.uploadedFiles = this.uploadedFiles.filter(item => item.data !== file.dataURL)
      } else {
        this.uploadedFiles = this.uploadedFiles.filter(item => item.name !== file.name)
      }

      const previewLength = document.getElementsByClassName('dz-preview').length
      if (!previewLength) {
        this.$refs.myVueDropzone.removeAllFiles()
      }
    },
    handleError(file, message) {
      this.$refs.myVueDropzone.removeFile(file)
      if (message !== 'Upload canceled.') {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: message,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      }
    },
    closeOfficeSectionModal() {
      this.$root.$emit('bv::hide::modal', 'office-use-section-modal', '')
    },
    saveOfficeSectionData() {
      const inChargePersonObject = {
        key: 'inChargePerson',
        value: this.inChargePersonLocal,
      }
      const installationDateObject = {
        key: 'installationDate',
        value: this.installationDateLocal,
      }
      const submitDateObject = {
        key: 'submitDate',
        value: this.submitDateLocal,
      }
      const emailDateObject = {
        key: 'emailDate',
        value: this.emailDateLocal,
      }
      const serviceTypeObject = {
        key: 'serviceType',
        value: this.serviceTypeValueLocal,
      }
      if (this.editFormInOtherStatus || this.requestForm.status === 'To prepare') {
        this.existingInChargePersonObject = inChargePersonObject
        this.existingInstallationDateObject = installationDateObject
        this.existingSubmitDateObject = submitDateObject
        this.existingServiceTypeObject = emailDateObject
        this.existingInstallationDateObject = serviceTypeObject
        this.inChargePerson = this.inChargePersonLocal
        this.installationDate = this.installationDateLocal
        this.submitDate = this.submitDateLocal
        this.emailDate = this.emailDateLocal
        this.serviceType = this.serviceTypeValueLocal
      } else {
        const formData = new FormData()

        formData.append('inChargePersonObject', JSON.stringify(inChargePersonObject))
        formData.append('installationDateObject', JSON.stringify(installationDateObject))
        formData.append('submitDateObject', JSON.stringify(submitDateObject))
        formData.append('emailDateObject', JSON.stringify(emailDateObject))
        formData.append('serviceTypeObject', JSON.stringify(serviceTypeObject))
        this.$http.post(`operation/service-requests/service-form/${this.requestForm._id}/store/office-section/ancestral`, formData, {
          headers: { 'Content-Type': 'multipart/form-data' },
        })
          .then(async response => {
            if (response.data.data) {
              await this.$emit('update:requestForm', response.data.data)
              this.$root.$emit('bv::hide::modal', 'office-use-section-modal', '')
              this.makeFormValues()
              this.$swal({
                title: 'Office Section Data Saved',
                html: response.data.message || '',
                // eslint-disable-next-line global-require
                imageUrl: require('@/assets/images/icons/save.png'),
                imageWidth: 80,
                imageHeight: 80,
                imageAlt: 'Custom Icon',
                showCancelButton: false,
                confirmButtonText: 'Okay',
                customClass: {
                  confirmButton: 'btn btn-primary',
                },
                buttonsStyling: false,
              })
            } else {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: response.data.message || '',
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                },
              })
            }
          })
          .catch(error => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: error.data.message ?? process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            })
          })
      }
    },
    openSignatureModal(field) {
      this.currentField = field
      this.$root.$emit('bv::show::modal', 'name-field-modal', '')
    },
    openSecondSignatureModal(field) {
      this.currentSecondField = field
      this.$root.$emit('bv::show::modal', 'second-name-field-modal', '')
    },
    closeNameFieldModal() {
      this.$root.$emit('bv::hide::modal', 'name-field-modal', '')
      const prop = `${this.currentField}InputImage`
      const field = `${this.currentField}InputType`
      if (!this[prop]) {
        this[field] = 'input'
      }
    },
    closeSecondNameFieldModal() {
      this.$root.$emit('bv::hide::modal', 'second-name-field-modal', '')
      const prop = `${this.currentSecondField}InputImageSecond`
      const field = `${this.currentSecondField}InputTypeSecond`
      if (!this[prop]) {
        this[field] = 'input'
      }
    },
    clearNameInputPad() {
      const prop = `${this.currentField}InputImage`
      this[prop] = ''
      this.$refs.nameInputPad.clearSignature()
    },
    clearSecondNameInputPad() {
      const prop = `${this.currentSecondField}InputImageSecond`
      this[prop] = ''
      this.$refs.secondNameInputPad.clearSignature()
    },
    saveNameInput() {
      const { data } = this.$refs.nameInputPad.saveSignature()
      if (data) {
        const prop = `${this.currentField}InputImage`
        const field = `${this.currentField}InputType`
        this[prop] = data
        this.$root.$emit('bv::hide::modal', 'name-field-modal', '')
        this[field] = 'canvas'
      }
    },
    saveSecondNameInput() {
      const { data } = this.$refs.secondNameInputPad.saveSignature()
      if (data) {
        const prop = `${this.currentSecondField}InputImageSecond`
        const field = `${this.currentSecondField}InputTypeSecond`
        this[prop] = data
        this.$root.$emit('bv::hide::modal', 'second-name-field-modal', '')
        this[field] = 'canvas'
      }
    },
    changeCustomerNameInput(type) {
      if (type === 'input') {
        this.$swal({
          title: 'Warning: Changing mode will result in the deletion of the handwriting.',
          html: 'Are you sure you want to continue?',
          // eslint-disable-next-line global-require
          imageUrl: require('@/assets/images/icons/warning.png'),
          reverseButtons: true,
          showCancelButton: true,
          cancelButtonText: 'No, go back',
          confirmButtonText: "Yes, I'm sure",
          customClass: {
            confirmButton: 'btn btn-primary ml-1',
            cancelButton: 'btn btn-outline-primary',
          },
          buttonsStyling: false,
        })
          .then(async result => {
            if (result.value) {
              this.customerNameImage = ''
              this.customerNameType = type
            }
          })
      } else {
        this.customerNameType = type
        this.$root.$emit('bv::show::modal', 'customer-name-modal', '')
      }
    },
    changeKinNameInput(type) {
      if (type === 'input') {
        this.$swal({
          title: 'Warning: Changing mode will result in the deletion of the handwriting.',
          html: 'Are you sure you want to continue?',
          // eslint-disable-next-line global-require
          imageUrl: require('@/assets/images/icons/warning.png'),
          reverseButtons: true,
          showCancelButton: true,
          cancelButtonText: 'No, go back',
          confirmButtonText: "Yes, I'm sure",
          customClass: {
            confirmButton: 'btn btn-primary ml-1',
            cancelButton: 'btn btn-outline-primary',
          },
          buttonsStyling: false,
        })
          .then(async result => {
            if (result.value) {
              this.kinNameImage = ''
              this.currentKinNameType = type
            }
          })
      } else {
        this.currentKinNameType = type
        this.$root.$emit('bv::show::modal', 'kin-name-modal', '')
      }
    },
    closeCustomerNameModal() {
      this.$root.$emit('bv::hide::modal', 'customer-name-modal', '')
      if (!this.customerNameImage) {
        this.customerNameType = 'input'
      }
    },
    closeKinNameModal() {
      this.$root.$emit('bv::hide::modal', 'kin-name-modal', '')
      if (!this.kinNameImage) {
        this.currentKinNameType = 'input'
      }
    },
    clearCustomerName() {
      this.customerNameImage = ''
      this.$refs.customerSignaturePad.clearSignature()
    },
    clearKinName() {
      this.kinNameImage = ''
      this.$refs.kinSignaturePad.clearSignature()
    },
    saveCustomerName() {
      const { data } = this.$refs.customerSignaturePad.saveSignature()
      if (data) {
        this.customerNameImage = data
        this.$root.$emit('bv::hide::modal', 'customer-name-modal', '')
        this.customerNameType = 'canvas'
      }
    },
    saveKinName() {
      const { data } = this.$refs.kinSignaturePad.saveSignature()
      if (data) {
        this.kinNameImage = data
        this.$root.$emit('bv::hide::modal', 'kin-name-modal', '')
        this.currentKinNameType = 'canvas'
      }
    },
    submitSignedForm(option = null) {
      const formData = new FormData()
      formData.append('customerEmail', this.customerEmailVal)
      formData.append('customerContact', this.customerMobileVal)
      if (option === 'edited') {
        formData.append('edited', 'yes')
      }
      if (this.tabActive === 'email') {
        formData.append('message', this.customerMessageValEmail)
      } else {
        formData.append('message', this.customerMessageValPhone)
      }
      formData.append('sendOptionType', this.tabActive)
      formData.append('formName', this.requestForm.formID.name)
      formData.append('url', `${window.location.origin}/operations/service-requests/form/${this.requestForm._id}/detail`)

      this.$http.post(`operation/service-requests/service-form/${this.requestForm._id}/generate-pdf`, formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      })
        .then(async response => {
          if (response.data.data) {
            await this.$emit('update:requestForm', response.data.data)
            this.$root.$emit('bv::hide::modal', 'submit-form-modal', '')

            this.makeFormValues()
            this.$toast({
              component: ToastificationContentCustom,
              props: {
                title: 'PDF is ready!',
                text: response.data.message,
                icon: 'CheckCircleIcon',
                variant: 'success',
              },
            })
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: response.data.message || '',
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            })
          }
        })
        .catch(error => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: error.data.message ?? process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
    resendSignedFormModal() {
      if (this.requestForm.pdfs.length) {
        this.$root.$emit('bv::show::modal', 'resend-form-modal', '')
      } else {
        this.$root.$emit('bv::show::modal', 'submit-form-modal', '')
      }
    },
    resendSignedForm() {
      const formData = new FormData()
      formData.append('customerEmail', this.customerEmailVal)
      formData.append('customerContact', this.customerMobileVal)
      if (this.tabActive === 'email') {
        formData.append('message', this.customerMessageValEmail)
      } else {
        formData.append('message', this.customerMessageValPhone)
      }
      formData.append('sendOptionType', this.tabActive)
      formData.append('formName', this.requestForm.formID.name)
      formData.append('url', `${window.location.origin}/operations/service-requests/form/${this.requestForm._id}/detail`)

      this.$http.post(`operation/service-requests/service-form/${this.requestForm._id}/resend-pdf`, formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      })
        .then(response => {
          this.$toast({
            component: ToastificationContentCustom,
            props: {
              title: 'PDF is ready!',
              text: response.data.message,
              icon: 'CheckCircleIcon',
              variant: 'success',
            },
          })
        })
        .catch(error => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: error.data.message ?? process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
      // if (this.requestForm.pdfs.length) {
      //   this.$http.get(`operation/service-requests/service-form/${this.requestForm._id}/resend-pdf`, {
      //     headers: { 'Content-Type': 'multipart/form-data' },
      //   })
      //     .then(response => {
      //       this.$toast({
      //         component: ToastificationContentCustom,
      //         props: {
      //           title: 'PDF is ready!',
      //           text: response.data.message,
      //           icon: 'CheckCircleIcon',
      //           variant: 'success',
      //         },
      //       })
      //     })
      //     .catch(error => {
      //       this.$toast({
      //         component: ToastificationContent,
      //         props: {
      //           title: error.data.message ?? process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
      //           icon: 'AlertTriangleIcon',
      //           variant: 'danger',
      //         },
      //       })
      //     })
      // } else {
      //   this.$root.$emit('bv::show::modal', 'submit-form-modal', '')
      // }
    },
    closeSubmitFormModal() {
      this.$root.$emit('bv::hide::modal', 'submit-form-modal', '')
    },
    closeResendFormModal() {
      this.$root.$emit('bv::hide::modal', 'resend-form-modal', '')
    },
    clearCanvasInput(index) {
      const prop = `${index}InputImage`
      this[prop] = ''
      const field = `${index}InputType`
      this[field] = 'input'
      // this.$refs.nameInputPad.clearSignature()
    },
    clearSecondCanvasInput(index) {
      const prop = `${index}InputImageSecond`
      this[prop] = ''
      const field = `${index}InputTypeSecond`
      this[field] = 'input'
    },
    saveCanvasInput(index) {
      const prop = `${index}InputImage`
      const reference = `${index}InputPad`
      const { data } = this.$refs[reference].saveSignature()
      this[prop] = data
    },
    saveSecondCanvasInput(index) {
      const prop = `${index}InputImageSecond`
      const reference = `${index}InputSecondPad`
      const { data } = this.$refs[reference].saveSignature()
      this[prop] = data
    },
    async handleClick(field, collapse) {
      if (this.requestForm.status === 'To prepare' || this.editFormInOtherStatus) {
        await this.$refs[collapse].updateVisible(true)
        this.$refs[field].focus()
      }
    },
    async handleClickDateField(field, collapse) {
      if (this.requestForm.status === 'To prepare' || this.editFormInOtherStatus) {
        await this.$refs[collapse].updateVisible(true)
        this.$refs[field].fp.open()
      }
    },
    changeInputType(index, type) {
      const prop = `${index}InputType`
      if (type === 'input') {
        this.$swal({
          title: 'Warning: Changing mode will result in the deletion of the handwriting.',
          html: 'Are you sure you want to continue?',
          // eslint-disable-next-line global-require
          imageUrl: require('@/assets/images/icons/warning.png'),
          reverseButtons: true,
          showCancelButton: true,
          cancelButtonText: 'No, go back',
          confirmButtonText: "Yes, I'm sure",
          customClass: {
            confirmButton: 'btn btn-primary ml-1',
            cancelButton: 'btn btn-outline-primary',
          },
          buttonsStyling: false,
        })
          .then(result => {
            if (result.value) {
              this.clearCanvasInput(index)
              this[prop] = type
            }
          })
      } else {
        this[prop] = type
        this.currentField = index
        this.$root.$emit('bv::show::modal', 'name-field-modal', '')
      }
    },
    changeSecondInputType(index, type) {
      const prop = `${index}InputTypeSecond`
      if (type === 'input') {
        this.$swal({
          title: 'Warning: Changing mode will result in the deletion of the handwriting.',
          html: 'Are you sure you want to continue?',
          // eslint-disable-next-line global-require
          imageUrl: require('@/assets/images/icons/warning.png'),
          reverseButtons: true,
          showCancelButton: true,
          cancelButtonText: 'No, go back',
          confirmButtonText: "Yes, I'm sure",
          customClass: {
            confirmButton: 'btn btn-primary ml-1',
            cancelButton: 'btn btn-outline-primary',
          },
          buttonsStyling: false,
        })
          .then(result => {
            if (result.value) {
              this.clearSecondCanvasInput(index)
              this[prop] = type
            }
          })
      } else {
        this[prop] = type
        this.currentSecondField = index
        this.$root.$emit('bv::show::modal', 'second-name-field-modal', '')
      }
    },
    makeGenderArray() {
      this.genderValue = [this.gender]
    },
    makeCheckboxToRadio() {
      const lastSelected = this.serviceTypeValueLocal.slice(-1)[0]
      if (lastSelected) {
        this.serviceTypeValueLocal = this.serviceTypeValueLocal.filter(code => code === lastSelected)
      }
    },
    submitSaveFormConfirmation() {
      // this.$refs.serviceRequestAncestralFormSave.validate().then(success => {
      //   let hasError = false
      //   if (this.ownEngraving || this.secondOwnEngraving) {
      //     if (!this.uploadedFiles.length) {
      //       hasError = true
      //       this.showFileUploadError = true
      //     }
      //   }
      //   if (success && !hasError) {
      //     const valueArray = []
      //     const ownEngravingObj = {
      //       key: 'ownEngraving',
      //       value: this.ownEngraving,
      //     }
      //     valueArray.push(ownEngravingObj)

      //     const secondOwnEngravingObj = {
      //       key: 'secondOwnEngraving',
      //       value: this.secondOwnEngraving,
      //     }
      //     valueArray.push(secondOwnEngravingObj)

      //     if (this.ownEngraving || this.secondOwnEngraving) {
      //       //
      //     } else {
      //       const firstLivingObject = {
      //         key: 'firstLiving',
      //         value: this.firstLiving,
      //       }
      //       valueArray.push(firstLivingObject)
      //       const secondLivingObject = {
      //         key: 'secondLiving',
      //         value: this.secondLiving,
      //       }
      //       valueArray.push(secondLivingObject)
      //       const thirdLivingObject = {
      //         key: 'thirdLiving',
      //         value: this.thirdLiving,
      //       }
      //       valueArray.push(thirdLivingObject)
      //       const fourthLivingObject = {
      //         key: 'fourthLiving',
      //         value: this.fourthLiving,
      //       }
      //       valueArray.push(fourthLivingObject)
      //       const fifthLivingObject = {
      //         key: 'fifthLiving',
      //         value: this.fifthLiving,
      //       }
      //       valueArray.push(fifthLivingObject)
      //       const sixthLivingObject = {
      //         key: 'sixthLiving',
      //         value: this.sixthLiving,
      //       }
      //       valueArray.push(sixthLivingObject)
      //       const seventhLivingObject = {
      //         key: 'seventhLiving',
      //         value: this.seventhLiving,
      //       }
      //       valueArray.push(seventhLivingObject)

      //       const firstInputObject = {
      //         key: 'firstInput',
      //       }
      //       if (this.firstInputType === 'input') {
      //         firstInputObject.value = this.firstInput
      //       } else {
      //         firstInputObject.value = this.firstInputImage
      //       }
      //       valueArray.push(firstInputObject)
      //       const secondInputObject = {
      //         key: 'secondInput',
      //       }
      //       if (this.secondInputType === 'input') {
      //         secondInputObject.value = this.secondInput
      //       } else {
      //         secondInputObject.value = this.secondInputImage
      //       }
      //       valueArray.push(secondInputObject)
      //       const thirdInputObject = {
      //         key: 'thirdInput',
      //       }
      //       if (this.thirdInputType === 'input') {
      //         thirdInputObject.value = this.thirdInput
      //       } else {
      //         thirdInputObject.value = this.thirdInputImage
      //       }
      //       valueArray.push(thirdInputObject)
      //       const fourthInputObject = {
      //         key: 'fourthInput',
      //       }
      //       if (this.fourthInputType === 'input') {
      //         fourthInputObject.value = this.fourthInput
      //       } else {
      //         fourthInputObject.value = this.fourthInputImage
      //       }
      //       valueArray.push(fourthInputObject)
      //       const fifthInputObject = {
      //         key: 'fifthInput',
      //       }
      //       if (this.fifthInputType === 'input') {
      //         fifthInputObject.value = this.fifthInput
      //       } else {
      //         fifthInputObject.value = this.fifthInputImage
      //       }
      //       valueArray.push(fifthInputObject)
      //       const sixthInputObject = {
      //         key: 'sixthInput',
      //       }
      //       if (this.sixthInputType === 'input') {
      //         sixthInputObject.value = this.sixthInput
      //       } else {
      //         sixthInputObject.value = this.sixthInputImage
      //       }
      //       valueArray.push(sixthInputObject)
      //       const seventhInputObject = {
      //         key: 'seventhInput',
      //       }
      //       if (this.seventhInputType === 'input') {
      //         seventhInputObject.value = this.seventhInput
      //       } else {
      //         seventhInputObject.value = this.seventhInputImage
      //       }
      //       valueArray.push(seventhInputObject)

      //       const firstLivingSecondObject = {
      //         key: 'firstLivingSecond',
      //         value: this.firstLivingSecond,
      //       }
      //       valueArray.push(firstLivingSecondObject)
      //       const secondLivingSecondObject = {
      //         key: 'secondLivingSecond',
      //         value: this.secondLivingSecond,
      //       }
      //       valueArray.push(secondLivingSecondObject)
      //       const thirdLivingSecondObject = {
      //         key: 'thirdLivingSecond',
      //         value: this.thirdLivingSecond,
      //       }
      //       valueArray.push(thirdLivingSecondObject)
      //       const fourthLivingSecondObject = {
      //         key: 'fourthLivingSecond',
      //         value: this.fourthLivingSecond,
      //       }
      //       valueArray.push(fourthLivingSecondObject)
      //       const fifthLivingSecondObject = {
      //         key: 'fifthLivingSecond',
      //         value: this.fifthLivingSecond,
      //       }
      //       valueArray.push(fifthLivingSecondObject)
      //       const sixthLivingSecondObject = {
      //         key: 'sixthLivingSecond',
      //         value: this.sixthLivingSecond,
      //       }
      //       valueArray.push(sixthLivingSecondObject)

      //       const firstInputSecondObject = {
      //         key: 'firstInputSecond',
      //       }
      //       if (this.firstInputTypeSecond === 'input') {
      //         firstInputSecondObject.value = this.firstInputSecond
      //       } else {
      //         firstInputSecondObject.value = this.firstInputImageSecond
      //       }
      //       valueArray.push(firstInputSecondObject)
      //       const secondInputSecondObject = {
      //         key: 'secondInputSecond',
      //       }
      //       if (this.secondInputTypeSecond === 'input') {
      //         secondInputSecondObject.value = this.secondInputSecond
      //       } else {
      //         secondInputSecondObject.value = this.secondInputImageSecond
      //       }
      //       valueArray.push(secondInputSecondObject)
      //       const thirdInputSecondObject = {
      //         key: 'thirdInputSecond',
      //       }
      //       if (this.thirdInputTypeSecond === 'input') {
      //         thirdInputSecondObject.value = this.thirdInputSecond
      //       } else {
      //         thirdInputSecondObject.value = this.thirdInputImageSecond
      //       }
      //       valueArray.push(thirdInputSecondObject)
      //       const fourthInputSecondObject = {
      //         key: 'fourthInputSecond',
      //       }
      //       if (this.fourthInputTypeSecond === 'input') {
      //         fourthInputSecondObject.value = this.fourthInputSecond
      //       } else {
      //         fourthInputSecondObject.value = this.fourthInputImageSecond
      //       }
      //       valueArray.push(fourthInputSecondObject)
      //       const fifthInputSecondObject = {
      //         key: 'fifthInputSecond',
      //       }
      //       if (this.fifthInputTypeSecond === 'input') {
      //         fifthInputSecondObject.value = this.fifthInputSecond
      //       } else {
      //         fifthInputSecondObject.value = this.fifthInputImageSecond
      //       }
      //       valueArray.push(fifthInputSecondObject)
      //       const sixthInputSecondObject = {
      //         key: 'sixthInputSecond',
      //       }
      //       if (this.sixthInputTypeSecond === 'input') {
      //         sixthInputSecondObject.value = this.sixthInputSecond
      //       } else {
      //         sixthInputSecondObject.value = this.sixthInputImageSecond
      //       }
      //       valueArray.push(sixthInputSecondObject)
      //     }

      //     const customerNameObj = {
      //       key: 'customerName',
      //     }
      //     if (this.customerNameType === 'input') {
      //       customerNameObj.value = this.customerName
      //     } else {
      //       customerNameObj.value = this.customerNameImage
      //     }
      //     valueArray.push(customerNameObj)

      //     const customerContactObj = {
      //       key: 'customerContact',
      //       value: this.customerContact,
      //     }
      //     valueArray.push(customerContactObj)

      //     const customerNRICObj = {
      //       key: 'customerNRIC',
      //       value: this.customerNRIC,
      //     }
      //     valueArray.push(customerNRICObj)

      //     const kinNameObj = {
      //       key: 'kinName',
      //     }
      //     if (this.kinNameType === 'input') {
      //       kinNameObj.value = this.kinName
      //     } else {
      //       kinNameObj.value = this.kinNameImage
      //     }
      //     valueArray.push(kinNameObj)

      //     const kinContactObj = {
      //       key: 'kinContact',
      //       value: this.kinContact,
      //     }
      //     valueArray.push(kinContactObj)

      //     const kinNRICObj = {
      //       key: 'kinNRIC',
      //       value: this.kinNRIC,
      //     }
      //     valueArray.push(kinNRICObj)

      //     const fileNoObj = {
      //       key: 'fileNo',
      //       value: this.fileNo,
      //     }
      //     valueArray.push(fileNoObj)

      //     const pedestalNoObj = {
      //       key: 'pedestalNo',
      //       value: this.pedestalNo,
      //     }
      //     valueArray.push(pedestalNoObj)

      //     const agentNameObj = {
      //       key: 'agentName',
      //       value: this.agentName,
      //     }
      //     valueArray.push(agentNameObj)

      //     const agentContactObj = {
      //       key: 'agentContact',
      //       value: this.agentContact,
      //     }
      //     valueArray.push(agentContactObj)

      //     const remarksObj = {
      //       key: 'remarks',
      //       value: this.remarks,
      //     }
      //     valueArray.push(remarksObj)

      //     if (this.existingPurchaserSignObject.key) {
      //       valueArray.push(this.existingPurchaserSignObject)
      //     }

      //     if (this.existingCompletionSignSrcObject.key) {
      //       valueArray.push(this.existingCompletionSignSrcObject)
      //     }

      //     if (this.existingInChargePersonObject.key) {
      //       valueArray.push(this.existingInChargePersonObject)
      //     }

      //     if (this.existingServiceTypeObject.key) {
      //       valueArray.push(this.existingServiceTypeObject)
      //     }
      //     if (this.existingInstallationDateObject.key) {
      //       valueArray.push(this.existingInstallationDateObject)
      //     }
      //     if (this.existingSubmitDateObject.key) {
      //       valueArray.push(this.existingSubmitDateObject)
      //     }
      //     if (this.existingEmailDateObject.key) {
      //       valueArray.push(this.existingEmailDateObject)
      //     }

      //     let valueChanged = false
      //     if (this.requestForm.status === 'Signed and Sent') {
      //       const difference = valueArray.filter(({ value: id1 }) => !this.requestForm.values.some(({ value: id2 }) => id2 === id1))
      //       if (difference.length) {
      //         const otherValueChanged = difference.find(o => !['inChargePerson', 'serviceType', 'installationDate', 'submitDate', 'emailDate'].includes(o.key))
      //         if (otherValueChanged) {
      //           valueChanged = true
      //         }
      //       }
      //     }

      //     if (valueChanged) {
      //       this.$root.$emit('bv::show::modal', 'edit-form-modal', '')
      //     } else {
      //       this.submitSaveForm()
      //     }
      //   }
      // })
      if (this.requestForm.status === 'Signed and Sent') {
        this.$root.$emit('bv::show::modal', 'edit-form-modal', '')
      } else {
        this.submitSaveForm()
      }
    },
    cancelEditing() {
      this.$root.$emit('bv::hide::modal', 'edit-form-modal', '')
      // this.editFormInOtherStatus = false
      // this.makeFormValues()
    },
    submitSaveForm(option = null, submit = false) {
      this.$refs.serviceRequestAncestralFormSave.validate().then(success => {
        let hasError = false
        let hasCustomerImage = true
        if (this.customerNameType === 'canvas' && !this.customerNameImage) {
          hasCustomerImage = false
          this.customerNameImageError = true
        }
        if (this.ownEngraving || this.secondOwnEngraving) {
          if (!this.uploadedFiles.length) {
            hasError = true
            this.showFileUploadError = true
          }
        }
        if ((option && option === 'saveOnly') || (success && !hasError && hasCustomerImage)) {
          const formData = new FormData()
          if (option && option === 'readyToSign') {
            formData.append('readyToSign', true)
          }
          const valueArray = []
          let srValueArray = this.serviceRequest.formValues
          const sfValueObject = {}
          const srValueObject = {}
          const ownEngravingObj = {
            key: 'ownEngraving',
            value: this.ownEngraving,
          }
          valueArray.push(ownEngravingObj)

          const secondOwnEngravingObj = {
            key: 'secondOwnEngraving',
            value: this.secondOwnEngraving,
          }
          valueArray.push(secondOwnEngravingObj)

          if (this.ownEngraving || this.secondOwnEngraving) {
            formData.append('ownEngraving', true)
          } else {
            const firstLivingObject = {
              key: 'firstLiving',
              value: this.firstLiving,
            }
            valueArray.push(firstLivingObject)
            const secondLivingObject = {
              key: 'secondLiving',
              value: this.secondLiving,
            }
            valueArray.push(secondLivingObject)
            const thirdLivingObject = {
              key: 'thirdLiving',
              value: this.thirdLiving,
            }
            valueArray.push(thirdLivingObject)
            const fourthLivingObject = {
              key: 'fourthLiving',
              value: this.fourthLiving,
            }
            valueArray.push(fourthLivingObject)
            const fifthLivingObject = {
              key: 'fifthLiving',
              value: this.fifthLiving,
            }
            valueArray.push(fifthLivingObject)
            const sixthLivingObject = {
              key: 'sixthLiving',
              value: this.sixthLiving,
            }
            valueArray.push(sixthLivingObject)
            const seventhLivingObject = {
              key: 'seventhLiving',
              value: this.seventhLiving,
            }
            valueArray.push(seventhLivingObject)

            const firstInputObject = {
              key: 'firstInput',
            }
            if (this.firstInputType === 'input') {
              firstInputObject.value = this.firstInput
            } else {
              firstInputObject.value = this.firstInputImage
            }
            valueArray.push(firstInputObject)
            const secondInputObject = {
              key: 'secondInput',
            }
            if (this.secondInputType === 'input') {
              secondInputObject.value = this.secondInput
            } else {
              secondInputObject.value = this.secondInputImage
            }
            valueArray.push(secondInputObject)
            const thirdInputObject = {
              key: 'thirdInput',
            }
            if (this.thirdInputType === 'input') {
              thirdInputObject.value = this.thirdInput
            } else {
              thirdInputObject.value = this.thirdInputImage
            }
            valueArray.push(thirdInputObject)
            const fourthInputObject = {
              key: 'fourthInput',
            }
            if (this.fourthInputType === 'input') {
              fourthInputObject.value = this.fourthInput
            } else {
              fourthInputObject.value = this.fourthInputImage
            }
            valueArray.push(fourthInputObject)
            const fifthInputObject = {
              key: 'fifthInput',
            }
            if (this.fifthInputType === 'input') {
              fifthInputObject.value = this.fifthInput
            } else {
              fifthInputObject.value = this.fifthInputImage
            }
            valueArray.push(fifthInputObject)
            const sixthInputObject = {
              key: 'sixthInput',
            }
            if (this.sixthInputType === 'input') {
              sixthInputObject.value = this.sixthInput
            } else {
              sixthInputObject.value = this.sixthInputImage
            }
            valueArray.push(sixthInputObject)
            const seventhInputObject = {
              key: 'seventhInput',
            }
            if (this.seventhInputType === 'input') {
              seventhInputObject.value = this.seventhInput
            } else {
              seventhInputObject.value = this.seventhInputImage
            }
            valueArray.push(seventhInputObject)

            const firstLivingSecondObject = {
              key: 'firstLivingSecond',
              value: this.firstLivingSecond,
            }
            valueArray.push(firstLivingSecondObject)
            const secondLivingSecondObject = {
              key: 'secondLivingSecond',
              value: this.secondLivingSecond,
            }
            valueArray.push(secondLivingSecondObject)
            const thirdLivingSecondObject = {
              key: 'thirdLivingSecond',
              value: this.thirdLivingSecond,
            }
            valueArray.push(thirdLivingSecondObject)
            const fourthLivingSecondObject = {
              key: 'fourthLivingSecond',
              value: this.fourthLivingSecond,
            }
            valueArray.push(fourthLivingSecondObject)
            const fifthLivingSecondObject = {
              key: 'fifthLivingSecond',
              value: this.fifthLivingSecond,
            }
            valueArray.push(fifthLivingSecondObject)
            const sixthLivingSecondObject = {
              key: 'sixthLivingSecond',
              value: this.sixthLivingSecond,
            }
            valueArray.push(sixthLivingSecondObject)

            const firstInputSecondObject = {
              key: 'firstInputSecond',
            }
            if (this.firstInputTypeSecond === 'input') {
              firstInputSecondObject.value = this.firstInputSecond
            } else {
              firstInputSecondObject.value = this.firstInputImageSecond
            }
            valueArray.push(firstInputSecondObject)
            const secondInputSecondObject = {
              key: 'secondInputSecond',
            }
            if (this.secondInputTypeSecond === 'input') {
              secondInputSecondObject.value = this.secondInputSecond
            } else {
              secondInputSecondObject.value = this.secondInputImageSecond
            }
            valueArray.push(secondInputSecondObject)
            const thirdInputSecondObject = {
              key: 'thirdInputSecond',
            }
            if (this.thirdInputTypeSecond === 'input') {
              thirdInputSecondObject.value = this.thirdInputSecond
            } else {
              thirdInputSecondObject.value = this.thirdInputImageSecond
            }
            valueArray.push(thirdInputSecondObject)
            const fourthInputSecondObject = {
              key: 'fourthInputSecond',
            }
            if (this.fourthInputTypeSecond === 'input') {
              fourthInputSecondObject.value = this.fourthInputSecond
            } else {
              fourthInputSecondObject.value = this.fourthInputImageSecond
            }
            valueArray.push(fourthInputSecondObject)
            const fifthInputSecondObject = {
              key: 'fifthInputSecond',
            }
            if (this.fifthInputTypeSecond === 'input') {
              fifthInputSecondObject.value = this.fifthInputSecond
            } else {
              fifthInputSecondObject.value = this.fifthInputImageSecond
            }
            valueArray.push(fifthInputSecondObject)
            const sixthInputSecondObject = {
              key: 'sixthInputSecond',
            }
            if (this.sixthInputTypeSecond === 'input') {
              sixthInputSecondObject.value = this.sixthInputSecond
            } else {
              sixthInputSecondObject.value = this.sixthInputImageSecond
            }
            valueArray.push(sixthInputSecondObject)
          }

          formData.append('files', JSON.stringify(this.uploadedFiles))

          const customerNameObj = {
            key: 'customerName',
          }
          if (this.customerNameType === 'input') {
            customerNameObj.value = this.customerName
          } else {
            customerNameObj.value = this.customerNameImage
          }
          srValueArray = srValueArray.filter(obj => obj.key !== customerNameObj.key).concat(customerNameObj)
          // sfValueObject.customerName = this.customerName

          const customerContactObj = {
            key: 'customerContact',
            value: this.customerContact,
          }
          srValueArray = srValueArray.filter(obj => obj.key !== customerContactObj.key).concat(customerContactObj)
          // sfValueObject.customerContact = this.customerContact

          const customerNRICObj = {
            key: 'customerNRIC',
            value: this.customerNRIC,
          }
          // valueArray.push(customerNRICObj)
          // srValueArray.push(customerNRICObj)
          srValueArray = srValueArray.filter(obj => obj.key !== customerNRICObj.key).concat(customerNRICObj)

          const kinNameObj = {
            key: 'kinName',
          }
          if (this.kinNameType === 'input') {
            kinNameObj.value = this.kinName
          } else {
            kinNameObj.value = this.kinNameImage
          }
          // srValueArray.push(kinNameObj)
          srValueArray = srValueArray.filter(obj => obj.key !== kinNameObj.key).concat(kinNameObj)

          const kinContactObj = {
            key: 'kinContact',
            value: this.kinContact,
          }
          // srValueArray.push(kinContactObj)
          srValueArray = srValueArray.filter(obj => obj.key !== kinContactObj.key).concat(kinContactObj)

          const kinNRICObj = {
            key: 'kinNRIC',
            value: this.kinNRIC,
          }
          // srValueArray.push(kinNRICObj)
          srValueArray = srValueArray.filter(obj => obj.key !== kinNRICObj.key).concat(kinNRICObj)

          // const fileNoObj = {
          //   key: 'fileNo',
          //   value: this.fileNo,
          // }
          // valueArray.push(fileNoObj)
          srValueObject.fileNo = this.fileNo

          // const pedestalNoObj = {
          //   key: 'pedestalNo',
          //   value: this.pedestalNo,
          // }
          // valueArray.push(pedestalNoObj)
          // sfValueObject.deceasedPedestal = this.pedestalNo

          // const agentNameObj = {
          //   key: 'agentName',
          //   value: this.agentName,
          // }
          // valueArray.push(agentNameObj)
          // formData.append('agentName', this.agentName)

          // const agentContactObj = {
          //   key: 'agentContact',
          //   value: this.agentContact,
          // }
          // valueArray.push(agentContactObj)
          // sfValueObject.agentContact = this.agentContact

          const remarksObj = {
            key: 'remarks',
            value: this.remarks,
          }
          valueArray.push(remarksObj)

          const photoClaimedByObj = {
            key: 'photoClaimedBy',
            value: this.photoClaimedBy,
          }
          valueArray.push(photoClaimedByObj)

          const photoClaimedDateObj = {
            key: 'photoClaimedDate',
            value: this.photoClaimedDate,
          }
          valueArray.push(photoClaimedDateObj)

          const photoReceivedByObj = {
            key: 'photoReceivedBy',
            value: this.photoReceivedBy,
          }
          valueArray.push(photoReceivedByObj)

          const photoReceivedDateObj = {
            key: 'photoReceivedDate',
            value: this.photoReceivedDate,
          }
          valueArray.push(photoReceivedDateObj)

          let completionCheckedSignVal = 0
          let purchaserSignVal = 0

          if (this.existingPurchaserSignObject.key) {
            purchaserSignVal = 1
            valueArray.push(this.existingPurchaserSignObject)
          }

          if (this.existingCompletionSignSrcObject.key) {
            completionCheckedSignVal = 1
            valueArray.push(this.existingCompletionSignSrcObject)
          }

          // if (this.existingInChargePersonObject.key) {
          //   valueArray.push(this.existingInChargePersonObject)
          // }
          const inChargePersonObject = {
            key: 'inChargePerson',
            value: this.inChargePerson,
          }
          valueArray.push(inChargePersonObject)

          // if (this.existingServiceTypeObject.key) {
          //   valueArray.push(this.existingServiceTypeObject)
          // }
          const serviceTypeObject = {
            key: 'serviceType',
            value: this.serviceType,
          }
          valueArray.push(serviceTypeObject)
          // if (this.existingInstallationDateObject.key) {
          //   valueArray.push(this.existingInstallationDateObject)
          // }
          const installationDateObject = {
            key: 'installationDate',
            value: this.installationDate,
          }
          valueArray.push(installationDateObject)
          // if (this.existingSubmitDateObject.key) {
          //   valueArray.push(this.existingSubmitDateObject)
          // }
          const submitDateObject = {
            key: 'submitDate',
            value: this.submitDate,
          }
          valueArray.push(submitDateObject)
          // if (this.existingEmailDateObject.key) {
          //   valueArray.push(this.existingEmailDateObject)
          // }
          const emailDateObject = {
            key: 'emailDate',
            value: this.emailDate,
          }
          valueArray.push(emailDateObject)

          // const firstInstallationObj = {
          //   key: 'firstInstallation',
          //   value: this.firstInstallation,
          // }
          // valueArray.push(firstInstallationObj)

          // const subsequentInstallationObj = {
          //   key: 'subsequentInstallation',
          //   value: this.subsequentInstallation,
          // }
          // valueArray.push(subsequentInstallationObj)

          // const subsequentInstallationFeeObj = {
          //   key: 'subsequentInstallationFee',
          //   value: this.subsequentInstallationFee,
          // }
          // valueArray.push(subsequentInstallationFeeObj)

          // const receiptNoObj = {
          //   key: 'receiptNo',
          //   value: this.receiptNo,
          // }
          // valueArray.push(receiptNoObj)

          // if (this.existingWitnessedSignSrcObject.key) {
          //   valueArray.push(this.existingWitnessedSignSrcObject)
          // }
          // if (this.existingAuthorizedSignSrcObject.key) {
          //   valueArray.push(this.existingAuthorizedSignSrcObject)
          // }
          // if (this.existingPurchaserSignObject.key) {
          //   valueArray.push(this.existingPurchaserSignObject)
          // }
          // if (this.existingServiceTypeObject.key) {
          //   valueArray.push(this.existingServiceTypeObject)
          // }
          // if (this.existingOfficeRemarksObject.key) {
          //   valueArray.push(this.existingOfficeRemarksObject)
          // }

          let allSigned = false
          if (completionCheckedSignVal && purchaserSignVal) {
            allSigned = true
          }

          // let valueChanged = false
          // if (this.requestForm.status === 'Signed and Sent') {
          //   const difference = valueArray.filter(({ value: id1 }) => !this.requestForm.values.some(({ value: id2 }) => id2 === id1))
          //   if (difference.length) {
          //     const otherValueChanged = difference.find(o => !['inChargePerson', 'serviceType', 'installationDate', 'submitDate', 'emailDate'].includes(o.key))
          //     if (otherValueChanged) {
          //       valueChanged = true
          //     }
          //   }
          // }

          formData.append('values', JSON.stringify(valueArray))
          formData.append('srValues', JSON.stringify(srValueArray))
          formData.append('sfValues', JSON.stringify(sfValueObject))
          formData.append('srValueObject', JSON.stringify(srValueObject))
          formData.append('allSigned', allSigned)
          formData.append('currentStatus', this.requestForm.status)
          formData.append('formName', this.requestForm.formID.name)
          formData.append('serviceRequest', this.serviceRequest._id)
          formData.append('serviceForm', this.serviceRequest.serviceForm._id)
          formData.append('url', `${window.location.origin}/operations/service-requests/form/${this.requestForm._id}/detail`)
          this.$http.post(`operation/service-requests/service-form/ancestral/${this.requestForm._id}/store`, formData, {
            headers: { 'Content-Type': 'multipart/form-data' },
          })
            .then(async response => {
              if (response.data.data) {
                await this.$refs.myVueDropzone.removeAllFiles()
                await this.$emit('update:requestForm', response.data.data)
                await this.$emit('update:serviceRequest', response.data.sr)
                this.makeFormValues()
                this.editFormInOtherStatus = false
                if (option && option === 'edited' && response.data.data.status === 'Signed and Sent' && submit) {
                  this.submitSignedForm('edited')
                } else if (response.data.data.status === 'Signing' && allSigned) {
                  this.$root.$emit('bv::show::modal', 'submit-form-modal', '')
                } else {
                  this.$swal({
                    title: 'Form Data Saved',
                    html: response.data.message || '',
                    // eslint-disable-next-line global-require
                    imageUrl: require('@/assets/images/icons/save.png'),
                    imageWidth: 80,
                    imageHeight: 80,
                    imageAlt: 'Custom Icon',
                    showCancelButton: false,
                    confirmButtonText: 'Okay',
                    customClass: {
                      confirmButton: 'btn btn-primary',
                    },
                    buttonsStyling: false,
                  })
                }
              } else {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: response.data.message || '',
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                  },
                })
              }
            })
            .catch(error => {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: error.data.message ?? process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                },
              })
            })
        } else {
          const items = document.getElementsByClassName('is-invalid')
          if (items && items.length) {
            // window.scrollTo({
            //   top: items[0].offsetHeight + 100,
            //   behavior: 'smooth',
            // })
            items[0].scrollIntoView({
              behavior: 'smooth',
              block: 'center',
              inline: 'center',
            })
          }
        }
      })
    },
  },
}
</script>
